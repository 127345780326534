import { useQuery } from '@tanstack/react-query';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataItem } from '../../@types/DataItem';
import { RootState } from '../../@types/RootState';
import { Exception } from '../../data';
import { useDebounce } from '../../hooks';
import { checkIfIsLoading } from '../../utils';
import { SegmentData } from '../FilterSection/services';
import { FilterHelper } from './FilterHelper';

type LoadFn = (
    query?: string,
    option?: Partial<{
        isModal: boolean;
        size: number;
    }>,
) => Promise<DataItem[]>;

type PayloadAction<DataKey extends string = string, T = unknown> = {
    key: DataKey;
} & T;

type ActionFn<DataKey extends string, Data> = (payload: PayloadAction<DataKey, Data>) => {
    payload: PayloadAction<string, Data>;
    type: string;
};

export type UseFilterQueryProps<DataKey extends string = string> = {
    dataKey: DataKey;
    loadFn: LoadFn;
    selectors: {
        data: (state: RootState) => DataItem[];
        cache: (state: RootState) => DataItem[];
        value: (state: RootState) => string[];
        query: (state: RootState) => string;
    };
    actions: {
        setQuery: ActionFn<DataKey, { query: string }>;
        setData: ActionFn<DataKey, { data: Array<DataItem | SegmentData> }>;
        setCache: ActionFn<DataKey, { cache: DataItem[] }>;
        setValue: ActionFn<DataKey, { value: string[] }>;
    };
};

export const useFilterQueryWithReducer = <DataKey extends string = string>({
    dataKey,
    loadFn,
    selectors,
    actions: { setQuery, setData, setCache, setValue },
}: UseFilterQueryProps<DataKey>) => {
    const debounce = useDebounce();
    const dispatch = useDispatch();
    const [hasPastedValues, setHasPastedValues] = useState(false);
    const data = useSelector(selectors.data);
    const cache = useSelector(selectors.cache);
    const value = useSelector(selectors.value);
    const query = useSelector(selectors.query);

    const handleSearch = useCallback(
        (query: string) => {
            debounce(() => dispatch(setQuery({ key: dataKey, query })));
        },
        [dataKey, dispatch, setQuery, debounce],
    );

    const handleChange = useCallback(
        (value: string[]) => {
            dispatch(setValue({ key: dataKey, value }));
            const filteredCache = FilterHelper.filterDataByValue(value, data);
            if (cache) dispatch(setCache({ key: dataKey, cache: filteredCache }));
        },
        [dataKey, cache, data, dispatch, setCache, setValue],
    );

    const handleClean = useCallback(() => {
        dispatch(setQuery({ key: dataKey, query: '' }));
        dispatch(setValue({ key: dataKey, value: [] }));
        dispatch(setCache({ key: dataKey, cache: [] }));
    }, [dataKey, dispatch, setQuery, setValue, setCache]);

    const handleClose = useCallback(() => {
        handleSearch('');
    }, [handleSearch]);

    const handleConfirmPasteValues = useCallback(
        (query: string) => {
            if (!query.length) return;
            handleSearch(query);
            setHasPastedValues(true);
        },
        [handleSearch],
    );

    const handleSuccess = useCallback(
        (data: DataItem[]) => {
            const [filteredData, value] = FilterHelper.onSuccess(data, cache);
            dispatch(setData({ key: dataKey, data: filteredData }));
            if (!hasPastedValues) return;
            dispatch(setValue({ key: dataKey, value }));
            dispatch(setCache({ key: dataKey, cache: filteredData }));
            setHasPastedValues(false);
        },
        [dataKey, cache, dispatch, setData, setCache, setValue, hasPastedValues],
    );

    const handleLoadData = useCallback(async () => {
        try {
            return await loadFn(query, { isModal: hasPastedValues, size: hasPastedValues ? 1000 : 20 });
        } catch (err) {
            setHasPastedValues(false);
            throw new Exception(err.message);
        }
    }, [loadFn, query, hasPastedValues]);

    const { fetchStatus } = useQuery({
        queryKey: [`ipa.filter.${dataKey}`, query],
        initialData: [],
        queryFn: handleLoadData,
        onSuccess: handleSuccess,
    });

    const isLoading = useMemo(() => checkIfIsLoading(fetchStatus), [fetchStatus]);

    return {
        data,
        cache,
        value,
        query,
        isLoading,
        onSearch: handleSearch,
        onClose: handleClose,
        onClean: handleClean,
        onChange: handleChange,
        onConfirmPasteValues: handleConfirmPasteValues,
    };
};
