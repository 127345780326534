import { CustosVendaDataType, DespesaVendaDataType, EditBaseTableItemDataType, ImpostosDataType } from "./pages/EditBaseTable/types"

export const outrosCustosCalc = (data: EditBaseTableItemDataType) => {
    const totalImpostos = data?.impostos.ipi + data?.impostos.icms + data?.impostos.pis + data?.impostos.cofins + data?.impostos.outros
    const totalCustoVenda = data?.custosVenda.freteMedio + data?.custosVenda.comissao + data?.custosVenda.trocasOuDevolucao + data?.custosVenda.gratificacao + data?.custosVenda.outros
    const totalDespesaVenda = data?.despesaVenda.comerciais + data?.despesaVenda.administrativas + data?.despesaVenda.outras
    const total = totalImpostos + totalCustoVenda + totalDespesaVenda

    return total
}

export const PMZCalc = (data: EditBaseTableItemDataType) => {
    return outrosCustosCalc(data) + data.parametros.custoFabricacao
}

export const totalImpostos = (impostos: ImpostosDataType) => {
    const total = impostos?.ipi + impostos?.icms + impostos?.pis + impostos?.cofins + impostos?.outros

    return total.toFixed(2).toString().replace('.', ',')
}

export const totalCustosVenda = (custosVenda: CustosVendaDataType) => {
    const total = custosVenda?.freteMedio +
        custosVenda?.comissao +
        custosVenda?.trocasOuDevolucao +
        custosVenda?.gratificacao +
        custosVenda?.outros

    return total.toFixed(2).toString().replace('.', ',')
}

export const totalOutrasDespesas = (outras: DespesaVendaDataType) => {
    const total = outras?.comerciais + outras?.administrativas + outras?.outras

    return total.toFixed(2).toString().replace('.', ',')
}

export const tabelaAtualPercentage = (data: EditBaseTableItemDataType) => {
    const value = data.tabelaAtual.valorTabelaAtual
    const calc = ((value - PMZCalc(data)) / value) * 100

    return calc.toFixed(2).toString().replace('.', ',')
}

export const precoMedioMargemOperacionalCalc = (data: EditBaseTableItemDataType) => {
    const value = data.parametros.precoMedio.valorAtual
    if (value) {
        const calc = ((value - PMZCalc(data)) / value) * 100
        return calc.toFixed(2)
    }
    return 0
}

export const precoMedioDiffCalc = (data: EditBaseTableItemDataType) => {
    const precoMedioAtual = data.parametros.precoMedio.valorAtual
    const tabelaAtual = data.tabelaAtual.valorTabelaAtual
    const calc = ((precoMedioAtual - tabelaAtual) / tabelaAtual) * 100
    return calc
}

export const precoEditadoEPrecoTabelaAtualDiffCalc = (data: EditBaseTableItemDataType) => {
    const novoPrecoTabela = data.parametros.novoPrecoTabela.valorNovo
    const tabelaAtual = data.tabelaAtual.valorTabelaAtual
    const calc = ((novoPrecoTabela - tabelaAtual) / tabelaAtual) * 100
    return calc
}

// PREÇO MEDIO PROJETADO
export const precoMedioProjetadoCalc = (data: EditBaseTableItemDataType) => {
    const novoPrecoTabela = data.parametros.novoPrecoTabela.valorNovo
    const toPercent = precoMedioDiffCalc(data) * 0.01
    const calc = (1 + toPercent) * novoPrecoTabela
    // const calc = (1 + (((precoMedioAtual - tabelaAtual) / tabelaAtual) * 100 )) * novoPrecoTabela
    return calc
}

export const margemOperacionalPrecoMedioCalc = (data: EditBaseTableItemDataType) => {
    const calc = ((data.parametros.precoMedio.valorAtual - PMZCalc(data)) / data.parametros.precoMedio.valorAtual) * 100;
    return calc;
}

// MARGEM OPERACIONAL
export const margemOperacionalCalc = (data: EditBaseTableItemDataType) => {
    const calc = ((precoMedioProjetadoCalc(data) - PMZCalc(data)) / precoMedioProjetadoCalc(data)) * 100;
    return calc;
}

export const precoMedioMargemBrutaCalc = (data: EditBaseTableItemDataType) => {
    const precoMedio = data.parametros.precoMedio.valorAtual
    const custoFabricacao = data.parametros.custoFabricacao
    const calc = ((precoMedio - custoFabricacao - margemOperacionalCalc(data)) / precoMedio) * 100
    return calc
}

// MARGEM BRUTA
export const margemBrutaCalc = (data: EditBaseTableItemDataType) => {
    const margemOperacionalVariation = margemOperacionalCalc(data) - precoMedioMargemOperacionalCalc(data)
    const calc = (
        (precoMedioProjetadoCalc(data) - data.parametros.custoFabricacao - margemOperacionalVariation) / precoMedioProjetadoCalc(data)
    ) * 100
    return calc
}

// MARGEM BRUTA PERCENTUAL
export const margemBrutaPercentVariationCalc = (data: EditBaseTableItemDataType) => {
    const calc = margemBrutaCalc(data) - precoMedioMargemBrutaCalc(data);

    if (calc === Infinity || calc === -Infinity) {
        return 0
    }
    return calc
}

// MARGEM OPERACIONAL PERCENTUAL
export const margemOperacionalPercentVariationCalc = (data: EditBaseTableItemDataType) => {
    const calc = margemOperacionalCalc(data) - margemOperacionalPrecoMedioCalc(data);

    if (calc === Infinity || calc === -Infinity) {
        return 0
    }
    return calc
}
