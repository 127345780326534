import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import * as service from '../services';

export const useUfFilter = () => {

    const getUFData = async () => {
        const res = await service.getUFList();

        if (res.status === 200) {
            const list = res.data.map((item) => ({ label: item, value: item }));
            return list;
        }

        return [];
    };

    const {
        data,
        isLoading,
        isError,
        isSuccess,
        refetch,
    } = useQuery({
        queryKey: ['uf'],
        queryFn: getUFData,
        retry: false,
    });

    return {
        ufData: data,
        isLoadingRegisteredProducts: isLoading,
        isError,
        isSuccess,
        refetchRegisteredProducts: refetch,
    };
};
