import React from 'react';
import { Table } from 'rsuite';
import ModalConfirm from '../../../../../../../components/ModalConfirm';
import styles from './ModalDescriptionPriceAndDiscount.module.scss';

const { Column, HeaderCell, Cell } = Table;

type ModalDescriptionPriceAndDiscountProps = {
    show?: boolean
}

const tableData = [
    {
        avista: 15.23,
        column: '0,0%',
        15: 15.23,
        30: 15.23,
        45: 15.23,
        60: 15.23,
        75: 15.23,
    },
    {
        avista: 15.23,
        column: '5,0%',
        15: 15.23,
        30: 15.23,
        45: 15.23,
        60: 15.23,
        75: 15.23,
    },
    {
        avista: 15.23,
        column: '10,0%',
        15: 15.23,
        30: 15.23,
        45: 15.23,
        60: 15.23,
        75: 15.23,
    },
    {
        avista: 15.23,
        column: '15,0%',
        15: 15.23,
        30: 15.23,
        45: 15.23,
        60: 15.23,
        75: 15.23,
    },
    {
        avista: 15.23,
        column: '20,0%',
        15: 15.23,
        30: 15.23,
        45: 15.23,
        60: 15.23,
        75: 15.23,
    },
    {
        avista: 15.23,
        column: '25,0%',
        15: 15.23,
        30: 15.23,
        45: 15.23,
        60: 15.23,
        75: 15.23,
    },
];

const firstColumn = ['0,0%', '5,0%', '10,0%', '15,0%', '20,0%', '25,0%'];

export const ModalDescriptionPriceAndDiscount = ({ show, ...props }: ModalDescriptionPriceAndDiscountProps) => {
    return (
        <ModalConfirm
            show={show}
            modalWidth="688px"
            titleFontSize="16px"
            footerAlign="end"
            title="Ativar oferta do produto"
            message="Escolha o preço e o prazo vigente para a oferta"
            confirmButton="Ativar oferta"
            cancelButton="Fechar"
            className={styles['modal-activate-product-promo']}
        >
            <Table
                className="table"
                autoHeight
                data={tableData}
                bordered
            >
                <Column className={styles['bold-background']}>
                    <HeaderCell>Desc./Prazo</HeaderCell>
                    <Cell dataKey="column" />
                </Column>
                <Column>
                    <HeaderCell className={styles['bold-background']}>À vista</HeaderCell>
                    <Cell dataKey="avista" />
                </Column>
                <Column>
                    <HeaderCell className={styles['bold-background']}>15d. (+0,25%)</HeaderCell>
                    <Cell dataKey="15" />
                </Column>
                <Column>
                    <HeaderCell className={styles['bold-background']}>30d. (+0,75%)</HeaderCell>
                    <Cell dataKey="30" />
                </Column>
                <Column>
                    <HeaderCell className={styles['bold-background']}>45d. (+1,25%)</HeaderCell>
                    <Cell dataKey="45" />
                </Column>
                <Column>
                    <HeaderCell className={styles['bold-background']}>60d. (+1,75%)</HeaderCell>
                    <Cell dataKey="60" />
                </Column>
                <Column>
                    <HeaderCell className={styles['bold-background']}>75d. (+2,25%)</HeaderCell>
                    <Cell dataKey="75" />
                </Column>
            </Table>
        </ModalConfirm>
    );
};
