import React, { ReactNode } from 'react';
import { ContentContainer, LinkButton } from '../../../../../components';

import styles from './ResultsFoundActionArea.module.scss';

type ResultsFoundActionAreaProps = {
    children?: ReactNode,
    value?: number,
    className?: string,
}

export const ResultsFoundActionArea = ({ children, value, className }: ResultsFoundActionAreaProps) => {
    return (
        <ContentContainer className={[styles.container, className].join(' ')}>
            <div className={styles.flex}>
                <span className={styles.text}>Foram encontrados</span>
                <span className={styles['bold-text']}>
                    {value}
                    {' '}
                    resultados
                </span>
                <span className={styles.text}>no total</span>
                <LinkButton fontSize="12px" className={styles['btn-select-all']}>
                    Selecionar tudo
                </LinkButton>
            </div>
            <div className={styles['flex-action']}>
                {children}
            </div>
        </ContentContainer>
    );
};
