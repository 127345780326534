import { useQuery } from '@tanstack/react-query';
import * as service from '../services';
import { useEditBaseTableStore } from '../EditBaseTable.store';
import { useCustosGeraisTabela } from './useCustosGeraisTabela';
import { NotificationMessage } from '../../../../../../components';

export const useSaveCustosGeraisTabela = () => {
    const {
        custosGeraisTabelaBody,
        setEditBaseTableState,
    } = useEditBaseTableStore();

    const { handleGetCustosGeraisTabelaData } = useCustosGeraisTabela()

    const saveCustosGeraisTabela = async () => {
        const res = await service.saveCustosGeraisTabela(custosGeraisTabelaBody);
        return res;
    };

    const saveSuccess = () => {
        setEditBaseTableState('openModalEditGeneralCosts', false)
        NotificationMessage(
            'success',
            5000,
            'Custos gerais da tabela base salvo com sucesso',
        );
        handleGetCustosGeraisTabelaData()
    }

    const {
        isLoading,
        isError,
        refetch,
    } = useQuery({
        queryKey: ['save-custos-gerais-tabela'],
        queryFn: () => saveCustosGeraisTabela(),
        onSuccess: saveSuccess,
        retry: false,
        enabled: false
    });

    return {
        isLoading,
        isError,
        refetch,
        handleSaveCustosGeraisTabela: refetch
    };
};
