import React, { ReactNode } from 'react';
import styles from './MainContainer.module.scss';

type MainContainerProps = {
    children?: ReactNode,
    className?: string,
}

export const MainContainer = ({ children, className }: MainContainerProps) => {
    return (
        <div className={[styles['main-container'], className].join(' ')}>{children}</div>
    );
};
