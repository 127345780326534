import { zodResolver } from '@hookform/resolvers/zod';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { selectorSegmentos } from '../../../../../../reducers/gerenciadorPrecos/segmentos';
import productsSegmentationSchema, { ProductsSegmentation } from '../lib/schema';
import { useProductsSegmentationQuery } from './useProductsSegmentationQuery';

export const useProductsSegmentation = () => {
    const segments = useSelector(selectorSegmentos).slice(0, 4);

    const formatedSegments = useMemo(() => {
        return segments.map((seg) => {
            return { ...seg, label: `${seg.level} - ${seg.label}` };
        });
    }, [segments]);

    const query = useProductsSegmentationQuery();

    const form = useForm<ProductsSegmentation>({
        values: query.data,
        resolver: zodResolver(productsSegmentationSchema),
    });

    return useMemo(() => ({ query, form, segments: formatedSegments }), [query, form, formatedSegments]);
};
