import EditIcon from '@material-ui/icons/Edit';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
    Button,
    Checkbox,
    Table,
    Tooltip,
    Whisper,
    InputGroup,
    Input,
    Icon,
} from 'rsuite';
// import { selectorCalculadoraPrevisaoDemanda } from '../../../../reducers/calculadoraPrevisaoDemanda';
// import {
//     setFiltersFromEditScenario,
// } from '../../../../reducers/calculadoraPrevisaoDemanda/filtros';
// import {
//     setInputFromEditScenario,
// } from '../../../../reducers/calculadoraPrevisaoDemanda/inputCalculos';
// import {
//     setResultFromEditScenario,
//     setResultScenarioData,
// } from '../../../../reducers/calculadoraPrevisaoDemanda/resultScenario';
import DeleteIcon from '@material-ui/icons/Delete';
import { LoadingSpinerArea } from '../../../../../../../components/LoadingSpinner';
import { Indicator } from '../../../../../../CalculadoraPrevisaoDemanda/Components/Indicator';
import { LinkButton } from '../../../../../../../components';

import styles from './NewPriceTableTable.module.scss';

const {
 Column, HeaderCell, Cell, Pagination,
} = Table;

const lengthMenu = [
    {
        value: 10,
        label: 10,
    },
    {
        value: 20,
        label: 20,
    },
];

const tableData = [
    {
        produtoCodigo: '249',
        produto: 'PROPOMAX Kids 140 ml',
        linha: 'PROPOMAX',
        uf: 'Nacional',
        custoFabricacao: 4.29,
        cfInd: 1.2,
        outros: 12.4,
        outrosInd: 39.2,
        pmz: 7.64,
        pmzInd: 23.8,
        tabelaAtual: 15.90,
        taInd: 18.6,
        precoMedioAtual: 11.2,
        pmInd: 42.12,
        pmIndd: 2.1,
        intervaloMinimo: 105,
        intervaloMaximo: 108,
        responsavel: 'Tiago Martins',
        data: '23/05/2022',
    },
    {
        produtoCodigo: '249',
        produto: 'PROPOMAX Kids 140 ml',
        linha: 'PROPOMAX',
        uf: 'Nacional',
        custoFabricacao: 4.29,
        cfInd: 1.2,
        outros: 12.4,
        outrosInd: 39.2,
        pmz: 7.64,
        pmzInd: 23.8,
        tabelaAtual: 15.90,
        taInd: 18.6,
        precoMedioAtual: 11.2,
        pmInd: 42.12,
        pmIndd: 2.1,
        intervaloMinimo: 105,
        intervaloMaximo: 108,
        responsavel: 'Tiago Martins',
        data: '23/05/2022',
    },
];

export const NewPriceTableTable = () => {
    // const { savedScenario } = useSelector(selectorCalculadoraPrevisaoDemanda);
    // const selectedScenario = savedScenario.selectedScenarioItemList;
    // const tableData = savedScenario.savedScenarioData;
    // const indeterminate = selectedScenario.length > 0 && selectedScenario.length < tableData.totalElements;

    // const {
    //     tableHandlers,
    //     tableState,
    // } = useTableActions();

    // const { resultHandlers } = useGetScenarioResults();

    // const dispatch = useDispatch();

    // const history = useHistory();

    return (
        <div className={styles.table}>
            <LoadingSpinerArea area="table-scenario" />
            <Table
                data={tableData}
                autoHeight
                id="table-negociacoes-fornecedor"
                className={styles['table-new-price']}
                rowClassName={styles['table-new-price-row']}
                rowHeight={64}
                headerHeight={46}
                // sortColumn={tableState.sortColumn}
                // sortType={tableState.sortType}
                // onSortColumn={tableHandlers.handleSortColumns}
            >
                <Column align="center" width={60}>
                    <HeaderCell style={{ padding: '2px' }}>
                        {/* <Checkbox
                            indeterminate={indeterminate}
                            checked={selectedScenario.length === tableData.totalElements}
                            onChange={tableHandlers.handleChangeAllCheckbox}
                        /> */}
                    </HeaderCell>
                    <Cell>
                        {(rowData) => (
                            <Checkbox />
                        )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={2}>
                    <HeaderCell>
                        Produto
                    </HeaderCell>
                    <Cell dataKey="produto" height={60}>
                        {(rowData) => (
                            <Whisper
                                delayShow={0.2}
                                trigger="hover"
                                placement="bottom"
                                speaker={<Tooltip>{rowData.produto}</Tooltip>}
                            >
                                <div>
                                    <div className={styles.flex}>
                                        <p className={[styles['text-14'], styles.bold].join(' ')}>{rowData.produtoCodigo}</p>
                                        <div className={styles.leader}>Líder</div>
                                    </div>
                                    <p className={[styles['text-12'], styles.bold].join(' ')}>
                                        {rowData.produto}
                                    </p>
                                </div>
                            </Whisper>
                        )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell>
                        Família
                    </HeaderCell>
                    <Cell dataKey="familia" height={60}>
                        {(rowData) => (
                            <div className={styles.flex}>
                                <p className={[styles['text-14'], styles.bold].join(' ')}>{rowData.linha}</p>
                            </div>
                            )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell>
                        <span className={styles['flex-column']}>
                            <p>Tabela base</p>
                        </span>
                    </HeaderCell>
                    <Cell dataKey="pmz">
                        {(rowData) => (
                            <div>
                                <p className={[styles['text-14'], styles.bold].join(' ')}>
                                    R$
                                    {' '}
                                    {rowData.pmz}
                                </p>
                                <p className={[styles['text-11'], styles.bold].join(' ')}>
                                    $
                                    {' '}
                                    {rowData.pmz}
                                    %
                                </p>
                            </div>
                            )}
                    </Cell>
                </Column>
                <Column sortable width={120}>
                    <HeaderCell>
                        Percentual
                    </HeaderCell>
                    <Cell dataKey="percentual">
                        {(rowData) => (
                            <div className={styles.flex}>
                                <InputGroup className={styles['input-group']}>
                                    <Input />
                                    <InputGroup.Addon>%</InputGroup.Addon>
                                </InputGroup>
                            </div>
                )}
                    </Cell>
                </Column>
                <Column width={120}>
                    <HeaderCell />
                    <Cell dataKey="">
                        {(rowData) => (
                            <div className={styles['text-12']}>
                                <p>
                                    Deve ficar entre
                                </p>
                                <div className={styles.flex}>
                                    <span className={styles.bold}>
                                        {rowData.intervaloMinimo}
                                        %
                                    </span>
                                    <span className={styles.e}>
                                        e
                                    </span>
                                    <span className={styles.bold}>
                                        {rowData.intervaloMaximo}
                                        %
                                    </span>
                                </div>

                            </div>
                )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell>
                        Última atualização
                    </HeaderCell>
                    <Cell dataKey="ultimaAtualizacao">
                        {(rowData) => (
                            <div>
                                <p className={styles['text-14']}>
                                    {rowData.responsavel}
                                </p>
                                <p className={styles['text-11']}>
                                    {rowData.data}
                                </p>
                            </div>
                            )}
                    </Cell>
                </Column>
                <Column flexGrow={1}>
                    <HeaderCell />
                    <Cell dataKey="button">
                        {(rowData) => (
                            <Button className={styles['btn-delete']}>
                                {/* <img src={TrashIcon} alt="" className={styles['trash-icon']} /> */}
                                <DeleteIcon />
                            </Button>
                            )}
                    </Cell>
                </Column>

            </Table>
            <Pagination
                // activePage={tableState.page + 1 || null}
                // displayLength={tableData.size}
                // total={tableData.totalElements || null}
                // onChangePage={tableHandlers.handleChangePage}
                // onChangeLength={tableHandlers.handleChangeSize}
                lengthMenu={lengthMenu}
            />
        </div>

    );
};
