import React from 'react';
import {
    Button,
    Checkbox,
    Table,
    Tooltip,
    Whisper,
} from 'rsuite';
import { LoadingSpinerArea } from '../../../../../../../components/LoadingSpinner';
import { Indicator } from '../../../../../../CalculadoraPrevisaoDemanda/Components/Indicator';
import { LinkButton } from '../../../../../../../components';

import styles from './TableCurrentBase.module.scss';
import { outrosCustosCalc } from '../../../../calculos';
import { useCurrentBaseTableStore } from '../../CurrentBaseTable.store';
import { EditBaseTableItemDataType, EditBaseTableListResponse } from '../../../EditBaseTable/types';
import { tableLengthMenu } from '../../../../utils';
import CurrencyFormat from 'react-currency-format';

const {
 Column, HeaderCell, Cell, Pagination,
} = Table;

type TableCurrentBaseProps = {
    data: EditBaseTableListResponse,
    onChangeCheckbox?: (product: EditBaseTableItemDataType, checked: boolean) => void,
    onChangePage?: (value: number) => void,
    onChangeLength?: (value: number) => void,
}

export const TableCurrentBase = ({
    data,
    onChangeCheckbox,
    onChangeLength,
    onChangePage
}: TableCurrentBaseProps) => {
    const { tabelaBaseItemsSelectedList, setOpenModalProductCosts } = useCurrentBaseTableStore()
    // const indeterminate = selectedScenario.length > 0 && selectedScenario.length < tableData.totalElements;
    const handleOpenModal = (product: EditBaseTableItemDataType) => {
        setOpenModalProductCosts(true, product);
    };

    return (
        <div className={styles['table-scenario-container']}>
            <LoadingSpinerArea area="tabela-base" />
            <Table
                data={data?.content}
                autoHeight
                id="table-negociacoes-fornecedor"
                className={styles['table-scenario']}
                rowClassName={styles['table-scenario-row']}
                rowHeight={64}
                headerHeight={46}
                // sortColumn={tableState.sortColumn}
                // sortType={tableState.sortType}
                // onSortColumn={tableHandlers.handleSortColumns}
            >
                <Column align="center" width={60}>
                    <HeaderCell style={{ padding: '2px' }}>
                        {/* <Checkbox
                            indeterminate={indeterminate}
                            checked={selectedScenario.length === tableData.totalElements}
                            onChange={tableHandlers.handleChangeAllCheckbox}
                        /> */}
                    </HeaderCell>
                    <Cell>
                        {(rowData) => (
                            <Checkbox
                                checked={tabelaBaseItemsSelectedList.some((item) => item.produto.id === rowData?.produto.id)}
                                value={rowData?.produto.id}
                                onChange={(v, checked) => onChangeCheckbox(rowData, checked)}
                            />
                        )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={2}>
                    <HeaderCell>
                        Produto
                    </HeaderCell>
                    <Cell dataKey="produto" height={60}>
                        {(rowData) => (
                            <Whisper
                                delayShow={0.2}
                                trigger="hover"
                                placement="bottom"
                                speaker={<Tooltip>{rowData.produto.descricaoProduto}</Tooltip>}
                            >
                                <div>
                                    <div className={styles.flex}>
                                        <p className={styles['main-bold-text']}>{rowData.produto.id}</p>
                                        <span className={styles['leader-text']}>Líder</span>
                                    </div>
                                    <p className={styles['subtext-bold']}>
                                        {rowData.produto.descricaoProduto}
                                    </p>
                                </div>
                            </Whisper>
                        )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell>
                        Família
                    </HeaderCell>
                    <Cell dataKey="linha" height={60}>
                        {(rowData) => (
                            <div className={styles.flex}>
                                <p className={styles['main-bold-text']}>{rowData.produto.produtoMasterFamilia}</p>
                            </div>
                            )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell>UF</HeaderCell>
                    <Cell dataKey="uf">
                        {(rowData) => (
                            <div>
                                <p className={styles['main-bold-text']}>
                                    NACIONAL
                                </p>
                                {/* <LinkButton fontSize="10px">Ver UFs</LinkButton> */}
                            </div>
                            )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell className={styles.line}>
                        <span className={styles['flex-column']}>
                            <p>Custo</p>
                            <p>fabricação</p>
                        </span>
                    </HeaderCell>
                    <Cell
                        dataKey="custoFabricacao"
                        className={styles.line}
                    >
                        {(rowData) => (
                            <p className={styles['main-bold-text']}>
                                <CurrencyFormat
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                    value={rowData.parametros.custoFabricacao}
                                    displayType={'text'}
                                    prefix={'R$ '}
                                    decimalSeparator={','}
                                    thousandSeparator={'.'}
                                />
                            </p>
                        )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell>
                        <span className={styles['flex-column']}>
                            <p>Outros</p>
                            <p>custos</p>
                        </span>
                    </HeaderCell>
                    <Cell dataKey="outros">
                        {(rowData) => (
                            <div>
                                <p className={styles['main-bold-text']}>
                                    <CurrencyFormat
                                        fixedDecimalScale={true}
                                        decimalScale={2}
                                        value={outrosCustosCalc(rowData)}
                                        displayType={'text'}
                                        prefix={'R$ '}
                                        decimalSeparator={','}
                                        thousandSeparator={'.'}
                                    />
                                </p>
                                <Button
                                    className={styles['btn-open-modal']}
                                    onClick={() => handleOpenModal(rowData)}
                                >
                                    Abrir
                                </Button>
                            </div>
                            )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell>PMZ</HeaderCell>
                    <Cell dataKey="pmz">
                        {(rowData) => (
                            <p className={styles['main-bold-text']}>
                                <CurrencyFormat
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                    value={rowData.parametros.precoMargemZero}
                                    displayType={'text'}
                                    prefix={'R$ '}
                                    decimalSeparator={','}
                                    thousandSeparator={'.'}
                                />
                            </p>
                        )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell className={styles.line}>
                        <span className={styles['flex-column']}>
                            <p>Tabela</p>
                            <p>base</p>
                        </span>
                    </HeaderCell>
                    <Cell dataKey="tabelaAtual" className={styles.line}>
                        {(rowData) => (
                            <div>
                                <p className={styles['main-bold-text']}>
                                    <CurrencyFormat
                                        fixedDecimalScale={true}
                                        decimalScale={2}
                                        value={rowData?.parametros.precoTabela}
                                        displayType={'text'}
                                        prefix={'R$ '}
                                        decimalSeparator={','}
                                        thousandSeparator={'.'}
                                    />
                                </p>
                                <div className={styles['margin-indicator']}>
                                    <Whisper
                                        delayShow={0.2}
                                        trigger="hover"
                                        placement="bottom"
                                        speaker={<Tooltip>Margem de lucro operacional</Tooltip>}
                                    >
                                        <span className={styles['currency-indicator']}>
                                            $
                                            {' '}
                                            {(rowData.parametros.margemOperacional * 100).toFixed(1)}
                                            %
                                        </span>
                                    </Whisper>
                                </div>
                            </div>
                        )}
                    </Cell>
                </Column>
            </Table>
            <Pagination
                activePage={data?.page.number + 1 || null}
                displayLength={data?.page.size}
                total={data?.page.totalElements || null}
                onChangePage={onChangePage}
                onChangeLength={onChangeLength}
                lengthMenu={tableLengthMenu}
            />
        </div>

    );
};
