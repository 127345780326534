import { combineReducers } from '@reduxjs/toolkit';
import contextMenu from './contextMenu';
import datakeys from './datakeys';
import datapoints from './datapoints';
import datapointsCache from './datapointsCache';
import disabledCompetitors from './disabledCompetitors';
import expandedRowKey from './expandedRowKey';
import filters from './filters/filters';
import isLayoutFixed from './isLayoutFixed';
import modals from './modals';
import modalsBoolean from './modalsBoolean';
import pagination from './pagination';
import quickActionsFilters from './quickActionsFilter';
import savedFilter from './savedFilters';
import segmentos from './segmentos';
import selectedDatapoints from './selectedDatapoints';
import sort from './sort';
import totalElements from './totalElements';

const rootReducer = combineReducers({
    modals,
    savedFilter,
    quickActionsFilters,
    datapoints,
    datapointsCache,
    totalElements,
    sort,
    contextMenu,
    expandedRowKey,
    filters,
    segmentos,
    selectedDatapoints,
    modalsBoolean,
    pagination,
    disabledCompetitors,
    datakeys,
    isLayoutFixed,
});

export type GerenciadorPrecosStateProps = ReturnType<typeof rootReducer>;

export const selectorGerenciadorPrecos = (state: Record<'gerenciadorPrecosReducer', GerenciadorPrecosStateProps>) => state.gerenciadorPrecosReducer;

export default rootReducer;
