import React, { ReactNode } from 'react';
import styles from './styles.module.scss';

export interface IBoxRoot {
    children: ReactNode;
    marginRight?: string;
    type?: 'container' | 'up' | 'down';
    height?: string;
    className?: string;
}

export const BoxRoot = ({
    children,
    className,
    type = 'container',
    marginRight = '8px',
    height,
}: IBoxRoot) => (
    <div
        className={[styles[type], className].join(' ')}
        style={{ marginRight, height }}
    >
        {children}
    </div>
);
