import { Slot } from '@radix-ui/react-slot';
import classNames from 'classnames';
import _ from 'lodash';
import React, { ComponentProps, HTMLAttributes, HtmlHTMLAttributes, InputHTMLAttributes, LabelHTMLAttributes, forwardRef } from 'react';
import CurrencyInput from 'react-currency-input';
import { Checkbox, DatePicker, InputGroup, InputNumber, RadioGroup as RSRadioGroup, Toggle } from 'rsuite';
import { CheckboxProps } from 'rsuite/lib/Checkbox';
import { DatePickerProps } from 'rsuite/lib/DatePicker';
import { InputNumberProps } from 'rsuite/lib/InputNumber';
import { RadioGroupProps } from 'rsuite/lib/RadioGroup';
import SelectPicker, { SelectPickerProps } from 'rsuite/lib/SelectPicker';
import { ToggleProps } from 'rsuite/lib/Toggle';
import { BaseComponentProps } from '../../@types/BaseComponent';
import { CheckboxGroup } from '../Form/CheckboxGroup';
import { InfoRadio } from '../InfoRadio';
import styles from './InfoInput.module.scss';

const InputBox = ({ className, asChild, ...props }: BaseComponentProps<HtmlHTMLAttributes<HTMLDivElement>>) => {
    const Comp = asChild ? Slot : 'div';
    return <Comp className={classNames(styles['info-inputbox'], className)} {...props} />;
};

export const InfoLabel = forwardRef<HTMLLabelElement, LabelHTMLAttributes<HTMLLabelElement>>(({ className, children, ...props }, ref) => (
    <label ref={ref} className={classNames(styles['info-label'], className)} {...props}>
        {children}
    </label>
));

export const InfoInput = forwardRef<HTMLInputElement, InputHTMLAttributes<HTMLInputElement>>(({ className, ...props }, ref) => (
    <input ref={ref} className={classNames(styles['info-input'], className)} {...props} />
));

export const InfoSelect = ({ className, isLoading = false, data, ...props }: SelectPickerProps & { isLoading?: boolean }) => (
    <SelectPicker data={isLoading ? [] : data} className={classNames(styles['info-select'], className)} {...props} />
);

type InfoToggleProps = Exclude<ToggleProps, 'onChange' | 'checked'> & {
    onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
};

export const InfoToggle = ({ value, onChange, ...props }: InfoToggleProps) => (
    <Toggle
        size="sm"
        checked={value}
        onChange={(checked, e) => {
            // @ts-ignore
            onChange?.({ ...e, target: { ...e.target, value: checked } }, checked);
        }}
        {...props}
    />
);

const InfoDatePicker = ({ className, disabled, value, onChange, ...props }: DatePickerProps) => (
    <DatePicker
        className={classNames(styles['info-date-picker'], disabled && styles.disabled, className)}
        value={disabled ? undefined : value}
        disabled={disabled}
        onChange={(value, event) => {
            onChange?.(value ?? undefined, event);
        }}
        {...props}
    />
);

const InputAddon = ({ className, ...props }: HTMLAttributes<HTMLSpanElement>) => (
    <InputGroup.Addon className={classNames(styles['info-input-addon'], className)} {...props} />
);

type NumericProps = {
    value: number;
    allowNegative?: boolean;
    allowEmpty?: boolean;
    precision?: string;
    decimalSeparator?: string;
    thousandSeparator?: string;
    inputType?: 'text' | 'number' | 'tel';
    selectAllOnFocus?: boolean;
    prefix?: string;
    suffix?: string;
    autoFocus?: boolean;
    onChangeEvent?: (event: React.ChangeEvent<HTMLInputElement>, maskedValue: string, floatValue: number) => void;
} & HtmlHTMLAttributes<HTMLElement>;

const Numeric = ({ className, suffix, prefix, onChangeEvent, ...props }: NumericProps) => {
    return (
        <InputGroup className={classNames(styles['info-numeric-input'], className)}>
            {prefix && <InputAddon>{prefix}</InputAddon>}
            <CurrencyInput
                onChangeEvent={(event, maskedValue, floatValue) => {
                    onChangeEvent?.(
                        // convert the event.target.value to number
                        // to work with react-hook-form
                        {
                            ...event,
                            target: {
                                ...event.target,
                                value: floatValue as never,
                            },
                        },
                        maskedValue,
                        floatValue,
                    );
                }}
                {...props}
            />
            {suffix && <InputAddon>{suffix}</InputAddon>}
        </InputGroup>
    );
};

type NumberProps = InputNumberProps;

const Number = ({ className, onChange, ...props }: NumberProps) => (
    <InputNumber
        className={classNames(styles['info-number-input'], className)}
        onChange={(value, event) => {
            onChange?.(_.toNumber(value), event);
        }}
        {...props}
    />
);

type ErrorProps = { message?: string } & Omit<HTMLAttributes<HTMLSpanElement>, 'children'>;

const Error = ({ message, className, ...props }: ErrorProps) => {
    if (!message) return null;
    return (
        <span className={classNames(styles['info-error-message'], className)} {...props}>
            {message}
        </span>
    );
};

type CheckProps = Omit<CheckboxProps, 'onChange'> & {
    onChange?: (event: React.SyntheticEvent<HTMLInputElement, Event>, value: boolean, checked: boolean) => void;
};

const Check = ({ className, onChange, ...props }: CheckProps) => (
    <Checkbox
        className={classNames(styles['info-checkbox'], className)}
        onChange={(value, checked, event) => {
            onChange?.(
                {
                    ...event,
                    target: {
                        ...event.target,
                        // @ts-ignore
                        value: checked,
                    },
                },
                value,
                checked,
            );
        }}
        {...props}
    />
);

const RadioGroup = ({ className, ...props }: RadioGroupProps) => {
    return <RSRadioGroup className={classNames(styles['radio-group'], className)} {...props} />;
};

export type FieldDescriptionProps = ComponentProps<'p'>;

const FormDescription = forwardRef<HTMLParagraphElement, FieldDescriptionProps>(({ className, ...props }, ref) => {
    return <p {...props} ref={ref} className={classNames(styles['form-description'], className)} />;
});

FormDescription.displayName = 'FormDescription';

InputBox.Label = InfoLabel;
InputBox.Input = InfoInput;
InputBox.Select = InfoSelect;
InputBox.DatePicker = InfoDatePicker;
InputBox.Numeric = Numeric;
InputBox.Number = Number;
InputBox.Error = Error;
InputBox.Addon = InputAddon;
InputBox.Check = Check;
InputBox.CheckGroup = CheckboxGroup;
InputBox.Toggle = InfoToggle;
InputBox.RadioGroup = RadioGroup;
InputBox.Radio = InfoRadio;
InputBox.FormDescription = FormDescription;

export { InputBox };
