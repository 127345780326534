import { create } from 'zustand';
import {
    CustoFabricacaoBodyType,
    CustosGeraisTabelaBodyType,
    CustosProdutoBodyType,
    EditBaseTableItemDataType,
    PriceAnalysisModalTableType,
    ProdutoDataType
} from '../EditBaseTable/types';

type State = {
    openModalPriceAnalysisComercial: boolean,
    openModalActivateProductOffer: boolean,
    priceAnalysisComercialTableData: ProdutoDataType[];
}

type Actions = {
    setManageComercialTableState: (name: string, data: any) => void;
    setOpenModalPriceAnalysisComercial: (open: boolean, data) => void;
    reset: () => void;
}

const initialState: State = {
    priceAnalysisComercialTableData: [],
    openModalPriceAnalysisComercial: false,
    openModalActivateProductOffer: false,
};

export const useManageComercialTableStore = create<State & Actions>((set) => ({
    ...initialState,
    setManageComercialTableState: (name, data) => {
        set(() => ({
            [name]: data,
        }));
    },
    setOpenModalPriceAnalysisComercial: (open: boolean, data: EditBaseTableItemDataType) => {
        set(() => ({
            openModalPriceAnalysisComercial: open,
            priceAnalysisComercialTableData: [
                {
                    name: 'Tabela atual',
                    price: 23.30,
                    diff: null
                },
                {
                    name: 'Preço médio atual',
                    price: 23.30,
                    diff: 12.78
                },
                {
                    name: 'Novo preço com desconto máximo',
                    price: 23.30,
                    diff: 12.78
                },
                {
                    name: 'Novo preço de tabela',
                    price: 23.30,
                    diff: null
                },
                {
                    name: 'Preço médio projetado',
                    price: 23.30,
                    diff: 12.34
                },
            ]
        }));
    },
    reset: () => {
        set(initialState);
    },
}));
