import React, { ReactNode } from 'react';
import { Content } from 'rsuite';
import styles from './ContentContainer.module.scss';

type ContentContainerProps = {
    children: ReactNode,
    className?: string,
    padding?: string,
    margin?: string,
}

export const ContentContainer = ({
    children,
    className,
    padding = '12px',
    margin = '8px 0',
}: ContentContainerProps) => {
    return (
        <Content
            className={[styles.content, className].join(' ')}
            style={{ padding, margin }}
        >
            {children}
        </Content>
    );
};
