import React, { useState } from 'react';
import { Button, Panel } from 'rsuite';
import DeleteIcon from '@material-ui/icons/Delete';
import { ContentContainer, LoadingSpinerArea } from '../../../../../components';
import { MainContainer, ResultsFoundActionArea } from '../../components';
import {
    NewPriceTableDefine,
    NewPriceTableForm,
    PaymentTermAndDiscount,
    NewPriceTableTable,
    GeneralRules,
    PriceLimits,
    TablePanelHeader
} from './components';
import * as service from './services'
import styles from './CreateComercialTable.module.scss';
import { useCreateComercialTableStore } from './CreateComercialTable.store';

export const CreateComercialTable = () => {
    const [panelHeaderStatus, setPanelHeaderStatus] = useState(false);

    const { createComercialTableBody, setCreateComercialTableState } = useCreateComercialTableStore();

    const handleSaveTabelaComercial = async () => {
        const res = await service.createSaveTabelaComercial(createComercialTableBody)

        if (res.status === 200) {
            console.log('sucesso!!')
        }
    }

    const handlePanelAction = () => {
        setPanelHeaderStatus(!panelHeaderStatus);
    };

    return (
        <MainContainer>
            <LoadingSpinerArea area="save-tabela-comercial" />
            <NewPriceTableForm />
            <NewPriceTableDefine />
            {/* <PaymentTermAndDiscount /> */}
            <GeneralRules />
            <PriceLimits />
            {/* <Panel
                className={styles['table-panel']}
                header={
                    <TablePanelHeader
                        title="Detalhamento por produto"
                        subtitle="Defina regras específicas por produto"
                        status={panelHeaderStatus}
                    />
                }
                collapsible
                onSelect={handlePanelAction}
            >
                <ResultsFoundActionArea value={23} className={styles['table-header']}>
                    <Button className={styles['btn-delete']} appearance="default">
                        <DeleteIcon />
                    </Button>
                    <Button className={styles['btn-change']} appearance="ghost">
                        Alterar
                    </Button>
                </ResultsFoundActionArea>
                <NewPriceTableTable />
            </Panel> */}
            <ContentContainer className={styles['save-wrapper']}>
                <Button
                    className={styles['btn-save']}
                    appearance="primary"
                    onClick={handleSaveTabelaComercial}
                >
                    Salvar tabela
                </Button>
            </ContentContainer>
        </MainContainer>
    );
};
