import React from 'react';
import { SelectPicker, InputGroup } from 'rsuite';
import styles from './PriceLimits.module.scss';
import { BorderedContentContainer } from '../../../../components';
import { useTipoLimiteFilter } from '../../hooks/useTipoLimiteFilter';
import { InputCurrency } from '../../../../../../../components';
import { useCreateComercialTableStore } from '../../CreateComercialTable.store';

export const PriceLimits = () => {
    const { tipoLimiteData } = useTipoLimiteFilter();
    const { createComercialTableBody, setCreateComercialTableState } = useCreateComercialTableStore();

    const handleChangePriceLimits = (name: string, value: string) => {
        const obj = {
            ...createComercialTableBody,
            limites: [
                {
                    ...createComercialTableBody.limites[0],
                    [name]: value,
                }
            ]
        }
        setCreateComercialTableState('createComercialTableBody', obj)
    }

    return (
        <BorderedContentContainer
            title="Limites de preço"
        >
            <div className={styles.row}>
                <div className={styles['select-group']}>
                    <label htmlFor="nome">Tipo de limite</label>
                    <SelectPicker
                        placeholder="Selecione o tipo de limite"
                        defaultValue="PERCENTUAL_TABELA_BASE"
                        data={tipoLimiteData}
                    />
                </div>
                <div className={styles['input-group-tax']}>
                    <label htmlFor="tabela-base">Percentual mínimo</label>
                    <InputGroup>
                        <InputCurrency
                            className={styles['currency-input']}
                            value={createComercialTableBody.limites[0].percentualMinimo}
                            precision='1'
                            decimalSeparator=","
                            thousandSeparator='.'
                            onChangeEvent={(_e, _s, value) => handleChangePriceLimits('percentualMinimo', value)}
                        />
                        <InputGroup.Addon>%</InputGroup.Addon>
                    </InputGroup>
                </div>
                <div className={styles['input-group-tax']}>
                    <label htmlFor="tabela-base">Percentual máximo</label>
                    <InputGroup>
                        <InputCurrency
                            className={styles['currency-input']}
                            value={createComercialTableBody.limites[0].percentualMaximo}
                            precision='1'
                            decimalSeparator=","
                            thousandSeparator='.'
                            onChangeEvent={(_e, _s, value) => handleChangePriceLimits('percentualMaximo', value)}
                        />
                        <InputGroup.Addon>%</InputGroup.Addon>
                    </InputGroup>
                </div>
            </div>
        </BorderedContentContainer>
    );
};
