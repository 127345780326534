import { ButtonPrimary } from '../../../../../components';
import { Footer } from './Footer';
import { Form } from './Layout';
import { Content, Option, Header as OptionHeader, Subtitle as OptionSubtitle, Title as OptionTitle } from './Option';
import { Content as ContentSection, Section, Header as SectionHeader } from './Section';
import { Subtitle } from './Subtitle';
import { Title } from './Title';

const Layout = {
    Root: Form,
    Section: {
        Root: Section,
        Header: SectionHeader,
        Content: ContentSection,
    },
    Title,
    Subtitle,
    Footer,
    Action: ButtonPrimary,
    Option: {
        Root: Option,
        Title: OptionTitle,
        Subtitle: OptionSubtitle,
        Header: OptionHeader,
        Content,
    },
};

export default Layout;
