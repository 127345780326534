import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../@types/RootState';
import { ICompetitorPriceSources } from '../../pages/IPA/Entities';

type BrokenLimit = {
    isActive: boolean;
    value: boolean;
};

type ChangedPrices = {
    isActive: boolean;
};

type CompetitorPrices = {
    isActive: boolean;
};

type IsProductMaster = {
    isActive: boolean;
};

type MinDateCpi = {
    isActive: boolean;
    value: number;
    competitorPriceSources: ICompetitorPriceSources[];
};

export type PriceManagerQuickActionFilter = {
    brokenLimit: BrokenLimit;
    changedPrices: ChangedPrices;
    competitorPrices: CompetitorPrices;
    minDateCompetitorPrice: MinDateCpi;
    isProductMaster: IsProductMaster;
};

export type QuickActionFilterLimitsKeys = keyof PriceManagerQuickActionFilter;

const initialState: PriceManagerQuickActionFilter = {
    brokenLimit: {
        isActive: false,
        value: false,
    },
    changedPrices: {
        isActive: false,
    },
    competitorPrices: {
        isActive: false,
    },
    minDateCompetitorPrice: {
        isActive: false,
        value: 7,
        competitorPriceSources: [],
    },
    isProductMaster: {
        isActive: false,
    },
};

const slice = createSlice({
    name: 'filtros-state',
    initialState,
    reducers: {
        TOGGLE_QUICK_FILTER_ON(
            state,
            action: PayloadAction<QuickActionFilterLimitsKeys>,
        ) {
            state[action.payload].isActive = true;
        },
        toggleQuickFilterOff(
            state,
            action: PayloadAction<{ name: QuickActionFilterLimitsKeys }>,
        ) {
            state[action.payload.name].isActive = false;
        },
        toggleQuickFilter(
            state,
            action: PayloadAction<{ name: QuickActionFilterLimitsKeys }>,
        ) {
            state[action.payload.name].isActive =
                !state[action.payload.name].isActive;
        },
        CHANGE_QUICK_FILTER_VALUE(
            state,
            action: PayloadAction<{
                name: 'brokenLimit' | 'minDateCompetitorPrice';
                value: boolean | number;
            }>,
        ) {
            state[action.payload.name].value = action.payload.value;
        },
        togglePriceCPI(state) {
            state.competitorPrices.isActive = !state.competitorPrices.isActive;
        },
        RESET_QUICK_ACTION_FILTER() {
            return initialState;
        },
        toggleQuickActionFilter(
            state,
            action: PayloadAction<{ key: QuickActionFilterLimitsKeys }>,
        ) {
            state[action.payload.key].isActive =
                !state[action.payload.key].isActive;
        },
        setMinDateCompetitorPriceSource(
            state,
            action: PayloadAction<ICompetitorPriceSources[]>,
        ) {
            state.minDateCompetitorPrice.competitorPriceSources =
                action.payload;
        },
    },
});

const selectorQuickActionFilters = (state: RootState) => {
    return state.gerenciadorPrecosReducer.quickActionsFilters;
};

const {
    TOGGLE_QUICK_FILTER_ON,
    toggleQuickFilterOff,
    toggleQuickFilter,
    CHANGE_QUICK_FILTER_VALUE,
    RESET_QUICK_ACTION_FILTER,
    togglePriceCPI,
    toggleQuickActionFilter,
    setMinDateCompetitorPriceSource,
} = slice.actions;

export {
    CHANGE_QUICK_FILTER_VALUE,
    RESET_QUICK_ACTION_FILTER,
    selectorQuickActionFilters,
    setMinDateCompetitorPriceSource,
    TOGGLE_QUICK_FILTER_ON,
    togglePriceCPI,
    toggleQuickActionFilter,
    toggleQuickFilter,
    toggleQuickFilterOff
};

export default slice.reducer;
