import { trackPromise } from 'react-promise-tracker';
import api from '../../../../../utils/API';

const URL = process.env.REACT_APP_IPA_PRICE_TABLES_MANAGER_URL

// ########## - TABELA BASE ATUAL - ##########

export const getTabelaBaseList = (data) => trackPromise(
    api({
        url: `${URL}/produto-base/by-uf/NACIONAL`,
        method: 'GET',
        expectToken: true,
        params: {
            page: data.page,
            size: data.size,
        },
    }).then((res) => res),
    'tabela-base'
);

export const downloadTabelaBaseAtual = (data) => trackPromise(
    api({
        url: `${URL}/tabela-base/download/NACIONAL`,
        method: 'POST',
        expectToken: true,
        data: JSON.stringify(data),
        responseType: 'arraybuffer',
    })
        .then((res) => res)
        .then((download) => {
            if (download.status === 200) {
                const blob = new Blob([download.data]);
                const hiddenElement = document.createElement('a');
                hiddenElement.href = window.URL.createObjectURL(blob);
                hiddenElement.target = '_blank';
                hiddenElement.download = `tabela_base.csv`;
                hiddenElement.click();
            } else {
                return download;
            }
        }),
);
