import React, { ReactNode } from 'react';
import { ContentContainer } from '../../../../../components';
import styles from './BorderedContentContainer.module.scss';

type BorderedContentContainerProps ={
    title?: string,
    subtitle?: string,
    children?: ReactNode,
    className?: string,
    borderedContentClassName?: string,
}

export const BorderedContentContainer = ({
    title,
    subtitle,
    children,
    className,
    borderedContentClassName,
}: BorderedContentContainerProps) => {
    return (
        <ContentContainer className={className} margin="0 0 8px 0">
            <div className={styles['title-wrapper']}>
                <h6 className={styles.title}>{title}</h6>
                <p className={styles.subtitle}>{subtitle}</p>
            </div>
            <div className={[styles['bordered-content'], borderedContentClassName].join(' ')}>
                {children}
            </div>
        </ContentContainer>
    );
};
