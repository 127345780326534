import { useQuery } from '@tanstack/react-query';
import * as service from '../services';
// import { useEditBaseTableStore } from '../EditBaseTable.store';
import { useState } from 'react';
// import { TableRequestType } from '../types';

export const useComercialTableList = () => {
    // const { setEditBaseTableState } = useEditBaseTableStore();
    const [ editBaseTableList, setEditBaseTableList ] = useState({ page: 0, size: 10 })
    const [ comercialTableMutateData, setComercialTableMutateData ] = useState({})

    const getComercialTableList = async (data) => {
        const resp = await service.getComercialTableList(data);
        return resp;
    };

    const {
        isLoading, isError, data, error, isFetching, refetch,
    } = useQuery(
        {
            queryKey: ['edit-base-table-list', editBaseTableList],
            queryFn: () => getComercialTableList(editBaseTableList),
            retry: false,
            onSuccess: (data) => {
                setComercialTableMutateData(data)
            },
        },
    );

    const handleChangeComercialTableListPage = (value: number) => {
        setEditBaseTableList({...editBaseTableList, page: value - 1})
    }

    const handleChangeComercialTableListLength = (value: number) => {
        setEditBaseTableList({page: 0, size: value})
    }

    return {
        isLoading,
        isError,
        comercialTableData: data,
        error,
        isFetching,
        comercialTableMutateData,
        setComercialTableMutateData,
        handleGetEditTableList: refetch,
        setEditBaseTableList,
        handleChangeComercialTableListPage,
        handleChangeComercialTableListLength
    };
};
