import React from 'react'
import { ContextMenuContent, ContextMenuItem, ContextMenuSeparator } from '../../../../../../../components'

export const NewPriceTableInputContextMenu = () => {
    return (
        <ContextMenuContent>
            {/* <ContextMenuItem
                // onClick={() =>
                //     handleOpenPriceCompositionModal(
                //         contextMenuDatapoint.productId,
                //         contextMenuDatapoint.storeId,
                //     )
                // }
            >
                Todas as tabelas do produto
            </ContextMenuItem>
            <ContextMenuItem
                // onClick={() =>
                //     handleOpenCompositionRuleModal(
                //         contextMenuDatapoint.ruleId,
                //         contextMenuDatapoint.productsToBePricedId,
                //     )
                // }
            >
                Desfazer alterações de preço
            </ContextMenuItem>
            <ContextMenuSeparator /> */}
            <ContextMenuItem
                // onClick={() => {
                //     onOpenModal('price-event-history');
                // }}
            >
                Limite de preço
            </ContextMenuItem>
            {/* <ContextMenuSeparator />
            <ContextMenuItem
                // onClick={() =>
                //     handleOpenPriceCompositionModal(
                //         contextMenuDatapoint.productId,
                //         contextMenuDatapoint.storeId,
                //     )
                // }
            >
                Editar custos do produto
            </ContextMenuItem>
            <ContextMenuItem
                // onClick={() =>
                //     handleOpenCompositionRuleModal(
                //         contextMenuDatapoint.ruleId,
                //         contextMenuDatapoint.productsToBePricedId,
                //     )
                // }
            >
                Ativar oferta do produto
            </ContextMenuItem> */}
        </ContextMenuContent>
    )
}
