import React, { ReactNode } from 'react';
import styles from './TabelaBaseFormBox.module.scss';

type TabelaBaseFormBoxProps = {
    children?: ReactNode,
    title?: string,
    totalValue?: string | number,
    className?: string,
}

export const TabelaBaseFormBox = ({ children, title, totalValue, className }: TabelaBaseFormBoxProps) => {
    return (
        <div className={[styles.container, className].join(' ')}>
            {title ? <p className={styles.title}>{title}</p> : null}
            {children}
            <div className={styles.footer}>
                <span className={styles['total-text']}>Total</span>
                {' '}
                <span className={styles['total-value']}>{totalValue}</span>
            </div>
        </div>
    );
};
