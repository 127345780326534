import React from 'react';
import ModalConfirm from '../../../../../../../components/ModalConfirm';
import checkGreenIcon from '../../../../../../../assets/icons/icon_check_circle.svg'
import styles from './ModalEditConfirmation.module.scss';
import { LoadingSpiner } from '../../../../../../../components';

type ModalCompetitionProps = {
    show?: boolean,
    onCancel?: () => void,
    onConfirm?: () => void,
}

export const ModalEditConfirmation = ({ show, onCancel, onConfirm}: ModalCompetitionProps) => {
    return (
        <ModalConfirm
            show={show}
            modalWidth="500px"
            titleFontSize="16px"
            footerAlign="center"
            confirmButton="Salvar"
            cancelButton="Fechar"
            className={styles['modal-edit-confirmation']}
            onCancel={onCancel}
            onConfirm={onConfirm}
        >
            <LoadingSpiner size="md" />
            <div className={styles.content}>
                <img src={checkGreenIcon} alt="" />
                <h5 className={styles.title}>Você tem certeza que quer alterar os custos do produto?</h5>
                <p className={styles.subtitle}>
                    Ao alterar os custos do produto na tabela base os valores serão atualizados
                    em todas as demais tabelas e as margens serão recalculadas a partir dos novos valores.
                </p>
            </div>
        </ModalConfirm>
    );
};
