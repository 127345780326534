import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import * as service from '../services';
import { canaisFilterLabel } from '../../../utils';

export const useCanaisFilter = () => {

    const getCanaisData = async () => {
        const res = await service.getCanaisList();

        if (res.status === 200) {
            const list = res.data.map((item) => ({ label: canaisFilterLabel[item], value: item }));
            return list;
        }

        return [];
    };

    const {
        data,
        isLoading,
        isError,
        isSuccess,
        refetch,
    } = useQuery({
        queryKey: ['canais'],
        queryFn: getCanaisData,
        retry: false,
    });

    return {
        canaisData: data,
        isError,
        isSuccess,
        refetchCanais: refetch,
    };
};
