import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
    Button,
    Checkbox,
    Table,
    Tooltip,
    Whisper,
    InputGroup,
    Input,
} from 'rsuite';
import EditIcon from '@material-ui/icons/Edit';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
// import { selectorCalculadoraPrevisaoDemanda } from '../../../../reducers/calculadoraPrevisaoDemanda';
// import {
//     setFiltersFromEditScenario,
// } from '../../../../reducers/calculadoraPrevisaoDemanda/filtros';
// import {
//     setInputFromEditScenario,
// } from '../../../../reducers/calculadoraPrevisaoDemanda/inputCalculos';
// import {
//     setResultFromEditScenario,
//     setResultScenarioData,
// } from '../../../../reducers/calculadoraPrevisaoDemanda/resultScenario';
import { LoadingSpinerArea } from '../../../../../../../components/LoadingSpinner';
import { Indicator } from '../../../../../../CalculadoraPrevisaoDemanda/Components/Indicator';
import { LinkButton } from '../../../../../../../components';

import styles from './RetailConsultCurrentTable.module.scss';

const {
 Column, HeaderCell, Cell, Pagination,
} = Table;

const lengthMenu = [
    {
        value: 10,
        label: 10,
    },
    {
        value: 20,
        label: 20,
    },
];

const tableData = [
    {
        produtoCodigo: '249',
        produto: 'PROPOMAX Kids 140 ml',
        linha: 'PROPOMAX',
        uf: 'Nacional',
        custoFabricacao: 4.29,
        cfInd: 1.2,
        outros: 12.4,
        outrosInd: 39.2,
        pmz: 7.64,
        pmzInd: 23.8,
        tabelaAtual: 15.90,
        taInd: 18.6,
        precoMedioAtual: 11.2,
        pmInd: 42.12,
        pmIndd: 2.1,
        vendas: 2400000,
        novoPrecoTabela: 17.45,
        npInd: 3.2356,
        margemLucroBruto: 43.9,
        mlInd: 6.294835,
        mlIndd: 76.2999,
        margemLucroOperacional: 20.5,
        mloInd: 4.987987,
        mloIndd: 6.23325435,
    },
    {
        produtoCodigo: '249',
        produto: 'PROPOMAX Kids 140 ml',
        linha: 'PROPOMAX',
        uf: 'Nacional',
        custoFabricacao: 4.29,
        cfInd: 1.2,
        outros: 12.4,
        outrosInd: 39.2,
        pmz: 7.64,
        pmzInd: 23.8,
        tabelaAtual: 15.90,
        taInd: 18.6,
        precoMedioAtual: 11.2,
        pmInd: 42.12,
        pmIndd: 2.1,
        vendas: 2400000,
        novoPrecoTabela: 17.45,
        npInd: 3.2356,
        margemLucroBruto: 43.9,
        mlInd: 6.294835,
        mlIndd: 76.2999,
        margemLucroOperacional: 20.5,
        mloInd: 4.987987,
        mloIndd: 6.23325435,
    },
];

export const RetailConsultCurrentTable = () => {
    // const { savedScenario } = useSelector(selectorCalculadoraPrevisaoDemanda);
    // const selectedScenario = savedScenario.selectedScenarioItemList;
    // const tableData = savedScenario.savedScenarioData;
    // const indeterminate = selectedScenario.length > 0 && selectedScenario.length < tableData.totalElements;

    // const {
    //     tableHandlers,
    //     tableState,
    // } = useTableActions();

    // const { resultHandlers } = useGetScenarioResults();

    // const dispatch = useDispatch();

    // const history = useHistory();

    return (
        <div className={styles['table-consult-current-container']}>
            <LoadingSpinerArea area="table-scenario" />
            <Table
                data={tableData}
                autoHeight
                id="table-negociacoes-fornecedor"
                className={styles['table-scenario']}
                rowClassName={styles['table-scenario-row']}
                rowHeight={64}
                headerHeight={46}
                // sortColumn={tableState.sortColumn}
                // sortType={tableState.sortType}
                // onSortColumn={tableHandlers.handleSortColumns}
            >
                <Column align="center" width={60}>
                    <HeaderCell style={{ padding: '2px' }}>
                        {/* <Checkbox
                            indeterminate={indeterminate}
                            checked={selectedScenario.length === tableData.totalElements}
                            onChange={tableHandlers.handleChangeAllCheckbox}
                        /> */}
                    </HeaderCell>
                    <Cell>
                        {(rowData) => (
                            <Checkbox />
                        )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={2}>
                    <HeaderCell>
                        Produto
                    </HeaderCell>
                    <Cell dataKey="produto" height={60}>
                        {(rowData) => (
                            <Whisper
                                delayShow={0.2}
                                trigger="hover"
                                placement="bottom"
                                speaker={<Tooltip>{rowData.produto}</Tooltip>}
                            >
                                <div>
                                    <p className={styles['main-bold-text']}>{rowData.produtoCodigo}</p>
                                    <p className={styles['subtext-bold']}>
                                        {rowData.produto}
                                    </p>
                                </div>
                            </Whisper>
                        )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell>
                        Linha
                    </HeaderCell>
                    <Cell dataKey="linha" height={60}>
                        {(rowData) => (
                            <div className={styles.flex}>
                                <p className={styles['main-bold-text']}>{rowData.linha}</p>
                            </div>
                            )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell>UF</HeaderCell>
                    <Cell dataKey="uf">
                        {(rowData) => (
                            <div className={styles.flex}>
                                <p className={styles['main-bold-text']}>
                                    {rowData.uf}
                                </p>
                            </div>
                            )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell className={styles.line}>
                        <span className={styles['flex-column']}>
                            <p>Custo</p>
                            <p>fabricação</p>
                        </span>
                    </HeaderCell>
                    <Cell
                        dataKey="custoFabricacao"
                        className={styles.line}
                    >
                        {(rowData) => (
                            <div>
                                <p className={styles['main-bold-text']}>
                                    R$
                                    {rowData.custoFabricacao}
                                </p>
                                <div className={[styles['neutro-indicator'], styles['pmz-indicator']].join(' ')}>
                                    {rowData.cfInd}
                                    %
                                </div>
                            </div>
                            )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell>
                        <span className={styles['flex-column']}>
                            <p>Outros</p>
                            <p>custos</p>
                        </span>
                    </HeaderCell>
                    <Cell dataKey="outros">
                        {(rowData) => (
                            <div>
                                <p className={styles['main-bold-text']}>
                                    R$
                                    {rowData.outros}
                                    {/* {utils.formatNumberToCurrency(rowData.outputs.new_price_scenario.price, 2)} */}
                                </p>
                                <div className={styles['outros-indicator-flex']}>
                                    <div className={styles['neutro-indicator']}>
                                        {rowData.outrosInd}
                                        %
                                    </div>
                                    <LinkButton fontSize="10px">Editar</LinkButton>
                                </div>
                            </div>
                            )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell>PMZ</HeaderCell>
                    <Cell dataKey="pmz">
                        {(rowData) => (
                            <div>
                                <p className={styles['main-bold-text']}>
                                    R$
                                    {rowData.pmz}
                                </p>
                                <div className={[styles['neutro-indicator'], styles['pmz-indicator']].join(' ')}>
                                    {rowData.pmzInd}
                                    %
                                </div>
                            </div>
                            )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell className={styles.line}>
                        <span className={styles['flex-column']}>
                            <p>Tabela</p>
                            <p>atual</p>
                        </span>
                    </HeaderCell>
                    <Cell dataKey="tabelaAtual" className={styles.line}>
                        {(rowData) => (
                            <p className={styles['main-bold-text']}>
                                R$
                                {' '}
                                {rowData.tabelaAtual}
                                {/* {utils.formatLocaleNumber(rowData.outputs.new_price_scenario?.demand_forecast, 0)} */}
                            </p>
                )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell>
                        <span className={styles['flex-column']}>
                            <p>Margem</p>
                            <p>lucro bruto</p>
                        </span>
                    </HeaderCell>
                    <Cell dataKey="margemLucroBruto">
                        {(rowData) => (
                            <div>
                                <p className={styles['main-bold-text']}>
                                    {rowData.tabelaAtual}
                                    {' '}
                                    %
                                </p>
                                <p className={styles.subtext}>
                                    R$
                                    {' '}
                                    {rowData.npInd}
                                    {/* {utils.formatLocaleNumber(rowData.outputs.base_price_scenario?.demand_forecast, 0)} */}
                                </p>
                            </div>
                            )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell>
                        <span className={styles['flex-column']}>
                            <p>Margem lucro</p>
                            <p>operacional</p>
                        </span>
                    </HeaderCell>
                    <Cell dataKey="margemLucroOperacional">
                        {(rowData) => (
                            <div>
                                <p className={styles['main-bold-text']}>
                                    {rowData.tabelaAtual}
                                    {' '}
                                    %
                                </p>
                                <p className={styles.subtext}>
                                    R$
                                    {' '}
                                    {rowData.npInd}
                                </p>
                            </div>
                            )}
                    </Cell>
                </Column>

            </Table>
            <Pagination
                // activePage={tableState.page + 1 || null}
                // displayLength={tableData.size}
                // total={tableData.totalElements || null}
                // onChangePage={tableHandlers.handleChangePage}
                // onChangeLength={tableHandlers.handleChangeSize}
                lengthMenu={lengthMenu}
            />
        </div>

    );
};
