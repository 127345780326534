import classNames from 'classnames';
import React, { ComponentProps, forwardRef } from 'react';
import styles from './Layout.module.scss';

export type FormProps = ComponentProps<'form'>;

const Form = forwardRef<HTMLFormElement, FormProps>(({ className, ...props }, ref) => {
    return <form {...props} ref={ref} className={classNames(styles.wrapper, className)} />;
});

Form.displayName = 'Form';

export { Form };
