import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import * as service from '../services';

export const useProductFilter = () => {
    const [timer, setTimer] = useState(null)
    const [baseProductData, setBaseProductData] = useState({list: [], cache: [], value: []})

    const [searchProducts, setSearchProducts] = useState({page: 0, size: 1000});

    const getBaseProductData = async (params: any) => {
        const res = await service.getBaseProductList(params);

        if (res.status === 200) {
            const list = res.data.content.map((item) =>
                ({
                    label: `${item.produto.codigoInternoProduto} - ${item.produto.descricaoProduto}`,
                    value: item.produto.codigoInternoProduto
                }));

            const value = res.data.content.map((item) => item.produto.codigoInternoProduto)
            setBaseProductData((prevState) => ({
                ...prevState,
                list,
                cache: list,
                value,
            }));

            return list;
        }
        return [];
    };

    const {
        data,
        isLoading,
        isError,
        isSuccess,
        refetch,
    } = useQuery({
        queryKey: ['base-products', searchProducts],
        queryFn: () => getBaseProductData(searchProducts),
        retry: false,
    });

    const handleAddBaseProduct = (item) => {
        const newList = baseProductData.value;
        const newValue = newList.concat(item.value);
        setBaseProductData((prevState) => ({
            ...prevState,
            value: newValue,
            cache: item,
        }));
    };

    const handleRemoveBaseProduct = (item) => {
        const removedCache = baseProductData.cache.filter(
            (i) => i.value !== item.value
        );
        const newValue = baseProductData.value.filter(
            (i) => i !== item.value
        );
        setBaseProductData((prevState) => ({
            ...prevState,
            value: newValue,
            cache: removedCache,
        }));
    };

    const handleCleanBaseProduct = () => {
        setBaseProductData((prevState) => ({ ...prevState, value: [], cache: [] }));
    };

    const handleCheckBaseProduct = (item) => {
        const hasValue = baseProductData.value.includes(item.value);
        if (hasValue) {
            handleRemoveBaseProduct(item)
        } else {
            handleAddBaseProduct(item)
        }
    };

    const handleCheckAllBaseProduct = () => {
        const value = baseProductData.list.map((i) => i.value)
        const all = baseProductData.list.length === baseProductData.value.length;
        if (!all) {
            setBaseProductData((prevState) => ({
                ...prevState,
                value,
                cache: baseProductData.list,
            }));
        } else {
            handleCleanBaseProduct();
        }
    };

    const searchBaseProduct = (value: string) => {
        clearTimeout(timer);

        const newTimer = setTimeout(() => {
            setSearchProducts(value);
        }, 500);

        setTimer(newTimer);
    }

    return {
        baseProductData,
        isLoadingRegisteredProducts: isLoading,
        isError,
        isSuccess,
        searchProducts,
        refetchRegisteredProducts: refetch,
        setSearchProducts,
        handleCheckBaseProduct,
        handleCheckAllBaseProduct,
        handleCleanBaseProduct
    };
};
