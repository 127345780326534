import classNames from 'classnames';
import React, { forwardRef } from 'react';
import styles from './Chip.module.scss';

export type ChipProps = {
    theme?: 'compact';
    filled?: boolean;
    active?: boolean;
    disabled?: boolean;
    leftIcon?: React.ReactNode;
    rightIcon?: React.ReactNode;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const Chip = forwardRef<HTMLButtonElement, ChipProps>(({ leftIcon, rightIcon, className, children, filled = false, ...props }, ref) => {
    return (
        <button
            ref={ref}
            className={classNames(
                styles['info-chip'],
                {
                    [styles['info-chip--filled']]: filled,
                },
                className,
            )}
            {...props}
        >
            {leftIcon && <span className={styles['info-chip__icon']}>{leftIcon}</span>}
            <span className={styles['info-chip__content']}>{children}</span>
            {rightIcon && <span className={styles['info-chip__icon']}>{rightIcon}</span>}
        </button>
    );
});

Chip.displayName = 'Chip';

export { Chip };
