import { useQuery } from '@tanstack/react-query';
import { getHeaderUpdateTimeViewService } from '../services';

export const useHeaderUpdateTimeView = () => {

    const getUpdateDataStatus = async () => {
        const resp = await getHeaderUpdateTimeViewService();
        return resp;
    };

    const {
        isLoading, isError, data, error, isFetching, refetch,
    } = useQuery(
        ['header-update-time-view'],
        getUpdateDataStatus,
        {
            retry: false,
        },
    );

    return {
        isLoading,
        isError,
        updateData: data?.data,
        error,
        isFetching,
        refetchUpdate: refetch,
    };
};
