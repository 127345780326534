import React from 'react';
import { Input, SelectPicker, InputGroup } from 'rsuite';
import styles from './GeneralRules.module.scss';
import { BorderedContentContainer } from '../../../../components';
import { useTipoRegraFilter } from '../../hooks/useTipoRegraFilter';
import { useCreateComercialTableStore } from '../../CreateComercialTable.store';
import { InputCurrency } from '../../../../../../../components';

export const GeneralRules = () => {
    const { tipoRegraData } = useTipoRegraFilter();
    const { createComercialTableBody, setCreateComercialTableState } = useCreateComercialTableStore();

    const handleChangeGeneralRules = (name: string, value: string) => {
        const obj = {
            ...createComercialTableBody,
            regras: [
                {
                    ...createComercialTableBody.regras[0],
                    [name]: value,
                }
            ]
        }
        setCreateComercialTableState('createComercialTableBody', obj)
    }

    return (
        <BorderedContentContainer
            title="Regras gerais da tabela"
        >
            <div className={styles.row}>
                <div className={styles['select-group']}>
                    <label htmlFor="nome">Tipo de regra</label>
                    <SelectPicker
                        placeholder="Selecione o tipo de regra"
                        defaultValue="PERCENTUAL_TABELA_BASE"
                        data={tipoRegraData}
                    />
                </div>
                <div className={styles['input-group-tax']}>
                    <label htmlFor="tabela-base">Percentual ideal</label>
                    <InputGroup>
                        <InputCurrency
                            className={styles['currency-input']}
                            value={createComercialTableBody.regras[0].percentualIdeal}
                            precision='1'
                            decimalSeparator=","
                            thousandSeparator='.'
                            onChangeEvent={(_e, _s, value) => handleChangeGeneralRules('percentualIdeal', value)}
                        />
                        <InputGroup.Addon>%</InputGroup.Addon>
                    </InputGroup>
                </div>
                <div className={styles['input-group-tax']}>
                    <label htmlFor="tabela-base">Desconto máximo</label>
                    <InputGroup>
                        <InputCurrency
                            className={styles['currency-input']}
                            value={createComercialTableBody.regras[0].descontoMaximo}
                            precision='1'
                            decimalSeparator=","
                            thousandSeparator='.'
                            onChangeEvent={(_e, _s, value) => handleChangeGeneralRules('descontoMaximo', value)}
                        />
                        <InputGroup.Addon>%</InputGroup.Addon>
                    </InputGroup>
                </div>
            </div>
        </BorderedContentContainer>
    );
};
