import { useQuery } from '@tanstack/react-query';
import { getEditBaseTableListService } from '../services';
import { useEditBaseTableStore } from '../EditBaseTable.store';
import { useState } from 'react';
import { TableRequestType } from '../types';

export const useEditBaseTableList = () => {
    const { setEditBaseTableState } = useEditBaseTableStore();
    const [ editBaseTableList, setEditBaseTableList ] = useState({ page: 0, size: 10 })
    const [ tableData, setTableData ] = useState({})

    const getTableList = async (data: TableRequestType) => {
        const resp = await getEditBaseTableListService(data);
        return resp;
    };

    const {
        isLoading, isError, data, error, isFetching, refetch,
    } = useQuery(
        {
            queryKey: ['edit-base-table-list', editBaseTableList],
            queryFn: () => getTableList(editBaseTableList),
            retry: false,
            onSuccess: (data) => {
                setTableData(data)
            },
        },
    );

    const handleChangeEditBaseTableListPage = (value: number) => {
        setEditBaseTableList({...editBaseTableList, page: value - 1})
    }

    const handleChangeEditBaseTableListLength = (value: number) => {
        setEditBaseTableList({page: 0, size: value})
    }

    return {
        isLoading,
        isError,
        editTableBaseData: data,
        error,
        isFetching,
        tableData,
        setTableData,
        handleGetEditTableList: refetch,
        setEditBaseTableList,
        handleChangeEditBaseTableListPage,
        handleChangeEditBaseTableListLength
    };
};
