import React from 'react';
import WarningIcon from '@material-ui/icons/Warning';
import styles from './Alert.module.scss';

type AlertProps = {
    title?: string,
    message?: string,
    className?: string,
}

export const Alert = ({ title, message, className }: AlertProps) => {
    return (
        <div className={[styles.container, className].join(' ')}>
            <WarningIcon className={styles['warning-icon']} />
            <div className={styles['message-area']}>
                {title ? <p className={styles.title}>{title}</p> : null}
                {message ? <p className={styles.message}>{message}</p> : null}
            </div>
        </div>
    );
};
