import React from 'react';
import { Button } from 'rsuite';
import {
    FiltersArea,
    MainContainer,
} from '../../components';
import { HeaderPriceTableList, TablePriceTableList } from './components';
import styles from './PriceTableList.module.scss';

export const PriceTableList = () => {
    return (
        <MainContainer>
            <HeaderPriceTableList />
            <TablePriceTableList />
        </MainContainer>
    );
};
