import React from 'react';
import { Button } from 'rsuite';
import { ContentContainer, LoadingSpinerLocal } from '../../../../../components';
import SelectPill from '../../../../../components/SelectPill';
import styles from './FiltersArea.module.scss';

export const FiltersArea = () => {
  return (
      <ContentContainer className={styles['filters-area-container']}>
          <div className={styles['inside-container']}>
              <div className={styles['filters-wrapper']}>

                  <SelectPill
                      type="check"
                      id="check-picker-pill"
                      placeholder="Produtos"
                  />
                  <SelectPill
                      type="check"
                      id="check-picker-pill"
                      placeholder="Linha"
                  />
                  <SelectPill
                      type="check"
                      id="check-picker-pill"
                      placeholder="Região"
                  />
                  <SelectPill
                      type="check"
                      id="check-picker-pill"
                      placeholder="UF"
                  />
                  <SelectPill
                      type="check"
                      id="check-picker-pill"
                      placeholder="Responsável"
                  />
              </div>
              <Button className={styles['btn-clear-filters']}>
                  Limpar filtros
              </Button>
          </div>
      </ContentContainer>
  );
};
