import { useQuery } from '@tanstack/react-query';
import * as service from '../services';
import { useEditBaseTableStore } from '../EditBaseTable.store';
import { useState } from 'react';
import { TableRequestType } from '../types';

export const useCustosGeraisTabela = () => {
    const { setEditBaseTableState } = useEditBaseTableStore();
    const [ uf, setUf ] = useState('NACIONAL')

    const getCustosGeraisTabela = async (uf: string) => {
        const resp = await service.getCustosGeraisTabela(uf);
        return resp;
    };

    const dataSuccess = (data) => {
        const { impostos, custosVenda, despesaVenda } = data.data
        const body = {
            uf,
            impostos,
            custos: custosVenda,
            despesaVenda,
        }
        setEditBaseTableState('custosGeraisTabelaBody', body)
    }

    const {
        isLoading, isError, data, error, isFetching, refetch,
    } = useQuery(
        {
            queryKey: ['custo-gerais-tabela', uf],
            queryFn: () => getCustosGeraisTabela(uf),
            retry: false,
            onSuccess: dataSuccess,
        },
    );

    return {
        isLoading,
        isError,
        custosGeraisTabelaData: data,
        error,
        isFetching,
        setUf,
        handleGetCustosGeraisTabelaData: refetch,
    };
};
