import classNames from 'classnames';
import React, { ComponentProps, forwardRef } from 'react';
import styles from './Layout.module.scss';

export type OptionProps = ComponentProps<'div'>;

const Option = forwardRef<HTMLDivElement, OptionProps>(({ className, ...props }, ref) => {
    return <div {...props} ref={ref} className={classNames(styles.option, className)} />;
});

Option.displayName = 'Option';

export type HeaderProps = ComponentProps<'div'>;

const Header = forwardRef<HTMLDivElement, HeaderProps>(({ className, ...props }, ref) => {
    return <div {...props} ref={ref} className={classNames(styles['option__header'], className)} />;
});

Header.displayName = 'Header';

export type TitleProps = ComponentProps<'h5'>;

const Title = forwardRef<HTMLHeadingElement, TitleProps>(({ className, ...props }, ref) => {
    return (
        <h5 {...props} ref={ref} className={classNames(styles.title, styles['option__title'], className)}>
            {props?.children}
        </h5>
    );
});

Title.displayName = 'Title';

export type SubtitleProps = ComponentProps<'h6'>;

const Subtitle = forwardRef<HTMLHeadingElement, SubtitleProps>(({ className, ...props }, ref) => {
    return (
        <h6 {...props} ref={ref} className={classNames(styles.subtitle, styles['option__subtitle'], className)}>
            {props?.children}
        </h6>
    );
});

Subtitle.displayName = 'Subtitle';

export type ContentProps = ComponentProps<'div'>;

const Content = forwardRef<HTMLDivElement, ContentProps>(({ className, ...props }, ref) => {
    return <div {...props} ref={ref} className={classNames(styles['option__content'], className)} />;
});

Content.displayName = 'Content';

export { Content, Header, Option, Subtitle, Title };
