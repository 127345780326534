import React from 'react';
import { Loader } from 'rsuite';
import { format, parseISO } from 'date-fns';
import styles from './UpdatedHeader.module.scss';

type UpdatedHeaderProps = {
    title: string,
    date: any,
}

export const UpdatedHeader = ({ title, date }: UpdatedHeaderProps) => {
    const parsedLastExecutionDate = date ? format(
        parseISO(date),
        'dd/MM/yyy, HH:mm',
    ) : null;

    return (
        <div className={styles['title-wrapper']}>
            <h5 className={styles.title}>{title}</h5>
            <div className={styles.tag}>
                {date ? (
                    <span>
                        atualizado em
                        {' '}
                        {parsedLastExecutionDate}
                    </span>
                ) : (
                    <div className={styles['loading-tag']}>
                        <span>Carregando informação...</span>
                        <Loader className={styles.loader} />
                    </div>
                )}

            </div>
        </div>
    );
};
