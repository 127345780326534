import React from 'react';
import { Button } from 'rsuite';
import styles from './LinkButton.module.scss';

type LinkButtonProps = {
    children: any,
    className?: string,
    fontSize?: string,
    onClick?: () => void,
}

export const LinkButton = ({ children, className, fontSize, onClick }:LinkButtonProps) => {
  return (
      <Button
          className={[styles['btn-link'], className].join(' ')}
          style={{ fontSize }}
          appearance="link"
          onClick={onClick}
      >
          {children}
      </Button>
  );
};
