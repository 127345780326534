import { useQueries, useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataItem } from '../../@types/DataItem';
import { GerenciadorPrecos } from '../../@types/GerenciadorPrecos';
import { useDebounce } from '../../hooks';
import { RESET_SAVED_FILTERS, selectorFiltersIsVisible } from '../../reducers/gerenciadorPrecos';
import {
    RESET_FILTERS_CACHE_BY_KEY,
    RESET_FILTER_CACHE,
    SET_FILTER_CACHE_BY_KEY,
    selectorFiltersCache,
} from '../../reducers/gerenciadorPrecos/filters/filtersCache';
import { SET_FILTER_DATA_BY_KEY, selectorFiltersData } from '../../reducers/gerenciadorPrecos/filters/filtersData';
import {
    RESET_FILTERS_SEARCH,
    RESET_FILTERS_SEARCH_BY_KEY,
    SET_FILTERS_SEARCH_BY_KEY,
    selectorFiltersSearch,
} from '../../reducers/gerenciadorPrecos/filters/filtersSearch';
import {
    RESET_FILTERS_VALUE,
    RESET_FILTERS_VALUE_BY_KEY,
    SET_FILTERS_VALUE_BY_KEY,
    selectorFiltersValues,
} from '../../reducers/gerenciadorPrecos/filters/filtersValues';
import { RESET_QUICK_ACTION_FILTER } from '../../reducers/gerenciadorPrecos/quickActionsFilter';
import { selectorSegmentos } from '../../reducers/gerenciadorPrecos/segmentos';
import { checkIfIsLoading } from '../../utils';
import {
    SegmentData,
    listFamilies,
    listPriceType,
    listProductBrands,
    listSegment,
    listStores,
    listSuppliers,
    listaSegmentacao,
} from '../FilterSection/services';

export type Keys = GerenciadorPrecos.FilterKeys;

const useNewFilterBox = () => {
    const dispatch = useDispatch();
    const debounce = useDebounce(500);
    const data = useSelector(selectorFiltersData);
    const cache = useSelector(selectorFiltersCache);
    const values = useSelector(selectorFiltersValues);
    const search = useSelector(selectorFiltersSearch);
    const cattegories = useSelector(selectorSegmentos);
    const isVisible = useSelector(selectorFiltersIsVisible);

    const [hasPastedValues, setHasPastedValues] = useState(false);

    const onSuccess = useCallback(
        (key: Keys, data: Array<DataItem | SegmentData>) => {
            const newData = _.uniqBy([...(cache[key] ?? []), ...data], 'value');
            dispatch(SET_FILTER_DATA_BY_KEY({ key, data: newData }));

            if (key === 'productIds' && hasPastedValues) {
                const value = newData.map(({ value }) => value) as string[];
                dispatch(
                    SET_FILTER_CACHE_BY_KEY({
                        key,
                        cache: newData as DataItem[],
                    }),
                );
                dispatch(SET_FILTERS_VALUE_BY_KEY({ key, value }));
                setHasPastedValues(false);
            }
        },
        [dispatch, cache, hasPastedValues],
    );

    const { fetchStatus: loadingProductFamilyIds } = useQuery({
        queryKey: ['ipa.filter.productFamilyIds', search.productFamilyIds],
        queryFn: () => listFamilies(search.productFamilyIds),
        onSuccess: (data) => onSuccess('productFamilyIds', data),
    });

    const { fetchStatus: loadingStoreIds } = useQuery({
        queryKey: ['ipa.filter.storeIds', search.storeIds],
        queryFn: () => listStores(search.storeIds),
        onSuccess: (data) => onSuccess('storeIds', data),
    });

    const { fetchStatus: loadingPriceType } = useQuery({
        queryKey: ['ipa.filter.priceType'],
        queryFn: listPriceType,
        onSuccess: (data) => onSuccess('priceType', data),
    });

    const { fetchStatus: loadingSegmentos } = useQuery({
        queryKey: ['ipa.filter.segmentos'],
        queryFn: listaSegmentacao,
        onSuccess: (data) => onSuccess('segmentos', data),
    });

    const { fetchStatus: loadingProductBrand } = useQuery({
        queryKey: ['ipa.filter.productBrand', search.productBrand],
        queryFn: () => listProductBrands(search.productBrand),
        onSuccess: (data) => onSuccess('productBrand', data),
    });

    const { fetchStatus: loadingSupplier } = useQuery({
        queryKey: ['ipa.filter.supplier', search.supplier],
        queryFn: () => listSuppliers(search.supplier),
        onSuccess: (data) => onSuccess('supplier', data),
    });

    const results = useQueries({
        queries: cattegories.map(({ value: key, level }) => ({
            queryKey: ['ipa.filter.segmentos', key, search[key]],
            queryFn: () => listSegment(search[key], level),
            onSuccess: (data: DataItem[]) => onSuccess(key, data),
            enabled: !!cattegories.length,
        })),
    });

    const onSearch = useCallback(
        (key: Keys, query: string) => {
            debounce(() => dispatch(SET_FILTERS_SEARCH_BY_KEY({ key, query })));
        },
        [dispatch, debounce],
    );

    const onChange = useCallback(
        (key: Keys, value: string[]) => {
            dispatch(SET_FILTERS_VALUE_BY_KEY({ key, value }));

            const cache = data[key]?.filter((item) => {
                return value.includes(item.value);
            });

            if (cache) dispatch(SET_FILTER_CACHE_BY_KEY({ key, cache }));
        },
        [dispatch, data],
    );

    const onClean = useCallback(
        (key: Keys) => {
            if (search[key]?.length) {
                dispatch(RESET_FILTERS_SEARCH_BY_KEY({ key }));
            }

            dispatch(RESET_FILTERS_VALUE_BY_KEY({ key }));
            dispatch(RESET_FILTERS_CACHE_BY_KEY({ key }));
        },
        [dispatch, search],
    );

    const onCleanAll = useCallback(() => {
        dispatch(RESET_FILTERS_VALUE());
        dispatch(RESET_FILTERS_SEARCH());
        dispatch(RESET_FILTER_CACHE());
        dispatch(RESET_QUICK_ACTION_FILTER());
        dispatch(RESET_SAVED_FILTERS());
    }, [dispatch]);

    const loadingCattegories = useMemo(() => results.some(({ fetchStatus }) => checkIfIsLoading(fetchStatus)), [results]);

    const loading = useMemo(
        () => ({
            productFamilyIds: checkIfIsLoading(loadingProductFamilyIds),
            storeIds: checkIfIsLoading(loadingStoreIds),
            priceType: checkIfIsLoading(loadingPriceType),
            segmentos: checkIfIsLoading(loadingSegmentos),
            productBrand: checkIfIsLoading(loadingProductBrand),
            supplier: checkIfIsLoading(loadingSupplier),
            loadingCattegories,
        }),
        [loadingProductFamilyIds, loadingStoreIds, loadingPriceType, loadingSegmentos, loadingProductBrand, loadingSupplier, loadingCattegories],
    );

    const onSelectAll = useCallback(
        (key: Keys, checked: boolean) => {
            let newData: string[] = [];

            if (checked) {
                newData = data[key]?.map(({ value }) => value) ?? [];
            }

            dispatch(SET_FILTERS_VALUE_BY_KEY({ key, value: newData ?? [] }));
        },
        [dispatch, data],
    );

    return {
        data,
        values,
        search,
        cattegories,
        loading,
        isVisible,
        onSearch,
        onChange,
        onClean,
        onCleanAll,
        onSelectAll,
    };
};

export default useNewFilterBox;
