import React from 'react';
import { Callout } from '../../../../../../components';
import Layout from '../../Layout';

const { Section } = Layout;

export const SetupIpaProductsSegmentationCallout = () => {
    return (
        <Section.Root>
            <Callout variant="warning" size="md">
                As modificações serão aplicadas na próxima integração de dados.
            </Callout>
        </Section.Root>
    );
};
