import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Button,
    Checkbox,
    Table,
    Tooltip,
    Whisper,
} from 'rsuite';

import EditIcon from '@material-ui/icons/Edit';
import ThreeDotsIcon from '../../../../../../../assets/icons/icon_three_dots_vertical_gray.svg';
// import { selectorCalculadoraPrevisaoDemanda } from '../../../../reducers/calculadoraPrevisaoDemanda';
// import {
//     setFiltersFromEditScenario,
// } from '../../../../reducers/calculadoraPrevisaoDemanda/filtros';
// import {
//     setInputFromEditScenario,
// } from '../../../../reducers/calculadoraPrevisaoDemanda/inputCalculos';
// import {
//     setResultFromEditScenario,
//     setResultScenarioData,
// } from '../../../../reducers/calculadoraPrevisaoDemanda/resultScenario';
import { LoadingSpinerArea } from '../../../../../../../components/LoadingSpinner';
import styles from './TablePriceTableList.module.scss';

const {
 Column, HeaderCell, Cell, Pagination,
} = Table;

const lengthMenu = [
    {
        value: 10,
        label: 10,
    },
    {
        value: 20,
        label: 20,
    },
];

const tableData = [
    {
        tabelaBase: 'Tabela Base Nacional',
        local: 'UF',
        localCode: '23',
        canal: 'Varejo',
        canalType: 'Farmaceutico',
        responsavel: 'Tiago Martins',
        date: '23/04/2024',
    },
    {
        tabelaBase: 'Tabela Base Nacional',
        local: 'UF',
        localCode: '23',
        canal: 'Varejo',
        canalType: 'Farmaceutico',
        responsavel: 'Tiago Martins',
        date: '23/04/2024',
    },
];

export const TablePriceTableList = () => {
    // const { savedScenario } = useSelector(selectorCalculadoraPrevisaoDemanda);
    // const selectedScenario = savedScenario.selectedScenarioItemList;
    // const tableData = savedScenario.savedScenarioData;
    // const indeterminate = selectedScenario.length > 0 && selectedScenario.length < tableData.totalElements;

    // const {
    //     tableHandlers,
    //     tableState,
    // } = useTableActions();

    // const { resultHandlers } = useGetScenarioResults();

    // const dispatch = useDispatch();

    // const history = useHistory();

    return (
        <div className={styles['table-list-table-price-container']}>
            <LoadingSpinerArea area="table-scenario" />
            <Table
                data={tableData}
                autoHeight
                id="table-list-table-price"
                className={styles['table-list-table-price']}
                rowClassName={styles['table-list-table-price-row']}
                rowHeight={64}
                headerHeight={46}
                // sortColumn={tableState.sortColumn}
                // sortType={tableState.sortType}
                // onSortColumn={tableHandlers.handleSortColumns}
            >
                <Column align="center" width={60}>
                    <HeaderCell style={{ padding: '2px' }}>
                        {/* <Checkbox
                            indeterminate={indeterminate}
                            checked={selectedScenario.length === tableData.totalElements}
                            onChange={tableHandlers.handleChangeAllCheckbox}
                        /> */}
                    </HeaderCell>
                    <Cell>
                        {(rowData) => (
                            <Checkbox />
                        )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={2}>
                    <HeaderCell>
                        Tabela de preços
                    </HeaderCell>
                    <Cell dataKey="tabela-precos" height={60}>
                        {(rowData) => (
                            <Whisper
                                delayShow={0.2}
                                trigger="hover"
                                placement="bottom"
                                speaker={<Tooltip>{rowData.tabelaBase}</Tooltip>}
                            >
                                <div className={styles.flex}>
                                    <p className={styles['main-bold-text']}>
                                        {rowData.tabelaBase}
                                    </p>
                                </div>
                            </Whisper>
                        )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell>
                        Local
                    </HeaderCell>
                    <Cell dataKey="local" height={60}>
                        {(rowData) => (
                            <div>
                                <p className={styles['main-bold-text']}>{rowData.localCode}</p>
                                <p className={styles['sub-text']}>{rowData.local}</p>
                            </div>
                            )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell>Canal</HeaderCell>
                    <Cell dataKey="canal">
                        {(rowData) => (
                            <div>
                                <p className={styles['main-bold-text']}>{rowData.canal}</p>
                                <p className={styles['sub-text']}>{rowData.canalType}</p>
                            </div>
                            )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell>
                        Responsável
                    </HeaderCell>
                    <Cell
                        dataKey="responsavel"
                    >
                        {(rowData) => (
                            <div>
                                <p className={styles['regular-text']}>{rowData.responsavel}</p>
                                <p className={styles['sub-text']}>{rowData.date}</p>
                            </div>
                            )}
                    </Cell>
                </Column>
                <Column flexGrow={1}>
                    <HeaderCell />
                    <Cell>
                        {(rowData) => (
                                <Button className={styles['btn-row-action']}>
                                    <img src={ThreeDotsIcon} alt="" className={styles['more-icon']} />
                                </Button>
                            )}
                    </Cell>
                </Column>

            </Table>
            <Pagination
                // activePage={tableState.page + 1 || null}
                // displayLength={tableData.size}
                // total={tableData.totalElements || null}
                // onChangePage={tableHandlers.handleChangePage}
                // onChangeLength={tableHandlers.handleChangeSize}
                lengthMenu={lengthMenu}
            />
        </div>

    );
};
