import { useMutation, useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';
import { Alert } from 'rsuite';
import { checkIfIsLoading } from '../../../../../../utils';
import { GET_loadProductsSegmentation, initialData, PATCH_updateProductsSegmentation, ProductsSegmentation } from '../lib';

export const useProductsSegmentationQuery = () => {
    const query = useQuery({
        queryKey: ['ipa/setup-form/segmentacao-de-produtos'],
        initialData,
        queryFn: GET_loadProductsSegmentation,
    });

    const { mutateAsync } = useMutation({
        mutationFn: PATCH_updateProductsSegmentation,
        onSuccess: () => {
            query.refetch();
        },
    });

    const handleUpdateProductsSegmentation = useCallback(
        async (formData: ProductsSegmentation) => {
            try {
                await mutateAsync(formData);
            } catch (err) {
                throw Alert.error(err.message);
            }
        },
        [mutateAsync],
    );

    return {
        ...query,
        isLoading: checkIfIsLoading(query.fetchStatus),
        handlers: {
            update: handleUpdateProductsSegmentation,
        },
    };
};
