import { create } from 'zustand';
import { CreateComercialTableType } from './types';

type State = {
    createComercialTableBody: CreateComercialTableType;
}

type Actions = {
    setCreateComercialTableState: (name: string, data: any) => void;
    setCreateComercialTableCleanFilters: () => void;
    reset: () => void;
}

const initialState: State = {
    createComercialTableBody: {
        nome: "",
        local: "",
        ufs: [],
        canal: "",
        subCanal: "",
        regras: [
            {
                tipo: "PERCENTUAL_TABELA_BASE",
                percentualIdeal: 0,
                descontoMaximo: 0
            }
        ],
        limites: [
            {
                tipo: "PERCENTUAL_TABELA_BASE",
                percentualMinimo: 0,
                percentualMaximo: 0
            }
        ],
        rascunho: [
            {
                idProduto: 0,
                percentual: 0
            }
        ],
        idTabelaBase: 6
    },
};

export const useCreateComercialTableStore = create<State & Actions>((set) => ({
    ...initialState,
    setCreateComercialTableState: (name, data) => {
        set(() => ({
            [name]: data,
        }));
    },
    setCreateComercialTableCleanFilters: () => {
        set((state) => ({
            createComercialTableBody: {
                ...state.createComercialTableBody,
                local: "",
                ufs: [],
                canal: "",
                subCanal: "",
            }
        }));
    },
    reset: () => {
        set(initialState);
    },
}));
