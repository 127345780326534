import classNames from 'classnames';
import React, { ComponentProps, forwardRef } from 'react';
import styles from './Layout.module.scss';

export type SubtitleProps = ComponentProps<'h3'>;

const Subtitle = forwardRef<HTMLHeadingElement, SubtitleProps>(({ className, children, ...props }, ref) => {
    return (
        <h3 {...props} ref={ref} className={classNames(styles.subtitle, className)}>
            {children}
        </h3>
    );
});

Subtitle.displayName = 'Subtitle';

export { Subtitle };
