import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import * as service from '../services';

export const useSubCanaisFilter = () => {

    const getSubCanaisData = async () => {
        const res = await service.getSubCanaisList();

        if (res.status === 200) {
            const list = res.data.map((item) => ({ label: item, value: item }));
            return list;
        }

        return [];
    };

    const {
        data,
        isLoading,
        isError,
        isSuccess,
        refetch,
    } = useQuery({
        queryKey: ['sub-canais'],
        queryFn: getSubCanaisData,
        retry: false,
    });

    return {
        subCanaisData: data,
        isError,
        isSuccess,
        refetchSubCanais: refetch,
    };
};
