import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import * as service from '../services';
import { useEditBaseTableStore } from '../EditBaseTable.store';
import { CustoFabricacaoBodyType, EditBaseTableItemDataType } from '../types';

export const useSaveCustoFabricacao = () => {
    const { custoFabricacaoBody } = useEditBaseTableStore();

    const [custoFabricacaoData, setCustoFabricacaoData] = useState({});

    const custoFabricacao = async (data: CustoFabricacaoBodyType) => {
        if (data.idRascunhoProduto) {
            const res = await service.saveCustoFabricacao(data);
            return res;
        }
    };

    const {
        data,
        isLoading,
        isError,
        isSuccess,
        refetch,
    } = useQuery({
        queryKey: ['save-custo-fabricacao', custoFabricacaoData],
        queryFn: () => custoFabricacao(custoFabricacaoData),
        retry: false,
    });

    const handleSaveCustoFabricacao = (event: React.FocusEvent<HTMLInputElement>, productData: EditBaseTableItemDataType) => {
        const custoFabricacao = parseFloat(event.target.value.replace(/\./g, '').replace(',', '.'));
        const updateCustoFabricacao = {
            ...custoFabricacaoBody,
            custoFabricacao,
            idRascunhoProduto: productData.id,
            precoMedio: productData.parametros.precoMedio.valorAtual,
            novoPrecoTabela: productData.parametros.novoPrecoTabela.valorNovo
        }
        setCustoFabricacaoData(updateCustoFabricacao)
    }

    return {
        isLoading,
        isError,
        editTableBaseData: data,
        handleSaveCustoFabricacao
    };
};
