import React, { useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { Button } from 'rsuite';
import { UpdatedHeader } from '../../../../../components/UpdatedHeader';
import {
    BigNumbersArea,
    FiltersArea,
    TableActionArea,
    MainContainer,
} from '../../components';
import { LinkButton } from '../../../../../components';
import {
    ManageComercialTableTable,
    ModalActivateProductPromo,
    ModalDescriptionPriceAndDiscount,
    ModalPriceLimit,
} from './components';
import { SelectUpdatedHeader } from '../../components/SelectUpdatedHeader';
import { ModalCustosProduto, ModalPriceAnalysis } from '../EditBaseTable/components';
import { useManageComercialTableStore } from './ManageComercialTable.store';
import styles from './ManageComercialTable.module.scss';
import { useComercialTableList } from './hooks/useComercialTableList';

const selectData = [
    {
        label: 'Tabela Rede A',
        value: 'tabelaRede_A',
    },
    {
        label: 'Tabela Rede B',
        value: 'tabelaRede_B',
    },
    {
        label: 'Tabela Rede C',
        value: 'tabelaRede_C',
    },
]

export const ManageComercialTable = () => {
    const [headerData, setHeaderData] = useState(selectData[0].value)
    const history = useHistory();

    const {
        priceAnalysisComercialTableData,
        openModalPriceAnalysisComercial,
        openModalActivateProductOffer ,
        setManageComercialTableState,
    } = useManageComercialTableStore()

    const { comercialTableMutateData } = useComercialTableList();

    return (
        <MainContainer>
            <div className={styles['header-wrapper']}>
                <div>
                    {/* <UpdatedHeader title="Tabela varejo estratégico" date="" /> */}
                    <SelectUpdatedHeader
                        data={selectData}
                        value={headerData}
                    />
                    <div className={styles['subtitle-wrapper']}>
                        <p className={styles.subtitle}>As alterações são salvas automaticamente</p>
                        <LinkButton
                            fontSize="12px"
                            onClick={() => history.push('/ipa/criar-tabela-comercial')}
                        >
                            Editar regras da tabela
                        </LinkButton>
                    </div>
                </div>
                <Button
                    className={styles['btn-consult']}
                    onClick={() => history.push('/ipa/tabela-comercial-atual')}
                >
                    Consultar a tabela atual
                </Button>
            </div>
            {/* <BigNumbersArea />
            <FiltersArea /> */}
            <TableActionArea>
                <Button
                    className={styles['btn-apply']}
                    appearance="primary"
                    // disabled={!productItemSelectedList.length}
                    // onClick={handleConfirmPriceApply}
                >
                    Aplicar preço
                </Button>
            </TableActionArea>
            <ManageComercialTableTable />
            <ModalActivateProductPromo
                show={openModalActivateProductOffer}
                onCancel={() => setManageComercialTableState('openModalActivateProductOffer', false)}
            />
            <ModalDescriptionPriceAndDiscount />
            <ModalPriceLimit />
            <ModalPriceAnalysis
                show={openModalPriceAnalysisComercial}
                tableHeight={273}
                title='Análise preço médio vs preço de tabela'
                data={priceAnalysisComercialTableData}
                // onCancel={() => setEditBaseTableState('openModalPriceAnalysis', false)}
            />
            <ModalCustosProduto
                // show={}
                hasCustoFabricacao
                title="Custos do produto"
                subtitle="Defina os custos específicos dos produtos selecionados"
                confirmButton="Salvar"
                cancelButton="Fechar"
                // data={productItemData}
                // onCancel={() => setOpenModalEditOtherCosts(false, null)}
                // onConfirm={handleOpenModalEditConfirmation}
                // onChangeCustosProduto={handleChangeCustosProduto}
            />
        </MainContainer>
    );
};
