import React from 'react';
import ModalConfirm from '../../../../../../../components/ModalConfirm';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';

import styles from './ModalPriceLimit.module.scss';

type ModalActivateProductPromoProps = {
    show?: boolean
}

export const ModalPriceLimit = ({ show, ...props }: ModalActivateProductPromoProps) => {
    return (
        <ModalConfirm
            show={show}
            modalWidth="500px"
            titleFontSize="16px"
            footerAlign="end"
            title="Limite de preço"
            cancelButton="Fechar"
            className={styles['modal-price-limit']}
        >
            <div className={styles.content}>
                <div className={[styles['percent-over'], styles['alert-status']].join(' ')}>
                    <div className={styles['percent-status-container']}>
                        <p className={styles.label}>
                            Percentual sobre tabela base
                        </p>
                        <div className={styles['percent-min-max']}>
                            <span className={styles.text}>Mín: 105,00%</span>
                            <span className={styles.divider}>|</span>
                            <span className={styles.text}>Max: 102,00%</span>
                        </div>
                    </div>
                    <ReportProblemIcon className={styles['alert-icon']} />
                </div>
                <div className={styles['price-container']}>
                    <p className={styles.text}>O preço deve ficar entre</p>
                    <div>
                        <span className={styles['price-value']}>R$ 15,63</span>
                        <span className={styles.text}>{' e '}</span>
                        <span className={styles['price-value']}>R$ 15,63</span>
                    </div>
                </div>
            </div>
        </ModalConfirm>
    );
};
