import React from 'react';
import { Loader, SelectPicker } from 'rsuite';
import { format, parseISO } from 'date-fns';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import styles from './SelectUpdatedHeader.module.scss';

type SelectUpdatedHeaderProps = {
    title?: string,
    date?: any,
    data?: any,
    placeholder?: any,
    value?: any,
    onChange?: (value: string) => void,
}

export const SelectUpdatedHeader = ({
    title,
    date,
    data,
    placeholder,
    value,
    onChange
}: SelectUpdatedHeaderProps) => {
    const parsedLastExecutionDate = date ? format(
        parseISO(date),
        'dd/MM/yyy, HH:mm',
    ) : null;

    return (
        <div className={styles['title-wrapper']}>
            <SelectPicker
                className={styles.select}
                searchable={false}
                cleanable={false}
                data={data}
                placeholder={placeholder}
                onChange={onChange}
                value={value}
            />
            <div className={styles.tag}>
                {date ? (
                    <span>
                        atualizado em
                        {' '}
                        {parsedLastExecutionDate}
                    </span>
                ) : (
                    <div className={styles['loading-tag']}>
                        <span>Carregando informação...</span>
                        <Loader className={styles.loader} />
                    </div>
                )}

            </div>
            <div className={styles['pending-alert']}>
                <ReportProblemIcon className={styles['alert-icon']} />
                <span className={styles['alert-text']}>Tabela com alterações pendente de revisão</span>
            </div>
        </div>
    );
};
