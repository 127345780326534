import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { Callout, InputBox, Label } from '../../../../../../components';
import { Segment } from '../../../../../../reducers/gerenciadorPrecos/segmentos';
import Layout from '../../Layout';
import { ProductsSegmentation } from '../lib/schema';
import styles from '../SetupIpaProductsSegmentation.module.scss';

const { Section, Title, Subtitle, Option } = Layout;

const { RadioGroup, Radio, Error, Select, FormDescription } = InputBox;

type OptionComponentProps = {
    form: UseFormReturn<ProductsSegmentation>;
    segments: Segment[];
};

export const SetupIpaProductsSegmentationInfopriceSensibility = ({ form, segments }: OptionComponentProps) => {
    return (
        <Section.Root>
            <Section.Header>
                <Title>Sensibilidade InfoPrice</Title>
                <Subtitle>
                    Use o calculo de Sensibilidade InfoPrice para classificar os produtos da sua base levando em consideração faturamento, penetração em tickets
                    e frequência de compras.
                </Subtitle>
            </Section.Header>
            <Section.Content>
                <Option.Root>
                    <Option.Header>
                        <Option.Title>lojas</Option.Title>
                        <Option.Subtitle>Escolha calcular a Sensibilidade para cada loja, ou agrupando por cluster de lojas ou todas lojas.</Option.Subtitle>
                    </Option.Header>
                    <Option.Content className={styles['option__content']}>
                        <Controller
                            control={form.control}
                            name="store.location"
                            render={({ field: { value, onChange }, fieldState: { error } }) => {
                                return (
                                    <InputBox>
                                        <RadioGroup value={value} onChange={onChange}>
                                            <Radio value="SINGLE_STORE">Calcular para loja</Radio>
                                            <Radio value="ALL_STORES">Calcular para todas as lojas da rede</Radio>
                                        </RadioGroup>
                                        <Error message={error?.message} />
                                    </InputBox>
                                );
                            }}
                        />
                    </Option.Content>
                </Option.Root>
                <Option.Root>
                    <Option.Header>
                        <Option.Title>escopo do cálculo</Option.Title>
                        <Option.Subtitle>Escolha entre calcular a Sensibilidade considerando todo o sortimento ou por categoria de produto.</Option.Subtitle>
                    </Option.Header>
                    <Option.Content className={styles['option__content']}>
                        <Controller
                            control={form.control}
                            name="store.category.calcType"
                            render={({ field: { value, onChange }, fieldState: { error } }) => {
                                return (
                                    <InputBox>
                                        <RadioGroup value={value} onChange={onChange}>
                                            <Radio className={styles['radio-description']} value="ALL_PRODUCTS_SORTMENT">
                                                <span>Calcular entre todo o sortimento de produtos</span>
                                                <FormDescription>A sensibilidade será calculada entre todos os produtos do sortimento juntos.</FormDescription>
                                            </Radio>
                                            <Radio className={styles['radio-description']} value="ALL_PRODUCTS_CATEGORY">
                                                <span>Calcular entre os produtos de cada categoria</span>
                                                <FormDescription>
                                                    A sensibilidade será calculada entre os produtos de cada categoria. Você pode escolher qual nível de
                                                    categoria calcular.
                                                </FormDescription>
                                            </Radio>
                                        </RadioGroup>
                                        <Error message={error?.message} />
                                    </InputBox>
                                );
                            }}
                        />
                        <Controller
                            control={form.control}
                            name="store.category.categoryLevel"
                            render={({ field: { value, ...field }, fieldState: { error } }) => {
                                const isDisabled = form.watch('store.category.calcType') === 'ALL_PRODUCTS_SORTMENT';

                                return (
                                    <div>
                                        <InputBox className={styles['select-container']}>
                                            <Label>Nível de categoria para o cálculo de Sensibilidade</Label>
                                            <Select
                                                {...field}
                                                data={segments}
                                                value={isDisabled ? null : value}
                                                searchable={false}
                                                cleanable={false}
                                                disabled={isDisabled}
                                            />
                                            <Error message={error?.message} />
                                        </InputBox>
                                        <Callout className={styles.callout} size="sm" variant="info">
                                            A sensibilidade InfoPrice é calculada agrupando os dados dos produtos que fazem parte de uma mesma família.
                                        </Callout>
                                    </div>
                                );
                            }}
                        />
                    </Option.Content>
                </Option.Root>
            </Section.Content>
        </Section.Root>
    );
};
