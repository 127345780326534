import React from 'react';
import { Input, SelectPicker } from 'rsuite';
import { BorderedContentContainer } from '../../../../components';

import styles from './NewPriceTableForm.module.scss';
import { useTabelaBase } from '../../hooks/useTabelaBase';
import { useCreateComercialTableStore } from '../../CreateComercialTable.store';

export const NewPriceTableForm = () => {
    const { tabelaBaseData } = useTabelaBase();
    const { createComercialTableBody, setCreateComercialTableState } = useCreateComercialTableStore();

    const handleChangeName = (value: string) => {
        const name = {...createComercialTableBody, nome: value}
        setCreateComercialTableState('createComercialTableBody', name)
    }

    return (
        <BorderedContentContainer
            title="Nova tabela de preços"
            borderedContentClassName={styles['bordered-container']}
        >
            <div className={styles.row}>
                <div className={styles['input-group']}>
                    <label htmlFor="nome">Nome da tabela</label>
                    <Input
                        className={styles.input}
                        placeholder="Tabela Varejo Estratégico"
                        onChange={handleChangeName}
                    />
                </div>
                <div className={styles['select-group']}>
                    <label htmlFor="tabela-base">Tabela base</label>
                    <SelectPicker
                        placeholder="Tabela Base Nacional"
                        data={tabelaBaseData}
                        defaultValue={6}
                    />
                </div>
            </div>
        </BorderedContentContainer>
    );
};
