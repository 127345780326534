import { trackPromise } from 'react-promise-tracker';
import api from '../../../../../utils/API';

const URL = process.env.REACT_APP_IPA_PRICE_TABLES_MANAGER_URL

// ########## - TABELA COMERCIAL - ##########

export const getComercialTableList = (data) => trackPromise(
    api({
        url: `${URL}/tabela-comercial`,
        method: 'GET',
        expectToken: true,
        params: {
            page: data.page,
            size: data.size,
        },
    }).then((res) => res),
    'table-edit-base'
);

export const putComercialTableList = (data) => trackPromise(
    api({
        url: `${URL}/tabela-comercial`,
        method: 'PUT',
        expectToken: true,
        data,
    }).then((res) => res),
);

export const postComercialTableList = (data) => trackPromise(
    api({
        url: `${URL}/tabela-comercial`,
        method: 'POST',
        expectToken: true,
        data
    }).then((res) => res),
);

export const getComercialTableItem = (id: string) => trackPromise(
    api({
        url: `${URL}/tabela-comercial/${id}`,
        method: 'GET',
        expectToken: true,
    }).then((res) => res),
);
