import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { Icon, Nav, Sidebar, Sidenav } from 'rsuite';

import {
    IPALiteMenu,
    InfoPanelMenu,
    IpaMenu,
    IraMenu,
    IsaMenu,
    PaineisCustomizadosMenu,
    CalculadoraPrevisaoDemandaMenu,
    PlataformaPrecificacaoMenu,
} from './IndividualMenus';

const headerStyles = {
    padding: 18,
    fontSize: 16,
    height: 56,
    background: 'rgb(30,136,229)',
    color: ' #fff',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
};

const Menu = ({ usuario }) => {
    const history = useHistory();
    const [expand, setExpand] = useState(false);
    const [paineis, setPaineis] = useState([]);

    const { sideMenu, activeKeyMenu, clienteData } = useSelector(
        (state) => ({
            sideMenu: state.menuDataReducer.sideMenu,
            activeKeyMenu: state.menuDataReducer.activeKeyMenu,
            codigoCliente: state.clienteDataReducer.codigoCliente,
            servicoBi: state.usuarioDataReducer.servicoBi,
            clienteData: state.clienteDataReducer.clienteData,
        }),
        shallowEqual,
    );

    const sendToLogin = () => {
        localStorage.removeItem('autenticacao.response');
        localStorage.removeItem('autenticacao.token');
        localStorage.removeItem('autenticacao.usuarioLogin');
        localStorage.removeItem('autenticacao.clientId');
        localStorage.removeItem('autenticacao.clientName');
        localStorage.removeItem('cliente');
        localStorage.removeItem('usuario');
        history.push('/login');
        window.location.reload(true);
    };

    const handleToggle = () => {
        setExpand(!expand);
    };

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('usuario'));
        if (!user) {
            sendToLogin();
        }
    }, []);

    useEffect(() => {
        setPaineis(
            paineis
                .concat(clienteData?.servicoBi?.analises)
                .concat(clienteData?.servicoBi?.graficos),
        );
    }, [clienteData]);

    return sideMenu === '1' ||
        sideMenu === '3' ||
        sideMenu === '4' ||
        sideMenu === '6' ||
        sideMenu === '7' ||
        sideMenu === '8' ||
        sideMenu === '9' ||
        sideMenu === '10' ? (
        <Sidebar
            style={{ display: 'flex', flexDirection: 'column' }}
            width={expand ? 260 : 56}
            collapsible
            className="sidebar-desktop"
        >
            <Sidenav.Header>
                <div style={headerStyles}>
                    <Icon icon="tag" size="lg" style={{ verticalAlign: 0 }} />
                    <span style={{ marginLeft: 12 }}> INFOPRICE</span>
                </div>
            </Sidenav.Header>
            <Sidenav
                expanded={expand}
                appearance="subtle"
                activeKey={activeKeyMenu}
            >
                <Sidenav.Body>
                    {sideMenu === '1' ? <IsaMenu user={usuario} /> : null}
                    {sideMenu === '3' ? (
                        <PaineisCustomizadosMenu paineis={paineis} />
                    ) : null}
                    {sideMenu === '4' ? <InfoPanelMenu user={usuario} /> : null}
                    {sideMenu === '6' ? (
                        <IpaMenu user={usuario} activeKeyMenu={activeKeyMenu} />
                    ) : null}
                    {sideMenu === '8' ? (
                        <IPALiteMenu
                            user={usuario}
                            activeKeyMenu={activeKeyMenu}
                        />
                    ) : null}
                    {sideMenu === '7' ? <IraMenu /> : null}
                    {sideMenu === '9' ? <CalculadoraPrevisaoDemandaMenu /> : null}
                    {sideMenu === '10' ? <PlataformaPrecificacaoMenu /> : null}
                </Sidenav.Body>
            </Sidenav>
            <Nav pullRight>
                <Nav.Item
                    onClick={handleToggle}
                    style={{ width: 56, textAlign: 'center' }}
                >
                    <Icon icon={expand ? 'angle-left' : 'angle-right'} />
                </Nav.Item>
            </Nav>
        </Sidebar>
    ) : null;
};

export default withRouter(Menu);
