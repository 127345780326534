import React from 'react';
import { InputGroup, Input } from 'rsuite';
import ModalConfirm from '../../../../../../../components/ModalConfirm';
import { SimulationResultBigNumbers } from '../../../../components';
import styles from './ModalSimulationResult.module.scss';

type ModalCustosGeraisTabelaBaseProps = {
    show?: boolean
}

export const ModalSimulationResult = ({ show, ...props }: ModalCustosGeraisTabelaBaseProps) => {
    return (
        <ModalConfirm
            show={show}
            modalWidth="946px"
            titleFontSize="20px"
            footerAlign="end"
            title="Simulação resultados"
            confirmButton="Usar preço simulado"
            cancelButton="Fechar"
            className={styles['modal-simulation-result']}
        >
            <div className={styles['product-container']}>
                <div>
                    <p className={styles['product-label']}>PRODUTO</p>
                    <p className={styles['product-name']}>253 - PROPOMAX Kids 140 ml</p>
                </div>
                <form className={styles.form}>
                    <div className={styles['input-wrapper']}>
                        <label className={styles['input-label']}>Novo preço de tabela</label>
                        <InputGroup>
                            <InputGroup.Addon>R$</InputGroup.Addon>
                            <Input {...props} />
                        </InputGroup>
                    </div>
                    <div className={styles['input-wrapper']}>
                        <label className={styles['input-label']}>Volume de vendas</label>
                        <InputGroup>
                            <Input {...props} />
                            <InputGroup.Addon>un</InputGroup.Addon>
                        </InputGroup>
                    </div>
                </form>
            </div>
            <SimulationResultBigNumbers />
        </ModalConfirm>
    );
};
