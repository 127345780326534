import React, { forwardRef } from 'react';
import { MdPushPin } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip, Whisper } from 'rsuite';
import { Chip, ChipProps } from '../../../../../components';
import { selectorIsLayoutFixed, TOGGLE_GERENCIADOR_IS_LAYOUT_FIXED } from '../../../../../reducers/gerenciadorPrecos/isLayoutFixed';

const FixedLayoutToggleButton = forwardRef<HTMLButtonElement, Omit<ChipProps, 'children' | 'filled' | 'leftIcon'>>((props, ref) => {
    const dispatch = useDispatch();
    const isLayoutFixed = useSelector(selectorIsLayoutFixed);
    return (
        <Whisper speaker={<Tooltip>Alternar a exibição do layout fixo</Tooltip>} placement="bottom">
            <Chip
                ref={ref}
                theme="compact"
                filled={isLayoutFixed}
                onClick={() => dispatch(TOGGLE_GERENCIADOR_IS_LAYOUT_FIXED())}
                leftIcon={<MdPushPin size={14} color="currentColor" />}
                {...props}
            >
                Fixar
            </Chip>
        </Whisper>
    );
});

FixedLayoutToggleButton.displayName = 'FixedLayoutToggleButton';

export { FixedLayoutToggleButton };
