import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import * as service from '../services';
import { CustosProdutoBodyType } from '../types';
import { useEditBaseTableStore } from '../EditBaseTable.store';
import { useEditBaseTableList } from './useEditBaseTableList';
import { NotificationMessage } from '../../../../../../components';

export const useSaveCustosProduto = () => {
    const [custosProdutoData, setCustosProdutoData] = useState({});
    const {
        custosProdutoBody,
        setEditBaseTableState,
    } = useEditBaseTableStore();

    const { handleGetEditTableList } = useEditBaseTableList()

    const custosProduto = async () => {
        const res = await service.saveCustosProduto(custosProdutoBody);
        return res;
    };

    const custosProdutoSuccess = () => {
        setEditBaseTableState('openModalEditConfirmation', false)
        NotificationMessage(
            'success',
            5000,
            'Custos do produto salvo com sucesso',
        );
        handleGetEditTableList()
    }

    const {
        isLoading,
        isError,
        isSuccess,
        refetch,
    } = useQuery({
        queryKey: ['save-custos-produto'],
        queryFn: custosProduto,
        retry: false,
        enabled: false,
        onSuccess: custosProdutoSuccess,
    });

    return {
        isLoading,
        isError,
        handleSaveCustoProduto: refetch,
        setCustosProdutoData
    };
};
