import React from 'react';
import ModalConfirm from '../../../../../../../components/ModalConfirm';
import { Alert, CustosVendaBox } from '../../../../components';
import { OutrasDespesasBox } from '../OutrasDespesasBox';
import styles from './ModalCustosGeraisTabelaBase.module.scss';
import { EditBaseTableItemDataType } from '../../types';
import { useCustosGeraisTabela } from '../../hooks/useCustosGeraisTabela';
import { useSaveCustosGeraisTabela } from '../../hooks/useSaveCustosGeraisTabela';
import { useEditBaseTableStore } from '../../EditBaseTable.store';
import { LoadingSpiner } from '../../../../../../../components';

type ModalCustosGeraisTabelaBaseProps = {
    show?: boolean,
    isAlertMessage?: boolean,
    title?: string,
    subtitle?: string,
}

export const ModalCustosGeraisTabelaBase = ({
    show,
    title,
    subtitle,
}: ModalCustosGeraisTabelaBaseProps) => {
    const {
        custosGeraisTabelaBody,
        setEditBaseTableState,
        setOpenModalEditGeneralCosts
    } = useEditBaseTableStore();
    const { handleSaveCustosGeraisTabela } = useSaveCustosGeraisTabela();

    const handleChangeCustosGeraisTabela = (objType: string, name: string, value: number) => {
        const objData = {
            ...custosGeraisTabelaBody,
            [objType]: {
                ...custosGeraisTabelaBody[objType],
                [name]: value
            }
        }
        setEditBaseTableState('custosGeraisTabelaBody', objData)
    }

    return (
        <ModalConfirm
            show={show}
            className={styles['modal-custos-gerais-tabela-base']}
            modalWidth="735px"
            titleFontSize="16px"
            footerAlign="end"
            title={title}
            upMessage={subtitle}
            confirmButton="Salvar"
            cancelButton="Fechar"
            onCancel={() => setOpenModalEditGeneralCosts(false, null)}
            onConfirm={handleSaveCustosGeraisTabela}
            onHideHeaderButton={() => setOpenModalEditGeneralCosts(false, null)}
        >
            <LoadingSpiner size="md" />
            <div className={styles.container}>
                <CustosVendaBox
                    custosVendaData={custosGeraisTabelaBody?.custos}
                    onChange={(name, value) => handleChangeCustosGeraisTabela('custos', name, value)}
                />
                <OutrasDespesasBox
                    outrasDespesasData={custosGeraisTabelaBody?.despesaVenda}
                    onChange={(name, value) => handleChangeCustosGeraisTabela('despesaVenda', name, value)}
                />
            </div>
            <Alert
                title="Atenção!"
                message="Esses custos serão aplicados a todos os produtos da tabela base"
                className={styles.alert}
            />
        </ModalConfirm>
    );
};
