import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../@types/RootState';

export type FiltersIsVisibleState = boolean;

const initialState: FiltersIsVisibleState = true;

const slice = createSlice({
    name: 'isVisible',
    initialState,
    reducers: {
        RESET_GERENCIADOR_FILTERS_IS_VISIBLE: () => initialState,
        TOGGLE_GERENCIADOR_FILTERS_IS_VISIBLE: (state) => !state,
        TOGGLE_ON_GERENCIADOR_FILTERS_IS_VISIBLE: () => true,
        TOGGLE_OFF_GERENCIADOR_FILTERS_IS_VISIBLE: () => false,
    },
});

export const selectorFiltersIsVisible = (state: RootState) => {
    return state.gerenciadorPrecosReducer.filters.isVisible;
};

export const {
    RESET_GERENCIADOR_FILTERS_IS_VISIBLE,
    TOGGLE_GERENCIADOR_FILTERS_IS_VISIBLE,
    TOGGLE_ON_GERENCIADOR_FILTERS_IS_VISIBLE,
    TOGGLE_OFF_GERENCIADOR_FILTERS_IS_VISIBLE,
} = slice.actions;

export default slice.reducer;
