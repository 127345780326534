import qs from 'qs';
import { trackPromise } from 'react-promise-tracker';
import api from '../../../../../utils/API';

const URL = process.env.REACT_APP_IPA_PRICE_TABLES_MANAGER_URL


// ########## - EDITAR TABELA BASE - ##########

export const getEditBaseTableListService = (data) => trackPromise(
    api({
        url: `${URL}/rascunho-produto-base/by-uf/NACIONAL`,
        method: 'GET',
        expectToken: true,
        params: {
            page: data.page,
            size: data.size,
        },
    }).then((res) => res),
    'table-edit-base'
);

export const getHeaderUpdateTimeViewService = () => trackPromise(
    api({
        url: `${URL}/tabela-base/NACIONAL`,
        method: 'GET',
        expectToken: true,
    }).then((res) => res),
);

export const saveCustoFabricacao = (data) => trackPromise(
    api({
        url: `${URL}/rascunho-produto-base/preco`,
        method: 'PUT',
        expectToken: true,
        data,
    }).then((res) => res),
);

export const priceApply = (params) => trackPromise(
    api({
        url: `${URL}/rascunho-produto-base/aplicar-precos`,
        method: 'PUT',
        expectToken: true,
        params: {
            idsRascunhoProduto: params,
        },
        paramsSerializer(params) {
            return qs.stringify(params, { arrayFormat: 'repeat' });
        },
    }).then((res) => res),
);

export const saveCustosProduto = (data) => trackPromise(
    api({
        url: `${URL}/rascunho-produto-base/custo`,
        method: 'PUT',
        expectToken: true,
        data,
    }).then((res) => res),
);

export const saveCustosGeraisTabela = (data) => trackPromise(
    api({
        url: `${URL}/custos-gerais-tabela`,
        method: 'POST',
        expectToken: true,
        data,
    }).then((res) => res),
);

export const getCustosGeraisTabela = (uf: string) => trackPromise(
    api({
        url: `${URL}/custos-gerais-tabela/${uf}`,
        method: 'GET',
        expectToken: true,
    }).then((res) => res),
);
