import React from 'react';
import { InputGroup, Input, DatePicker } from 'rsuite';
import ModalConfirm from '../../../../../../../components/ModalConfirm';
import CalendarIcon from '../../../../../../../assets/icons/icon_calendar.svg';
import styles from './ModalActivateProductPromo.module.scss';

type ModalActivateProductPromoProps = {
    show?: boolean,
    onCancel?: () => void
}

export const ModalActivateProductPromo = ({ show, onCancel }: ModalActivateProductPromoProps) => {
    return (
        <ModalConfirm
            show={show}
            modalWidth="500px"
            titleFontSize="16px"
            footerAlign="end"
            title="Ativar oferta do produto"
            message="Escolha o preço e o prazo vigente para a oferta"
            confirmButton="Ativar oferta"
            cancelButton="Fechar"
            className={styles['modal-activate-product-promo']}
            onCancel={onCancel}
            onHideHeaderButton={onCancel}
        >
            <form className={styles.form}>
                <div className={styles['input-wrapper']}>
                    <label className={styles['input-label']}>Preço oferta</label>
                    <InputGroup>
                        <InputGroup.Addon>R$</InputGroup.Addon>
                        <Input />
                    </InputGroup>
                </div>
                <div className={styles['input-wrapper-date']}>
                    <label className={styles['input-label']}>Início de vigência</label>
                    <InputGroup>
                        <InputGroup.Addon>
                            <img src={CalendarIcon} alt="" className={styles['calendar-icon']} />
                        </InputGroup.Addon>
                        <DatePicker format="DD/MM/YYYY" />
                    </InputGroup>
                </div>
                <div className={styles['input-wrapper-date']}>
                    <label className={styles['input-label']}>Final de vigência</label>
                    <InputGroup>
                        <InputGroup.Addon>
                            <img src={CalendarIcon} alt="" className={styles['calendar-icon']} />
                        </InputGroup.Addon>
                        <DatePicker format="DD/MM/YYYY" />
                    </InputGroup>
                </div>
            </form>
        </ModalConfirm>
    );
};
