import classNames from 'classnames';
import React, { ComponentProps, forwardRef } from 'react';
import styles from './Layout.module.scss';

export type FooterProps = ComponentProps<'div'>;

const Footer = forwardRef<HTMLDivElement, FooterProps>(({ className, ...props }, ref) => {
    return <div {...props} ref={ref} className={classNames(styles.section, styles.footer, className)} />;
});

Footer.displayName = 'Footer';

export { Footer };
