import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../@types/RootState';

type State = boolean;

const initialState: State = false;

const slice = createSlice({
    name: 'isVisible',
    initialState,
    reducers: {
        RESET_GERENCIADOR_IS_LAYOUT_FIXED: () => initialState,
        TOGGLE_GERENCIADOR_IS_LAYOUT_FIXED: (state) => !state,
    },
});

export const selectorIsLayoutFixed = (state: RootState) => {
    return state.gerenciadorPrecosReducer.isLayoutFixed;
};

export const { RESET_GERENCIADOR_IS_LAYOUT_FIXED, TOGGLE_GERENCIADOR_IS_LAYOUT_FIXED } = slice.actions;

export default slice.reducer;
