import _ from 'lodash';
import { DataItem } from '../../@types/DataItem';

export class FilterHelper {
    static mergeData(data: DataItem[] = [], cache: DataItem[] = []): DataItem[] {
        return _.uniqBy([...cache, ...data], 'value');
    }
    static onSuccess(data: DataItem[] = [], cache: DataItem[] = []) {
        const uniqData = FilterHelper.mergeData(data, cache);
        const value = uniqData.map(({ value }) => value);
        return [uniqData, value] as const;
    }
    static findDataByValue(value: string, data: DataItem[]): DataItem | undefined {
        return data.find((item) => item.value === value);
    }
    static filterDataByValue(value: string[], data: DataItem[]): DataItem[] {
        return data.filter((item) => value.includes(item.value));
    }
}
