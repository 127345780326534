import React from 'react';
import { Button } from 'rsuite';
import { UpdatedHeader } from '../../../../../components/UpdatedHeader';
import {
    FiltersArea,
    MainContainer,
    ResultsFoundActionArea,
} from '../../components';
import { RetailConsultCurrentTable } from './components/RetailConsultCurrentTable';
import { LinkButton } from '../../../../../components';
import styles from './RetailStrategicConsultCurrentTable.module.scss';

export const RetailStrategicConsultCurrentTable = () => {
    return (
        <MainContainer>
            <div className={styles['header-wrapper']}>
                <div>
                    <UpdatedHeader title="Tabela varejo estratégico" date="" />
                    <div className={styles['subtitle-wrapper']}>
                        <p className={styles.subtitle}>As alterações são salvas automaticamente</p>
                        <LinkButton fontSize="12px">Editar regras da tabela</LinkButton>
                    </div>
                </div>
                <Button className={styles['btn-consult']}>Editar tabela</Button>
            </div>
            <FiltersArea />
            <ResultsFoundActionArea value={23}>
                <Button className={styles['btn-download']} appearance="primary">
                    Download
                </Button>
            </ResultsFoundActionArea>
            <RetailConsultCurrentTable />
        </MainContainer>
    );
};
