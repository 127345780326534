import React, { useState } from 'react';
import { TabelaBaseFormBox } from '../TabelaBaseFormBox';
import { LabelInputGroup } from '../LabelInputGroup';
import { ImpostosDataType } from '../../pages/EditBaseTable/types';
import { totalImpostos } from '../../calculos';
import styles from './ImpostosBox.module.scss';

type ImpostosBoxProps = {
    impostosData: ImpostosDataType,
    disabled?: boolean,
    onChange?: (name: string, value: number) => void,
}

export const ImpostosBox = ({
    impostosData,
    disabled,
    onChange
}: ImpostosBoxProps) => {
    const [ imposto, setImposto ] = useState(impostosData)

    const handleImpostoChange = (name: string, value: number) => {
        setImposto({...imposto, [name]: value})
        onChange(name, value)
    }

    return (
        <TabelaBaseFormBox title="Impostos" totalValue={totalImpostos(imposto)}>
            <LabelInputGroup
                label="IPI"
                decimalSeparator=","
                disabled={disabled}
                value={imposto?.ipi}
                onChangeEvent={(_e: any, _s: string, number: number) => handleImpostoChange('ipi', number)}
            />
            <LabelInputGroup
                label="ICMS"
                decimalSeparator=","
                disabled={disabled}
                value={imposto?.icms}
                onChangeEvent={(_e: any, _s: string, number: number) => handleImpostoChange('icms', number)}
             />
            <LabelInputGroup
                label="PIS"
                decimalSeparator=","
                disabled={disabled}
                value={imposto?.pis}
                onChangeEvent={(_e: any, _s: string, number: number) => handleImpostoChange('pis', number)}
            />
            <LabelInputGroup
                label="COFINS"
                decimalSeparator=","
                disabled={disabled}
                value={imposto?.cofins}
                onChangeEvent={(_e: any, _s: string, number: number) => handleImpostoChange('cofins', number)}
            />
            <LabelInputGroup
                label="Outros"
                decimalSeparator=","
                disabled={disabled}
                value={imposto?.outros}
                onChangeEvent={(_e: any, _s: string, number: number) => handleImpostoChange('outros', number)}
            />
        </TabelaBaseFormBox>
    );
};
