import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import * as service from '../services';
import { canaisFilterLabel } from '../../../utils';

export const useTabelaBase = () => {

    const getTabelaBaseData = async () => {
        const res = await service.getTabelaBase();

        if (res.status === 200) {
            const list = res.data.content.map((item) => ({ label: item.uf, value: item.id }));
            return list;
        }

        return [];
    };

    const {
        data,
        isLoading,
        isError,
        isSuccess,
        refetch,
    } = useQuery({
        queryKey: ['tabela-base'],
        queryFn: getTabelaBaseData,
        retry: false,
    });

    return {
        tabelaBaseData: data,
        isError,
        isSuccess,
        refetchTabelaBase: refetch,
    };
};
