import React from 'react';
import { LoadingSpinerArea } from '../../../../../components';
import Layout from '../Layout';
import {
    SetupIpaProductsSegmentationCallout,
    SetupIpaProductsSegmentationCurveAbc,
    SetupIpaProductsSegmentationFooter,
    SetupIpaProductsSegmentationHeader,
    SetupIpaProductsSegmentationInfopriceSensibility,
} from './components';
import { useProductsSegmentation } from './hooks';

export const SetupIpaProductsSegmentation = () => {
    const { query, form, segments } = useProductsSegmentation();

    return (
        <Layout.Root onSubmit={form.handleSubmit(query.handlers.update)}>
            <SetupIpaProductsSegmentationHeader />
            <SetupIpaProductsSegmentationInfopriceSensibility form={form} segments={segments} />
            <SetupIpaProductsSegmentationCurveAbc form={form} segments={segments} />
            <SetupIpaProductsSegmentationCallout />
            <SetupIpaProductsSegmentationFooter isLoading={query.isLoading} onReset={() => form.reset()} />
            <LoadingSpinerArea area="ipa/setup-form/segmentacao-de-produtos" />
        </Layout.Root>
    );
};
