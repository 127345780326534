import React from 'react';
import styles from './CompetitionInfoBox.module.scss';

type CompetitionInfoBoxProps = {
    type?: string,
    product?: string,
    className?: string,
    price?: string | number,
    percent?: string | number,
}

export const CompetitionInfoBox = ({
    type,
    product,
    price,
    percent,
    className,
}: CompetitionInfoBoxProps) => {
    const isProductContainer = type === 'product';
    const styleType = isProductContainer ? styles['product-container'] : styles.container;

    return (
        <div
            className={[styleType, className].join(' ')}
        >
            <div>
                <p className={styles.label}>
                    { isProductContainer ? 'Produto' : 'Concorrente'}
                </p>
                <p className={styles.product}>{product}</p>
            </div>
            <div className={styles['value-box-wrapper']}>
                <div className={styles['value-box']}>
                    <p className={styles['label-box']}>Preço médio</p>
                    <p className={styles.value}>
                        R$
                        {' '}
                        {price || '0,00'}
                    </p>
                </div>
                <div className={styles['value-box']}>
                    <p className={styles['label-box']}>CPI</p>
                    <p className={styles.value}>
                        {percent || '0'}
                        %
                    </p>
                </div>
            </div>
        </div>
    );
};
