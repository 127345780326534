import React, { useState } from 'react'
import {
    Button,
    Checkbox,
    Table,
    Tooltip,
    Whisper,
    InputGroup,
    Input,
} from 'rsuite';
import CurrencyFormat from 'react-currency-format';
import EditIcon from '@material-ui/icons/Edit';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import { Indicator } from '../../../../../../CalculadoraPrevisaoDemanda/Components/Indicator';
import { ContextMenu, ContextMenuTrigger, LinkButton, LoadingSpinerArea } from '../../../../../../../components';
import { useManageComercialTableStore } from '../../ManageComercialTable.store';
import { NewPriceTableInputContextMenu } from './NewPriceTableInputContextMenu';

import styles from './ManageComercialTableTable.module.scss'

const {
 Column, HeaderCell, Cell, Pagination,
} = Table;

const lengthMenu = [
    {
        value: 10,
        label: 10,
    },
    {
        value: 20,
        label: 20,
    },
];

const tableData = [
    {
        id: 1,
        produtoCodigo: '249',
        produto: 'PROPOMAX Kids 140 ml',
        linha: 'PROPOMAX',
        uf: 'Nacional',
        custoFabricacao: 4.29,
        cfInd: 1.2,
        outros: 12.4,
        outrosInd: 39.2,
        pmz: 7.64,
        pmzInd: 23.8,
        tabelaAtual: 15.90,
        taInd: 18.6,
        precoMedioAtual: 11.2,
        pmInd: 42.12,
        pmIndd: 2.1,
        vendas: 2400000,
        novoPrecoTabela: 17.45,
        npInd: 3.2356,
        margemLucroBruto: 43.9,
        mlInd: 6.294835,
        mlIndd: 76.2999,
        margemLucroOperacional: 20.5,
        mloInd: 4.987987,
        mloIndd: 6.23325435,
    },
    {
        id: 2,
        produtoCodigo: '249',
        produto: 'PROPOMAX Kids 140 ml',
        linha: 'PROPOMAX',
        uf: 'Nacional',
        custoFabricacao: 4.29,
        cfInd: 1.2,
        outros: 12.4,
        outrosInd: 39.2,
        pmz: 7.64,
        pmzInd: 23.8,
        tabelaAtual: 15.90,
        taInd: 18.6,
        precoMedioAtual: 11.2,
        pmInd: 42.12,
        pmIndd: 2.1,
        vendas: 2400000,
        novoPrecoTabela: 17.45,
        npInd: 3.2356,
        margemLucroBruto: 43.9,
        mlInd: 6.294835,
        mlIndd: 76.2999,
        margemLucroOperacional: 20.5,
        mloInd: 4.987987,
        mloIndd: 6.23325435,
    },
];

type ManageComercialTableTableProps = {
    onClickPromo?: (rowData: any) => void,
}

export const ManageComercialTableTable = ({onClickPromo}: ManageComercialTableTableProps) => {
    const [rowId, setRowId] = useState([])
    const {
        priceAnalysisComercialTableData,
        setOpenModalPriceAnalysisComercial,
        setManageComercialTableState,
    } = useManageComercialTableStore()

    const handleOnClickDetails = (rowData) => {
        setOpenModalPriceAnalysisComercial(true, rowData)
    }

    const handleOnClickPromo = (rowData) => {
        const hasId = rowId.some((item) => item === rowData.id)
        const addId = !hasId ? [...rowId, rowData.id] : rowId.filter((item) => item !== rowData.id)
        console.log(hasId, addId)
        setRowId(addId)
        setManageComercialTableState('openModalActivateProductOffer', true)
        // onClickPromo(rowData)
    }

    return (
        <div className={styles['table-scenario-container']}>
            <LoadingSpinerArea area="table-scenario" />
            <Table
                data={tableData}
                autoHeight
                id="table-negociacoes-fornecedor"
                className={styles['table-scenario']}
                rowClassName={styles['table-scenario-row']}
                rowHeight={64}
                headerHeight={46}
                // sortColumn={tableState.sortColumn}
                // sortType={tableState.sortType}
                // onSortColumn={tableHandlers.handleSortColumns}
            >
                <Column align="center" width={60}>
                    <HeaderCell style={{ padding: '2px' }}>
                        {/* <Checkbox
                            indeterminate={indeterminate}
                            checked={selectedScenario.length === tableData.totalElements}
                            onChange={tableHandlers.handleChangeAllCheckbox}
                        /> */}
                    </HeaderCell>
                    <Cell>
                        {(rowData) => (
                            <Checkbox />
                        )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={2}>
                    <HeaderCell>
                        Produto
                    </HeaderCell>
                    <Cell dataKey="produto" height={60}>
                        {(rowData) => (
                            <div>
                                <div className={styles['product-code-wrapper']}>
                                    <span className={styles['main-bold-text']}>{rowData.produtoCodigo}</span>
                                    <div className={styles.lider}>Líder</div>
                                    <Whisper
                                        delayShow={0.2}
                                        trigger="hover"
                                        placement="bottom"
                                        speaker={<Tooltip>Produto com alteração pendente de revisão</Tooltip>}
                                    >
                                        <div className={styles['alert-icon-wrapper']}>
                                            <ReportProblemIcon className={styles['alert-icon']} />
                                        </div>
                                    </Whisper>
                                </div>
                                <Whisper
                                    delayShow={0.2}
                                    trigger="hover"
                                    placement="bottom"
                                    speaker={<Tooltip>{rowData.produto}</Tooltip>}
                                >
                                    <p className={styles['subtext-bold']}>
                                        {rowData.produto}
                                    </p>
                                </Whisper>
                            </div>
                        )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell>
                        Família
                    </HeaderCell>
                    <Cell dataKey="linha" height={60}>
                        {(rowData) => (
                            <div className={styles.flex}>
                                <p className={styles['main-bold-text']}>{rowData.linha}</p>
                            </div>
                            )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell>UF</HeaderCell>
                    <Cell dataKey="uf">
                        {(rowData) => (
                            <div className={styles.flex}>
                                <p className={styles['main-bold-text']}>
                                    {rowData.uf}
                                </p>
                            </div>
                            )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell className={styles.line}>
                        <span className={styles['flex-column']}>
                            <p>Custos</p>
                        </span>
                    </HeaderCell>
                    <Cell
                        dataKey="custoFabricacao"
                        className={styles.line}
                    >
                        {(rowData) => (
                            <div>
                                <p className={styles['main-bold-text']}>
                                    <CurrencyFormat
                                        fixedDecimalScale={true}
                                        decimalScale={2}
                                        value={rowData.custoFabricacao}
                                        displayType={'text'}
                                        prefix={'R$ '}
                                        decimalSeparator={','}
                                        thousandSeparator={'.'}
                                    />
                                </p>
                                <div className={styles['outros-indicator-flex']}>
                                    <LinkButton
                                        fontSize="10px"
                                        // onClick={() => handleEditOtherCosts(rowData)}
                                    >
                                        Editar
                                    </LinkButton>
                                </div>
                            </div>
                            )}
                    </Cell>
                </Column>
                {/* <Column sortable flexGrow={1}>
                    <HeaderCell>
                        <span className={styles['flex-column']}>
                            <p>Outros</p>
                            <p>custos</p>
                        </span>
                    </HeaderCell>
                    <Cell dataKey="outros">
                        {(rowData) => (
                            <div>
                                <p className={styles['main-bold-text']}>
                                    R$
                                    {rowData.outros}
                                    {utils.formatNumberToCurrency(rowData.outputs.new_price_scenario.price, 2)}
                                </p>
                                <div className={styles['outros-indicator-flex']}>
                                    <div className={styles['neutro-indicator']}>
                                        {rowData.outrosInd}
                                        %
                                    </div>
                                    <LinkButton fontSize="10px">Editar</LinkButton>
                                </div>
                            </div>
                            )}
                    </Cell>
                </Column> */}
                <Column sortable flexGrow={1}>
                    <HeaderCell>PMZ</HeaderCell>
                    <Cell dataKey="pmz">
                        {(rowData) => (
                            <div>
                                <p className={styles['main-bold-text']}>
                                    <CurrencyFormat
                                        fixedDecimalScale={true}
                                        decimalScale={2}
                                        value={rowData.pmz}
                                        displayType={'text'}
                                        prefix={'R$ '}
                                        decimalSeparator={','}
                                        thousandSeparator={'.'}
                                    />
                                </p>
                                {/* <div className={[styles['neutro-indicator'], styles['pmz-indicator']].join(' ')}>
                                    {rowData.pmzInd}
                                    %
                                </div> */}
                            </div>
                            )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell className={styles.line}>
                        <span className={styles['flex-column']}>
                            <p>Tabela</p>
                            <p>base</p>
                        </span>
                    </HeaderCell>
                    <Cell dataKey="tabelaAtual" className={styles.line}>
                        {(rowData) => (
                            <div>
                                <p className={styles['main-bold-text']}>
                                    <CurrencyFormat
                                        fixedDecimalScale={true}
                                        decimalScale={2}
                                        value={rowData.tabelaAtual}
                                        displayType={'text'}
                                        prefix={'R$ '}
                                        decimalSeparator={','}
                                        thousandSeparator={'.'}
                                    />
                                </p>
                                <span className={styles['currency-indicator']}>
                                    $
                                    {' '}
                                    {rowData.taInd}
                                    %
                                </span>
                            </div>
                )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell>
                        <span className={styles['flex-column']}>
                            <p>Tabela</p>
                            <p>atual</p>
                        </span>
                    </HeaderCell>
                    <Cell dataKey="tabelaAtual">
                        {(rowData) => (
                            <div>
                                <p className={styles['main-bold-text']}>
                                    <CurrencyFormat
                                        fixedDecimalScale={true}
                                        decimalScale={2}
                                        value={rowData.tabelaAtual}
                                        displayType={'text'}
                                        prefix={'R$ '}
                                        decimalSeparator={','}
                                        thousandSeparator={'.'}
                                    />
                                </p>
                                <span className={styles['currency-indicator']}>
                                    $
                                    {' '}
                                    {rowData.taInd}
                                    %
                                </span>
                            </div>
                        )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell className={styles.line}>
                        <span className={styles['flex-column']}>
                            <p>Novo preço</p>
                            <p>de tabela</p>
                        </span>
                    </HeaderCell>
                    <Cell
                        dataKey="novoPrecoTabela"
                        className={[styles['custo-fabricacao-cell'], styles.line].join(' ')}
                    >
                        {(rowData) => (
                            <ContextMenu>
                                <ContextMenuTrigger>
                                    <div>
                                        <InputGroup className={styles['input-group']}>
                                            <InputGroup.Addon>R$</InputGroup.Addon>
                                            <Input />
                                        </InputGroup>
                                        <div className={styles.flex}>
                                            <Indicator
                                                fontSize="10px"
                                            />
                                            <div className={styles['edit-indicator']}>Editado</div>
                                        </div>

                                    </div>
                                </ContextMenuTrigger>
                                <NewPriceTableInputContextMenu />
                            </ContextMenu>
                            )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell>
                        <span className={styles['flex-column']}>
                            <p>Preço médio</p>
                            <p>projetado</p>
                        </span>
                    </HeaderCell>
                    <Cell dataKey="outros">
                        {(rowData) => (
                            <div>
                                <p className={styles['main-bold-text']}>
                                    <CurrencyFormat
                                        fixedDecimalScale={true}
                                        decimalScale={2}
                                        // value={precoMedioProjetadoCalc(rowData)}
                                        value={2.34}
                                        displayType={'text'}
                                        prefix={'R$ '}
                                        decimalSeparator={','}
                                        thousandSeparator={'.'}
                                    />
                                </p>
                                <div className={styles['outros-indicator-flex']}>
                                    <LinkButton
                                        fontSize="10px"
                                        onClick={() => handleOnClickDetails(rowData)}
                                    >
                                        Detalhes
                                    </LinkButton>
                                </div>
                            </div>
                            )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell>
                        <span className={styles['flex-column']}>
                            <p>Margem</p>
                            <p>lucro bruto</p>
                        </span>
                    </HeaderCell>
                    <Cell dataKey="margemLucroBruto" >
                        {(rowData) => (
                            <div>
                                <p className={styles['main-bold-text']}>
                                    <CurrencyFormat
                                        fixedDecimalScale={true}
                                        decimalScale={1}
                                        // value={margemBrutaCalc(rowData)}
                                        value={40}
                                        displayType={'text'}
                                        suffix={'%'}
                                        decimalSeparator={','}
                                        thousandSeparator={'.'}
                                    />
                                </p>
                                <div className={styles.flex}>
                                    <Indicator
                                        className={styles['margem-indicator']}
                                        fontSize="10px"
                                        value={45}
                                        // value={margemBrutaPercentVariationCalc(rowData)}
                                    />
                                </div>
                            </div>
                            )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell>
                        <span className={styles['flex-column']}>
                            <p>Margem lucro</p>
                            <p>operacional</p>
                        </span>
                    </HeaderCell>
                    <Cell dataKey="margemLucroOperacional">
                        {(rowData) => (
                            <div>
                                <p className={styles['main-bold-text']}>
                                    <CurrencyFormat
                                        fixedDecimalScale={true}
                                        decimalScale={1}
                                        // value={margemBrutaCalc(rowData)}
                                        value={40}
                                        displayType={'text'}
                                        suffix={'%'}
                                        decimalSeparator={','}
                                        thousandSeparator={'.'}
                                    />
                                </p>
                                <div className={styles.flex}>
                                    <Indicator
                                        className={styles['margem-indicator']}
                                        fontSize="10px"
                                        value={45}
                                        // value={margemBrutaPercentVariationCalc(rowData)}
                                    />
                                </div>
                            </div>
                            )}
                    </Cell>
                </Column>
                <Column sortable width={68}>
                    <HeaderCell className={styles.line}>
                        Promo
                    </HeaderCell>
                    <Cell
                        dataKey="promo"
                        className={[styles.line, styles['promo-cell']].join(' ')}
                    >
                        {(rowData) => (
                            <Button
                                className={styles[rowId.some((item) => item === rowData.id) ? 'btn-promo-selected' : 'btn-promo']}
                                onClick={() => handleOnClickPromo(rowData)}
                            >
                                <LocalOfferIcon />
                                <p className={styles['promo-unselected-text']}>
                                    Ativar
                                </p>
                                <p className={styles['promo-unselected-text']}>
                                    promo
                                </p>
                            </Button>
                            // <Button className={styles['btn-promo-selected']}>
                            //     <LocalOfferIcon />
                            //     <p className={styles['promo-selected-text']}>
                            //         Promo até
                            //     </p>
                            //     <p className={styles['promo-selected-text']}>
                            //         21/05/2024
                            //     </p>
                            // </Button>
                            )}
                    </Cell>
                </Column>
            </Table>
            <Pagination
                // activePage={tableState.page + 1 || null}
                // displayLength={tableData.size}
                // total={tableData.totalElements || null}
                // onChangePage={tableHandlers.handleChangePage}
                // onChangeLength={tableHandlers.handleChangeSize}
                lengthMenu={lengthMenu}
            />
        </div>

    );
}
