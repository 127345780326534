import classNames from 'classnames';
import React, { ComponentProps, forwardRef } from 'react';
import styles from './Layout.module.scss';

export type TitleProps = ComponentProps<'h2'>;

const Title = forwardRef<HTMLHeadingElement, TitleProps>(({ className, children, ...props }, ref) => {
    return (
        <h2 {...props} ref={ref} className={classNames(styles.title, className)}>
            {children}
        </h2>
    );
});

Title.displayName = 'Title';

export { Title };
