import ConfiguracaoUsuarios from '../pages/Administrador/Pessoas';
import ProductsConfigPage from '../pages/Administrador/Produtos';
import UploadArquivosPage from '../pages/Administrador/Upload';
import { CalculadoraCenariosSalvos, CalculadoraDemanda, ComparacaoCenarios } from '../pages/CalculadoraPrevisaoDemanda';
import CriarContaPage from '../pages/CriarConta';
import HistoricoPrecoDatapointPage from '../pages/HistoricoPrecoDatapointPage';
import CampanhasPage from '../pages/IPA/Campanhas';
import BlocosOfertas from '../pages/IPA/Campanhas/Cadastro/BlocoDeOferta';
import DadosCampanha from '../pages/IPA/Campanhas/Cadastro/DadosCampanha';
import Extracao from '../pages/IPA/Campanhas/Cadastro/Extracao';
import LojasParticipantes from '../pages/IPA/Campanhas/Cadastro/LojasParticipantes';
import Ofertas from '../pages/IPA/Campanhas/Cadastro/Ofertas';
import { EditarRegraDePrecoPage, MargemObjetiva, PrecoAtacado, RegrasDePreco } from '../pages/IPA/Estrategia/index.ts';
import PriceExtraction from '../pages/IPA/ExtracaoPreco/PriceExtraction';
import LimitePrecoPage from '../pages/IPA/LimitesPreco';
import LimitePrecoCadastroPage from '../pages/IPA/LimitesPreco/Cadastro';
import { GerenciadorPrecos, Onboarding } from '../pages/IPA/Lite/pages';
import {
    DetalhesDaNegociacao,
    NegociacaoFornecedor,
    NegociacoesSalvas,
} from '../pages/IPA/Negociacoes/index.ts';
import {
    CreateComercialTable,
    CurrentBaseTable,
    EditBaseTable,
    ManageComercialTable,
    PriceTableList,
    RetailStrategicConsultCurrentTable,
} from '../pages/IPA/PlataformaPrecificacao';
import PrecificacaoManualPage from '../pages/IPA/PrecificacaoManual';
import ProdutoPrecificacaoManualPage from '../pages/IPA/PrecificacaoManual/ProdutoPrecificacaoManual';
import PrecificacaoManualLojaPage from '../pages/IPA/PrecificacaoManualLoja';
import PrecoPsicologicoPage from '../pages/IPA/PrecoPsicologico';
import RegrasPrecoPage from '../pages/IPA/RegrasPreco';
import RevisaoPage from '../pages/IPA/RevisaoPrecos';
import SimulacoesAnalises from '../pages/IPA/SimulacoesAnalises';
import PrevisaoDemanda from '../pages/IPA/SimulacoesAnalises/PrevisaoDemanda';
import PrevisoesSalvas from '../pages/IPA/SimulacoesAnalises/PrevisaoDemanda/PrevisoesSalvas';
import PrevisaoSalva from '../pages/IPA/SimulacoesAnalises/PrevisaoDemanda/PrevisoesSalvas/PrevisaoSalva';
import PrevisaoDemandaV2 from '../pages/IPA/SimulacoesAnalises/PrevisaoDemanda/v2/pages/Principal';
import { SimulacoesSalvasV2 } from '../pages/IPA/SimulacoesAnalises/PrevisaoDemanda/v2/pages/SimulacoesSalvas';
import TabelaPrecos from '../pages/IPA/TabelaPrecos';
import CompetitividadeGeral from '../pages/IRA/Pages/CompetitividadeGeral/index.tsx';
import MargemPraticadaGeral from '../pages/IRA/Pages/MargemPraticadaGeral/index.tsx';
import VendaGerais from '../pages/IRA/Pages/VendasGerais/index.tsx';
import IraAnalytics from '../pages/IRA/index.tsx';
import AnaliseCompetitividade from '../pages/ISA/InfoPanel/AnaliseCompetitividade';
import FreemiumCadastro from '../pages/ISA/InfoPanel/Freemium/Cadastro';
import CadastroComSucesso from '../pages/ISA/InfoPanel/Freemium/CadastroComSucesso';
import FreemiumWizard from '../pages/ISA/InfoPanel/Freemium/Wizard';
import InfoPanel from '../pages/ISA/InfoPanel/InfoPanel';
import ModuloRelatorio from '../pages/ISA/InfoPanel/ModuloRelatorio';
import OnDemand from '../pages/ISA/InfoPanel/OnDemand';
import { RequestInSystemContent } from '../pages/ISA/InfoPanel/OnDemand/Content/RequestInSystemContent';
import StoreCompetitors from '../pages/ISA/InfoPanel/StoreCompetitors';
import AcompanhamentoColetas from '../pages/ISA/MPDV/AcompanhamentoColetas';
import DetalheColeta from '../pages/ISA/MPDV/AcompanhamentoColetas/Components/DetalheColeta';
import DownloadRelatorioPage from '../pages/ISA/MPDV/DownloadRelatorio';
import ExtracaoDadosPage from '../pages/ISA/MPDV/ExtracaoDados';
import GestaoRetorno from '../pages/ISA/MPDV/GestaoRetorno';
import HistoricoPrecoPage from '../pages/ISA/MPDV/HistoricoPreco';
import LockedStores from '../pages/ISA/MPDV/LockedStores';
import RevisaoLista from '../pages/ISA/MPDV/RevisaoLista';
import Acompanhamento from '../pages/ISA/PaineisCustomizados/Acompanhamento';
import Analise from '../pages/ISA/PaineisCustomizados/Analise';
import LoginPage from '../pages/Login';
import NovaSenhaPage from '../pages/NovaSenha';
import PainelAbras from '../pages/PainelAbras';
import RecuperacaoSenhaPage from '../pages/RecuperarSenha';
import RegrasPrecoAtacadoCadastroPage from '../pages/RegraPrecoAtacadoCadastroPage';
import RegrasPrecoAtacadoPage from '../pages/RegraPrecoAtacadoPage';
import ShareAnalyze from '../pages/ShareAnalyze';

export const RoutesConfig = [
    {
        path: '/login',
        component: LoginPage,
        privateRoute: false,
        adminArea: false,
    },
    {
        path: '/recuperar-senha',
        component: RecuperacaoSenhaPage,
        privateRoute: false,
        adminArea: false,
    },
    {
        path: '/nova-senha',
        component: NovaSenhaPage,
        privateRoute: false,
        adminArea: false,
    },
    {
        path: '/criar-conta',
        component: CriarContaPage,
        privateRoute: false,
        adminArea: false,
    },
    {
        path: '/free/email',
        component: FreemiumCadastro,
        privateRoute: false,
        adminArea: false,
    },
    {
        path: '/free/usuario',
        component: FreemiumCadastro,
        privateRoute: false,
        adminArea: false,
    },
    {
        path: '/free/configuracoes',
        component: FreemiumWizard,
        privateRoute: false,
        adminArea: false,
    },
    {
        path: '/free/sucesso',
        component: CadastroComSucesso,
        privateRoute: false,
        adminArea: false,
    },
    {
        path: '/share-analyze',
        component: ShareAnalyze,
        privateRoute: false,
        adminArea: false,
    },
    {
        path: '/isa/historico-preco',
        component: HistoricoPrecoPage,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/isa/download-relatorio/:id',
        component: DownloadRelatorioPage,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/isa/download-relatorio',
        component: DownloadRelatorioPage,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/isa/extracao-dados',
        component: ExtracaoDadosPage,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/isa/gestao-retorno',
        component: GestaoRetorno,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/isa/acompanhamento-coletas',
        component: AcompanhamentoColetas,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/isa/acompanhamento-coletas/detalhe',
        component: DetalheColeta,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/isa/revisao-lista',
        component: RevisaoLista,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/analise/:id',
        component: Analise,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/acompanhamento/:id',
        component: Acompanhamento,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/isa/painel-geral',
        component: InfoPanel,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/isa/modulo-relatorio',
        component: ModuloRelatorio,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/isa/modulo-relatorio/:id',
        component: ModuloRelatorio,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/isa/analise-competitividade/varejo',
        component: AnaliseCompetitividade,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/isa/analise-competitividade/produtos',
        component: AnaliseCompetitividade,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/isa/solicitacao-ondemand',
        component: OnDemand,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/isa/solicitacao-ondemand/sistema',
        component: RequestInSystemContent,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/isa/lojas-bloqueadas',
        component: LockedStores,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/isa/lojas-concorrentes',
        component: StoreCompetitors,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/ipa/precificacao-produto',
        component: PrecificacaoManualPage,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/ipa/precificacao-produto/:identificador',
        component: ProdutoPrecificacaoManualPage,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/ipa/precificacao-produto/:identificador/precificar',
        component: PrecificacaoManualLojaPage,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/ipa/extracao-preco',
        component: PriceExtraction,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/ipa/historico-preco',
        component: HistoricoPrecoDatapointPage,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/ipa/regras-preco',
        component: RegrasPrecoPage,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/ipa/regras-preco/cadastro',
        component: RegrasDePreco,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/ipa/regras-preco/:id',
        component: EditarRegraDePrecoPage,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/ipa/regras-atacado',
        component: RegrasPrecoAtacadoPage,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/ipa/regras-atacado/cadastro',
        component: RegrasPrecoAtacadoCadastroPage,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/ipa/regras-atacado/:id',
        component: RegrasPrecoAtacadoCadastroPage,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/ipa/limites-preco',
        component: LimitePrecoPage,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/ipa/tabela-precos',
        component: TabelaPrecos,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/ipa/limites-preco/cadastro',
        component: LimitePrecoCadastroPage,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/ipa/limites-preco/:id',
        component: LimitePrecoCadastroPage,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/ipa/gerenciador-de-precos',
        component: RevisaoPage,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/ipa/campanhas',
        component: CampanhasPage,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/ipa/campanhas/cadastro/dados-campanha/:id?',
        component: DadosCampanha,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/ipa/campanhas/cadastro/blocos-de-oferta/:id',
        component: BlocosOfertas,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/ipa/campanhas/cadastro/lojas-participantes/:id',
        component: LojasParticipantes,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/ipa/campanhas/cadastro/ofertas/:id',
        component: Ofertas,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/ipa/campanhas/cadastro/extracao-precos/:id',
        component: Extracao,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/ipa/preco-psicologico',
        component: PrecoPsicologicoPage,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/ipa/simulacoes-analises',
        component: SimulacoesAnalises,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/ipa/simulacoes-analises/previsao-demanda',
        component: PrevisaoDemanda,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/ipa/simulacoes-analises/previsao-demanda-v2',
        component: PrevisaoDemandaV2,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/ipa/simulacoes-analises/previsao-demanda-v2/lista',
        component: SimulacoesSalvasV2,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/ipa/simulacoes-analises/previsao-demanda-v2/:id',
        component: PrevisaoDemandaV2,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/ipa/simulacoes-analises/previsao-demanda/lista',
        component: PrevisoesSalvas,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/ipa/simulacoes-analises/previsao-demanda/:id',
        component: PrevisaoSalva,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/ipa/negociacoes/fornecedor/',
        component: NegociacaoFornecedor,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/ipa/negociacoes/salvas/',
        component: NegociacoesSalvas,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/ipa/negociacoes/salvas/:id',
        component: DetalhesDaNegociacao,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/ipa/estrategia/margem-objetiva',
        component: MargemObjetiva,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/ipa/estrategia/preco-atacado',
        component: PrecoAtacado,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/ipa/lite/gerenciador-de-precos/',
        component: GerenciadorPrecos,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/ipa/lite/onboarding/',
        component: Onboarding,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/ira',
        component: IraAnalytics,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/ira/competitividade-geral',
        component: CompetitividadeGeral,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/ira/margem-praticada-geral',
        component: MargemPraticadaGeral,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/ira/vendas-gerais',
        component: VendaGerais,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/painel-abras/:id',
        component: PainelAbras,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/administrador/pessoas',
        component: ConfiguracaoUsuarios,
        privateRoute: true,
        adminArea: true,
    },
    {
        path: '/administrador/produtos',
        component: ProductsConfigPage,
        privateRoute: true,
        adminArea: true,
    },
    {
        path: '/administrador/produtos/IPA/*',
        component: ProductsConfigPage,
        privateRoute: true,
        adminArea: true,
    },
    {
        path: '/upload',
        component: UploadArquivosPage,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/ipa/calculadora-demanda',
        component: CalculadoraDemanda,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/ipa/cenarios-salvos',
        component: CalculadoraCenariosSalvos,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/ipa/comparacao-cenarios',
        component: ComparacaoCenarios,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/ipa/plataforma-editar-tabela-base',
        component: EditBaseTable,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/ipa/tabela-base-atual',
        component: CurrentBaseTable,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/ipa/lista-tabelas-preco',
        component: PriceTableList,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/ipa/criar-tabela-comercial',
        component: CreateComercialTable,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/ipa/gerenciar-tabela-comercial',
        component: ManageComercialTable,
        privateRoute: true,
        adminArea: false,
    },
    {
        path: '/ipa/tabela-comercial-atual',
        component: RetailStrategicConsultCurrentTable,
        privateRoute: true,
        adminArea: false,
    },
];
