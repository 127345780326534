import classNames from 'classnames';
import React, { ComponentProps, forwardRef } from 'react';
import styles from './Layout.module.scss';

export type SectionProps = ComponentProps<'div'>;

const Section = forwardRef<HTMLDivElement, SectionProps>(({ className, ...props }, ref) => {
    return <div {...props} ref={ref} className={classNames(styles.section, className)} />;
});

Section.displayName = 'Section';

export type HeaderProps = ComponentProps<'div'>;

const Header = forwardRef<HTMLDivElement, HeaderProps>(({ className, ...props }, ref) => {
    return <div {...props} ref={ref} className={classNames(styles['section__header'], className)} />;
});

Header.displayName = 'Header';

export type ContentProps = ComponentProps<'div'>;

const Content = forwardRef<HTMLDivElement, ContentProps>(({ className, ...props }, ref) => {
    return <div {...props} ref={ref} className={classNames(styles['section__content'], className)} />;
});

Content.displayName = 'Content';

export { Content, Header, Section };
