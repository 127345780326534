import React from 'react';
import { Button } from 'rsuite';
import { useHistory } from 'react-router-dom';
import { ContentContainer } from '../../../../../../../components';
import styles from './HeaderPriceTableList.module.scss';

export const HeaderPriceTableList = () => {
    const history = useHistory();

    return (
        <ContentContainer className={styles.container}>
            <div>
                <p className={styles.title}>Lista de tabelas de preço</p>
                <p className={styles.subtitle}>Crie tabelas de preço e defina as regras que devem seguir</p>
            </div>
            <Button
                className={styles['btn-new-table']}
                appearance="primary"
                onClick={() => history.push('ipa/criar-tabela-comercial')}
            >
                Nova tabela
            </Button>
        </ContentContainer>
    );
};
