import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'rsuite';
import { UpdatedHeader } from '../../../../../components/UpdatedHeader';
import styles from './EditBaseTable.module.scss';
import {
    BigNumbersArea,
    FiltersArea,
    TableActionArea,
    MainContainer,
} from '../../components';
import { EditTable } from './components/EditTable';
import {
    LinkButton,
    LoadingSpiner,
 } from '../../../../../components';
import {
    ModalCompetition,
    ModalCustosGeraisTabelaBase,
    ModalCustosProduto,
    ModalDiscriminationTable,
    ModalEditConfirmation,
    ModalPriceAnalysis,
    ModalSimulationResult,
} from './components';
import { useEditBaseTableList } from './hooks/useEditBaseTableList';
import { useEditBaseTableStore } from './EditBaseTable.store';
import { useHeaderUpdateTimeView } from './hooks/useHeaderUpdateTimeView';
import { useSaveCustoFabricacao } from './hooks/useSaveCustoFabricacao';
import { EditBaseTableItemDataType } from './types';
import { useSaveCustosProduto } from './hooks/useSaveCustosProduto';
import { usePriceApply } from './hooks/usePriceApply';
import DownloadIcon from '../../../../../assets/icons/icon_download_gray600.svg';

export const EditBaseTable = () => {
    const history = useHistory();

    const {
        openModalEditOtherCosts,
        productItemData,
        openModalPriceAnalysis,
        productItemSelectedList,
        openModalEditGeneralCosts,
        openModalEditConfirmation,
        custosGeraisTabelaBody,
        editBaseTableListContent,
        setEditBaseTableState,
        setOpenModalEditOtherCosts,
        setOpenModalEditGeneralCosts,
        custosProdutoBody,
        priceAnalysisTableData,
    } = useEditBaseTableStore();

    const {
        editTableBaseData,
        tableData,
        setTableData,
        handleChangeEditBaseTableListPage,
        handleChangeEditBaseTableListLength,
    } = useEditBaseTableList();
    const { updateData } = useHeaderUpdateTimeView();
    const { handleSaveCustoFabricacao } = useSaveCustoFabricacao();
    const { handleSaveCustoProduto } = useSaveCustosProduto()
    const { handleConfirmPriceApply } = usePriceApply()

    const handleChangeCheckboxAddSelectedProductItems = (product, checked) => {
        const addProduct = checked ? [...productItemSelectedList, product] : productItemSelectedList.filter((item) => item.id !== product.id);
        const productIds = addProduct.map((item) => item.id)

        setEditBaseTableState('productItemSelectedList', addProduct)
        setEditBaseTableState('priceApplyList', productIds)
    }

    const handleClickSelectAllProducts = (all: boolean) => {
        setEditBaseTableState('productItemSelectedList', all ? tableData?.data?.content : [])
    }

    const handleBlurCustoFabricacao = (event: React.FocusEvent<HTMLInputElement>, productData: EditBaseTableItemDataType) => {
        const newData = tableData?.data.content.map(
            (item) => item.id === productData.id
                ? {...item, parametros: {...item.parametros, custoFabricacao: Number(event.target.value.replace(',', '.'))}}
                : item
        )
        setTableData({...tableData, data: {...tableData.data, content: newData}})
        handleSaveCustoFabricacao(event, productData)
    }

    const handleBlurPrecoMedio = (event: React.FocusEvent<HTMLInputElement>, productData: EditBaseTableItemDataType) => {
        const newData = tableData?.data.content.map(
            (item) => item.id === productData.id
                ? {
                    ...item, parametros: {
                        ...item.parametros,
                        precoMedio: {
                            ...item.parametros.precoMedio,
                            valorAtual: Number(event.target.value.replace(',', '.'))
                        }
                    }}
                : item
        )
        setTableData({...tableData, data: {...tableData.data, content: newData}})
        handleSaveCustoFabricacao(event, productData)
    }

    const handleBlurNovoPrecoTabela = (event: React.FocusEvent<HTMLInputElement>, productData: EditBaseTableItemDataType) => {
        const newData = tableData?.data.content.map(
            (item) => item.id === productData?.id
                ? {
                    ...item, parametros: {
                        ...item.parametros,
                        novoPrecoTabela: {
                            ...item.parametros.novoPrecoTabela,
                            valorNovo: Number(event.target.value.replace(',', '.'))
                        }
                    }}
                : item
        )
        setTableData({...tableData, data: {...tableData.data, content: newData}})
        handleSaveCustoFabricacao(event, productData)
    }

    const handleChangeCustosProduto = (objType: string, name: string, value: number) => {
        const objData = {
            ...custosProdutoBody,
            [objType]: {
                ...custosProdutoBody[objType],
                [name]: value
            }
        }
        setEditBaseTableState('custosProdutoBody', objData)
    }

    const handleOpenModalEditConfirmation = () => {
        setEditBaseTableState('openModalEditOtherCosts', false)
        setEditBaseTableState('openModalEditConfirmation', true)
    }

    return (
        <MainContainer>
            <div className={styles['flex-between']}>
                <div>
                    <UpdatedHeader
                        title="Editar tabela base"
                        date={updateData?.aplicadoEm}
                    />
                    <div className={styles['subtitle-wrapper']}>
                        <p className={styles.subtitle}>
                            As alterações são salvas automaticamente
                        </p>
                        <LinkButton
                            fontSize="12px"
                            onClick={() => setOpenModalEditGeneralCosts(true, null)}
                        >
                            Editar custos gerais da tabela
                        </LinkButton>
                    </div>
                </div>
                <Button
                    className={styles['btn-consultar-tabela-base']}
                    onClick={() => history.push('/ipa/tabela-base-atual')}
                >
                    Consultar tabela base atual
                </Button>
            </div>
            {/* <BigNumbersArea />
            <FiltersArea /> */}
            <TableActionArea
                data={tableData?.data}
                selectedItems={productItemSelectedList}
                onClickSelectAll={handleClickSelectAllProducts}
            >
                {/* <Button className={styles['btn-gray']}>
                    <img src={DownloadIcon} alt="" />
                </Button> */}
                <Button
                    className={styles['btn-apply']}
                    appearance="primary"
                    disabled={!productItemSelectedList.length}
                    onClick={handleConfirmPriceApply}
                >
                    Aplicar preço
                </Button>
            </TableActionArea>
            <EditTable
                data={tableData?.data}
                onChangeCheckbox={handleChangeCheckboxAddSelectedProductItems}
                onBlurCustoFabricacao={handleBlurCustoFabricacao}
                onBlurPrecoMedio={handleBlurPrecoMedio}
                onBlurNovoPrecoTabela={handleBlurNovoPrecoTabela}
                onChangePage={handleChangeEditBaseTableListPage}
                onChangeLength={handleChangeEditBaseTableListLength}
            />
            <ModalCustosProduto
                show={openModalEditOtherCosts}
                title="Custos do produto"
                subtitle="Defina os custos específicos dos produtos selecionados"
                confirmButton="Salvar"
                cancelButton="Fechar"
                data={productItemData}
                onCancel={() => setOpenModalEditOtherCosts(false, null)}
                onConfirm={handleOpenModalEditConfirmation}
                onChangeCustosProduto={handleChangeCustosProduto}
            />
            <ModalCustosGeraisTabelaBase
                show={openModalEditGeneralCosts}
                title="Custos gerais da tabela base"
                isAlertMessage
                subtitle="Defina os custos gerais para a formação da tabela base de preços. Esses valores refletirão em todos os itens da tabela"
            />
            <ModalSimulationResult />
            <ModalCompetition />
            <ModalDiscriminationTable />
            <ModalPriceAnalysis
                show={openModalPriceAnalysis}
                isAlertMessage
                tableHeight={228}
                title='Análise preço médio vs preço de tabela'
                data={priceAnalysisTableData}
                onCancel={() => setEditBaseTableState('openModalPriceAnalysis', false)}
            />
            <ModalEditConfirmation
                show={openModalEditConfirmation}
                onCancel={() => setEditBaseTableState('openModalEditConfirmation', false)}
                onConfirm={handleSaveCustoProduto}
            />
        </MainContainer>
    );
};
