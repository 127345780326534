import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { Callout, InputBox, Label } from '../../../../../../components';
import { Segment } from '../../../../../../reducers/gerenciadorPrecos/segmentos';
import Layout from '../../Layout';
import { ProductsSegmentation } from '../lib/schema';
import styles from '../SetupIpaProductsSegmentation.module.scss';

const { Section, Title, Subtitle, Option } = Layout;

const { RadioGroup, Radio, Error, Select, FormDescription } = InputBox;

type OptionComponentProps = {
    form: UseFormReturn<ProductsSegmentation>;
    segments: Segment[];
};

export const SetupIpaProductsSegmentationCurveAbc = ({ form, segments }: OptionComponentProps) => {
    return (
        <Section.Root>
            <Section.Header>
                <Title>Curva ABC</Title>
                <Subtitle>
                    Você pode usar a Curva ABC para segmentar os produtos pela sua representatividade no faturamento. Disponibilizamos a Curva ABC Local,
                    calculada por loja, e Curva ABC Global, calculada para toda a rede.
                </Subtitle>
            </Section.Header>

            <Section.Content>
                <Option.Root>
                    <Option.Header>
                        <Option.Title>escopo do cálculo</Option.Title>
                        <Option.Subtitle>Escolha entre calcular a Curva ABC considerando todo o sortimento ou por categoria de produto</Option.Subtitle>
                    </Option.Header>
                    <Option.Content className={styles['option__content']}>
                        <Controller
                            control={form.control}
                            name="curve.calcType"
                            render={({ field: { value, onChange }, fieldState: { error } }) => {
                                return (
                                    <InputBox>
                                        <RadioGroup value={value} onChange={onChange}>
                                            <Radio className={styles['radio-description']} value="ALL_PRODUCTS_SORTMENT">
                                                <span>Calcular entre todo o sortimento de produtos</span>
                                                <FormDescription>A curva será calculada entre todos os produtos do sortimento juntos </FormDescription>
                                            </Radio>
                                            <Radio className={styles['radio-description']} value="ALL_PRODUCTS_CATEGORY">
                                                <span>Calcular entre os produtos de cada categoria</span>
                                                <FormDescription>
                                                    A curva será calculada entre os produtos de cada categoria. Você pode escolher qual nível de categoria
                                                    calcular.
                                                </FormDescription>
                                            </Radio>
                                        </RadioGroup>
                                        <Error message={error?.message} />
                                    </InputBox>
                                );
                            }}
                        />

                        <Controller
                            control={form.control}
                            name="curve.categoryLevel"
                            render={({ field: { value, ...field }, fieldState: { error } }) => {
                                const isDisabled = form.watch('curve.calcType') === 'ALL_PRODUCTS_SORTMENT';

                                return (
                                    <div>
                                        <InputBox className={styles['select-container']}>
                                            <Label>Nível de categoria para o cálculo de Curva ABC</Label>
                                            <Select
                                                {...field}
                                                data={segments}
                                                searchable={false}
                                                cleanable={false}
                                                value={isDisabled ? null : value}
                                                disabled={isDisabled}
                                            />
                                            <Error message={error?.message} />
                                        </InputBox>
                                        <Callout className={styles.callout} size="sm" variant="info">
                                            A Curva ABC é calculada agrupando os dados dos produtos que fazem parte de uma mesma família.
                                        </Callout>
                                    </div>
                                );
                            }}
                        />
                    </Option.Content>
                </Option.Root>
            </Section.Content>
        </Section.Root>
    );
};
