import React from 'react';
import ModalConfirm from '../../../../../../../components/ModalConfirm';
import { CompetitionInfoBox } from '../../../../components';
import styles from './ModalCompetition.module.scss';

type ModalCompetitionProps = {
    show?: boolean
}

export const ModalCompetition = ({ show, ...props }: ModalCompetitionProps) => {
    return (
        <ModalConfirm
            show={show}
            modalWidth="672px"
            titleFontSize="16px"
            footerAlign="end"
            title="Concorrência InfoPanel"
            confirmButton="Abrir no infopanel"
            cancelButton="Fechar"
            className={styles['modal-competition']}
        >
            <div className={styles.content}>
                <CompetitionInfoBox
                    product="253 - PROPOMAX Kids 140 ml"
                    type="product"
                    price="32,45"
                    percent="45.3"
                />
                <CompetitionInfoBox
                    product="Spray para Garganta Extrato de Própolis Apis Vida Sabor Gengibre 30ml"
                    price="32,45"
                    percent="45.3"
                />
            </div>
        </ModalConfirm>
    );
};
