import { z } from 'zod';

const categoryLevel = z.enum(['categoryLevel1', 'categoryLevel2', 'categoryLevel3', 'categoryLevel4']).nullable();

const calcType = z.enum(['ALL_PRODUCTS_SORTMENT', 'ALL_PRODUCTS_CATEGORY']);

const storeLocation = z.enum(['SINGLE_STORE', 'ALL_STORES']);

const categoryObject = z.object({ calcType, categoryLevel });

const store = z.object({
    location: storeLocation,
    category: categoryObject,
});

const productsSegmentationSchema = z.object({
    store,
    curve: categoryObject,
});

export const initialData: ProductsSegmentation = {
    store: {
        location: 'SINGLE_STORE',
        category: {
            calcType: 'ALL_PRODUCTS_SORTMENT',
            categoryLevel: null,
        },
    },
    curve: {
        calcType: 'ALL_PRODUCTS_SORTMENT',
        categoryLevel: null,
    },
};

export type ProductsSegmentation = z.infer<typeof productsSegmentationSchema>;

export default productsSegmentationSchema;
