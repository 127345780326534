import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import * as service from '../services';
import { tipoLimiteFilterLabel } from '../../../utils';

export const useTipoLimiteFilter = () => {

    const getTipoLimiteData = async () => {
        const res = await service.getTiposLimiteList();

        if (res.status === 200) {
            const list = res.data.map((item) => ({ label: tipoLimiteFilterLabel[item], value: item }));
            return list;
        }

        return [];
    };

    const {
        data,
        isLoading,
        isError,
        isSuccess,
        refetch,
    } = useQuery({
        queryKey: ['tipo-limite'],
        queryFn: getTipoLimiteData,
        retry: false,
    });

    return {
        tipoLimiteData: data,
        isError,
        isSuccess,
        refetchTipoLimite: refetch,
    };
};
