import React from 'react';
import { Button, Checkbox } from 'rsuite';
import { BorderedContentContainer } from '../../../../components';
import SelectPill from '../../../../../../../components/SelectPill';
import { LoadingMenu } from '../../../../../../../components/LoadingSpinner';

import { filterTitle } from '../../../../utils';
import styles from './NewPriceTableDefine.module.scss';
import { Channel, Local } from './filters';
import { useProductFilter } from '../../hooks/useProductFilter';
import { useCreateComercialTableStore } from '../../CreateComercialTable.store';

type NewPriceTableDefineProps = {
    onSelectProduct?: (item: any) => void,
}

export const NewPriceTableDefine = ({ onSelectProduct }: NewPriceTableDefineProps) => {
    const {
        baseProductData,
        handleCheckAllBaseProduct,
        handleCheckBaseProduct,
        handleCleanBaseProduct,
    } = useProductFilter();
    const {
        createComercialTableBody,
        setCreateComercialTableState,
        setCreateComercialTableCleanFilters,
    } = useCreateComercialTableStore();

    const allProductsSelected = baseProductData.list.length === baseProductData.value.length;
    const productsSelected = baseProductData.list.length > baseProductData.value.length;

    const renderSelectValue = () => {
        if (!baseProductData.value.length) {
            return <span>Selecionar produto</span>;
        }

        if (allProductsSelected) {
            return <span>Todos os produtos</span>;
        }

        if (productsSelected) {
            return <span>Produtos</span>;
        }
    };

    const handleOnSelectProduct = (item) => {
        handleCheckBaseProduct(item);
        onSelectProduct?.(item)
    }

    const handleCleanFilters = () => {
        setCreateComercialTableCleanFilters();
    }

    return (
        <BorderedContentContainer
            title="Defina onde aplicar a tabela"
            borderedContentClassName={styles['bordered-container']}
        >
            <div className={styles['form-container']}>
                <div className={styles['title-container']}>
                    {filterTitle.map((title) => (
                        <div className={styles['filter-title']}>
                            <p>{title}</p>
                        </div>
                    ))}
                </div>
                <div>
                    <div className={styles['filter-selection-wrapper']}>
                        <SelectPill
                            type="check"
                            id="check-picker-pill"
                            className={styles[allProductsSelected ? 'all-selected' : '']}
                            placeholder={renderSelectValue()}
                            name="second"
                            value={baseProductData.value}
                            data={baseProductData.list}
                            onSelect={(_v, item) => handleOnSelectProduct(item)}
                            onClean={handleCleanBaseProduct}
                            // locale={locale}
                            renderMenu={(menu) => (
                                <span>
                                    <LoadingMenu area="panel-filter" />
                                    {menu}
                                </span>
                            )}
                            renderExtraFooter={() => (
                                <div className={styles['product-filter-menu__footer']}>
                                    <Checkbox
                                        inline
                                        // indeterminate={productsCheckAllStatus}
                                        checked={allProductsSelected}
                                        onChange={handleCheckAllBaseProduct}
                                    >
                                        Selecionar tudo
                                        {' '}
                                        {/* <span>
                                            {products?.list?.length
                                                ? `(${products.list.length})`
                                                : null}
                                        </span> */}
                                    </Checkbox>
                                </div>
                            )}
                        />
                    </div>
                    <div className={styles['filter-selection-wrapper']}>
                        <Local />
                    </div>
                    <div className={styles['filter-selection-wrapper']}>
                        <Channel />
                    </div>
                </div>
                <Button
                    className={styles['btn-clear-filters']}
                    appearance="default"
                    onClick={handleCleanFilters}
                >
                    Limpar filtros
                </Button>
            </div>
        </BorderedContentContainer>
    );
};
