import { combineReducers, Reducer } from 'redux';
import { RootState } from '../../../@types/RootState';
import filtersCache, { FiltersCacheState } from './filtersCache';
import filtersData, { FiltersDataState } from './filtersData';
import filtersIsVisible, { FiltersIsVisibleState } from './filtersIsVisible';
import filtersLoading, { FiltersLoadingState } from './filtersLoading';
import filtersSearch, { FiltersSearchState } from './filtersSearch';
import filtersValues, { FiltersValueState } from './filtersValues';

export interface FiltersState {
    values: FiltersValueState;
    data: FiltersDataState;
    cache: FiltersCacheState;
    search: FiltersSearchState;
    loading: FiltersLoadingState;
    isVisible: FiltersIsVisibleState;
}

const rootReducer: Reducer<FiltersState> = combineReducers<FiltersState>({
    values: filtersValues,
    data: filtersData,
    cache: filtersCache,
    search: filtersSearch,
    loading: filtersLoading,
    isVisible: filtersIsVisible,
});

const selectorFilters = (state: RootState) => {
    return state.gerenciadorPrecosReducer.filters;
};

export { selectorFilters };

export default rootReducer;
