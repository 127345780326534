import React from 'react';
import { Indicator } from '../../../../CalculadoraPrevisaoDemanda/Components';
import { ForecastNumbers } from '../../../../CalculadoraPrevisaoDemanda/Components/ForecastNumbers';
import styles from './SimulationResultBigNumbers.module.scss';

export const SimulationResultBigNumbers = () => {
    return (
        <div className={styles['big-numbers-container']}>
            <ForecastNumbers.Root
                title="preço tabela"
            >
                <ForecastNumbers.MainValue margin="6px 0 3px 0">
                    R$ 1.433,90
                </ForecastNumbers.MainValue>
                <Indicator
                    value={12.9}
                />
                <ForecastNumbers.SubValue margin="3px 0 0 0">
                    R$ 1.433,90
                </ForecastNumbers.SubValue>
            </ForecastNumbers.Root>
            <ForecastNumbers.Root
                title="margem lucro bruto"
            >
                <ForecastNumbers.MainValue margin="6px 0 3px 0">
                    23,89%
                </ForecastNumbers.MainValue>
                <Indicator
                    type="pp"
                    value={12.9}
                />
                <ForecastNumbers.SubValue margin="3px 0 0 0">
                    23,89%
                </ForecastNumbers.SubValue>
            </ForecastNumbers.Root>
            <ForecastNumbers.Root
                title="margem lucro operacional"
            >
                <ForecastNumbers.MainValue margin="6px 0 3px 0">
                    23,89%
                </ForecastNumbers.MainValue>
                <Indicator
                    type="pp"
                    value={12.9}
                />
                <ForecastNumbers.SubValue margin="3px 0 0 0">
                    23,89%
                </ForecastNumbers.SubValue>
            </ForecastNumbers.Root>
            <ForecastNumbers.Root
                title="PREVISÃO DE faturamento (R$)"
            >
                <ForecastNumbers.MainValue margin="6px 0 3px 0">
                    R$ 1.433,90
                </ForecastNumbers.MainValue>
                <Indicator
                    value={12.9}
                />
                <ForecastNumbers.SubValue margin="3px 0 0 0">
                    R$ 1.433,90
                </ForecastNumbers.SubValue>
            </ForecastNumbers.Root>
            <ForecastNumbers.Root
                title="PREVISÃO LUCRO bruto (R$)"
            >
                <ForecastNumbers.MainValue margin="6px 0 3px 0">
                    R$ 1.433,90
                </ForecastNumbers.MainValue>
                <Indicator
                    value={12.9}
                />
                <ForecastNumbers.SubValue margin="3px 0 0 0">
                    R$ 1.433,90
                </ForecastNumbers.SubValue>
            </ForecastNumbers.Root>
            <ForecastNumbers.Root
                title="PREVISÃO LUCRO operacional (R$)"
            >
                <ForecastNumbers.MainValue margin="6px 0 3px 0">
                    R$ 1.433,90
                </ForecastNumbers.MainValue>
                <Indicator
                    value={12.9}
                />
                <ForecastNumbers.SubValue margin="3px 0 0 0">
                    R$ 1.433,90
                </ForecastNumbers.SubValue>
            </ForecastNumbers.Root>
        </div>
    );
};
