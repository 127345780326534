/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {
    Alert,
    Button,
    Col,
    Dropdown,
    Icon,
    Modal,
    Nav,
    Navbar,
    Tooltip,
    Whisper,
} from 'rsuite';
import logoInfoprice from '../assets/logo/logo-infoprice-branco.svg';
import { Chip } from '../pages/IPA/Lite';
import { lead } from '../services/EventLeadService';

import { resetIdentity } from '../utils/HeapConfig';
import { trackEvent } from '../utils/MatomoConfig';
import { ModalIPALead } from './ModalIPALead';
import { ModalMPDVLead } from './ModalMPDVLead';
import ModalNoAccessInfoPanel from './ModalNoAccessInfoPanel';

function sendEventLead(identificador, usuario) {
    lead(identificador, usuario).then(() => {
        console.log(`Evento ${identificador} enviado ao RD Station`);
    });
}

export function setClickToLocalStorage(event, identificador, usuario) {
    if (JSON.parse(localStorage.getItem('eventCounter'))?.[event] < 9) {
        const eventCounter = JSON.parse(localStorage.getItem('eventCounter'));
        eventCounter[event] += 1;
        localStorage.setItem('eventCounter', JSON.stringify(eventCounter));
    } else if (
        JSON.parse(localStorage.getItem('eventCounter'))?.[event] === 9
    ) {
        sendEventLead(identificador, usuario);

        const eventCounter = JSON.parse(localStorage.getItem('eventCounter'));
        eventCounter[event] = 0;
        localStorage.setItem('eventCounter', JSON.stringify(eventCounter));
    } else {
        let eventCounter;
        if (JSON.parse(localStorage.getItem('eventCounter'))) {
            eventCounter = JSON.parse(localStorage.getItem('eventCounter'));
        } else {
            eventCounter = {};
        }
        eventCounter[event] = 1;
        localStorage.setItem('eventCounter', JSON.stringify(eventCounter));
    }
}

function setListenerZendeskButton(usuario) {
    if (document.getElementById('launcher-frame')) {
        document
            .getElementById('launcher-frame')
            .contentWindow.document.body.querySelector(
                '[data-testid="launcher-wrapper"]',
            )
            .addEventListener('mouseup', () => {
                setClickToLocalStorage('atendimento', 'atendimento', usuario);
            });
    }
}

function centralAjuda(usuario) {
    setClickToLocalStorage('centralAjuda', 'botao-ajuda', usuario);
    window.open('https://basedeconhecimento.infoprice.co/');
}

class NavBar extends Component {
    constructor(props) {
        super(props);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleAdmin = this.handleAdmin.bind(this);
        this.closeUnauthorizedPanel = this.closeUnauthorizedPanel.bind(this);
        this.closeNoAssociation = this.closeNoAssociation.bind(this);
        this.closeNoAnalise = this.closeNoAnalise.bind(this);
        this.closeNoMonitoramento = this.closeNoMonitoramento.bind(this);
        this.closeUnauthorizedIpa = this.closeUnauthorizedIpa.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        this.sendUserToSetupFreemium = this.sendUserToSetupFreemium.bind(this);
        this.setLeadData = this.setLeadData.bind(this);
        this.getPainelLink = this.getPainelLink.bind(this);
        this.showIpaBanner = this.showIpaBanner.bind(this);
        this.closeIpaBanner = this.closeIpaBanner.bind(this);
        this.showMPDVBanner = this.showMPDVBanner.bind(this);
        this.closeMPDVBanner = this.closeMPDVBanner.bind(this);

        this.state = {
            showUnauthorizedPanel: false,
            showNoAnalises: false,
            showNoMonitoramento: false,
            showUnauthorizedIpa: false,
            isIpaBannerOpen: false,
            openMPDVBanner: false,
        };
    }

    componentDidMount() {
        const { usuario } = this.props;

        setTimeout(() => {
            setListenerZendeskButton(usuario);
        }, 4000);
    }

    handleSelect(eventKey) {
        const { history, usuario } = this.props;
        const { showUnauthorizedPanel, showUnauthorizedIpa } = this.state;

        if (eventKey === '1') {
            if (usuario?.servicos.some((item) => item.name === 'COL')) {
                history.push('/isa/historico-preco');
            } else if (
                usuario?.servicosCliente.some((item) => item.name === 'COL')
            ) {
                this.setState({
                    showNoAssociation: true,
                });
            } else {
                this.setState({
                    showNoMonitoramento: true,
                });
            }
        }
        if (eventKey === '3') {
            if (usuario?.servicos.some((item) => item.name === 'BI')) {
                if (
                    usuario?.servicoBi &&
                    (usuario?.servicoBi.analises.length ||
                        usuario?.servicoBi.graficos.length)
                ) {
                    history.push(this.getPainelLink(usuario));
                } else {
                    this.setState({
                        showNoAnalises: true,
                    });
                }
            } else if (
                usuario?.servicosCliente.some((item) => item.name === 'BI')
            ) {
                this.setState({
                    showNoAssociation: true,
                });
            }
        }
        if (eventKey === '4') {
            if (usuario?.servicos.some((item) => item.name === 'PAN')) {
                history.push('/isa/painel-geral');
            } else if (
                usuario?.servicosCliente.some((item) => item.name === 'PAN')
            ) {
                this.setState({
                    showNoAssociation: true,
                });
            } else {
                this.setState({
                    showUnauthorizedPanel: !showUnauthorizedPanel,
                });
            }
        }
        if (eventKey === '5') {
            if (usuario?.servicos.some((item) => item.name === 'IPA')) {
                if (usuario?.servicoIpa.pacoteIpa === 'LITE') {
                    history.push('/ipa/lite/gerenciador-de-precos');
                } else {
                    history.push('/ipa/gerenciador-de-precos');
                }
            } else {
                this.setState({
                    showUnauthorizedIpa: !showUnauthorizedIpa,
                });
            }
        }
    }

    handleAdmin() {
        const { history } = this.props;
        history.push('/administrador/usuarios');
    }

    handleLogout() {
        const { history } = this.props;

        resetIdentity(window.heap);
        localStorage.removeItem('autenticacao.token');
        localStorage.removeItem('cliente');
        localStorage.removeItem('usuario');
        history.push('/login');
        window.location.reload(true);
    }

    getPainelLink(usuario) {
        if (usuario?.servicoBi?.analises?.length) {
            return `/analise/${usuario?.servicoBi?.analises[0]?.codigo}`;
        }
        return `/acompanhamento/${usuario?.servicoBi?.graficos[0]?.codigo}`;
    }

    setLeadData(keyValue, stateKey) {
        const { usuario } = this.props;
        const identifier = { keyValue, stateKey };

        this.sendLead(identifier, usuario);
        trackEvent('Upsell/Crossell', 'crossell', keyValue);

        if (usuario.servicoPanel.pacotePainel.name === 'FREE') {
            if (keyValue === 'Monitoramento de concorrentes') {
                window.open(
                    'https://wa.me/551137779670?text=Ol%C3%A1%21+Quero+saber+mais+sobre+o+ISA+-+Monitoramento+PDV',
                );
            }
        }
    }

    closeUnauthorizedPanel() {
        this.setState({
            showUnauthorizedPanel: false,
        });
    }

    closeUnauthorizedIpa() {
        this.setState({
            showUnauthorizedIpa: false,
        });
    }

    closeNoAssociation() {
        this.setState({
            showNoAssociation: false,
        });
    }

    closeNoAnalise() {
        this.setState({
            showNoAnalises: false,
        });
    }

    closeNoMonitoramento() {
        this.setState({
            showNoMonitoramento: false,
        });
    }

    sendLead(identifier, user) {
        lead(identifier.keyValue, user).then(() => {
            this.setState({
                [identifier.stateKey]: false,
            });
            Alert.success('Em breve entraremos em contato!');
        });
    }

    sendUserToSetupFreemium() {
        const { history } = this.props;
        history.push('/free/configuracoes');
    }

    showIpaBanner() {
        const { usuario } = this.props;

        const hasIpaPermission = usuario?.servicos.some(
            (item) => item.name === 'IPA',
        );

        if (hasIpaPermission) return;

        this.setState({
            isIpaBannerOpen: true,
        });
    }

    closeIpaBanner() {
        this.setState({
            isIpaBannerOpen: false,
        });
    }

    showMPDVBanner() {
        const { usuario } = this.props;

        const hasMPDVPermission = usuario?.servicos.some(
            (item) => item.name === 'COL',
        );

        if (hasMPDVPermission) return;

        this.setState({
            openMPDVBanner: true,
        });
    }

    closeMPDVBanner() {
        this.setState({
            openMPDVBanner: false,
        });
    }

    render() {
        const { activeKey, administradores, usuario, codigoCliente } =
            this.props;
        const {
            showUnauthorizedPanel,
            showNoMonitoramento,
            showNoAssociation,
            showNoAnalises,
            isIpaBannerOpen,
            openMPDVBanner,
        } = this.state;

        return (
            <Navbar
                componentClass={Col}
                sm={24}
                appearance="inverse"
                className="navbar-desktop"
            >
                <Navbar.Header style={{ marginRight: '25px' }}>
                    <div className="navbar-brand logo">
                        <img alt="Logo Infoprice" src={logoInfoprice} />
                    </div>
                </Navbar.Header>
                <Navbar.Body>
                    <Nav onSelect={this.handleSelect} activeKey={activeKey}>
                        <Nav.Item
                            componentClass={Col}
                            sm={24}
                            eventKey="4"
                            title="ISA | InfoPanel"
                            className={
                                !usuario?.servicos.some(
                                    (item) => item.name === 'PAN',
                                )
                                    ? 'no-access'
                                    : null
                            }
                        >
                            {!usuario?.servicos.some(
                                (item) => item.name === 'PAN',
                            ) ? (
                                <Icon icon="lock" className="mg-left-7" />
                            ) : null}
                            {!usuario?.servicos.some(
                                (item) => item.name === 'PAN',
                            ) ? (
                                <span>ISA | InfoPanel</span>
                            ) : (
                                <Link id="link-navbar" to="/isa/painel-geral">
                                    ISA | InfoPanel
                                </Link>
                            )}
                        </Nav.Item>

                        <>
                            <Nav.Item
                                componentClass={Col}
                                sm={24}
                                eventKey="1"
                                title="ISA | Monitoramento em PDV"
                                className={
                                    !usuario?.servicos.some(
                                        (item) => item.name === 'COL',
                                    )
                                        ? 'no-access'
                                        : null
                                }
                                onSelect={this.showMPDVBanner}
                            >
                                {!usuario?.servicos.some(
                                    (item) => item.name === 'COL',
                                ) ? (
                                    <Icon icon="lock" className="mg-left-7" />
                                ) : null}
                                {!usuario?.servicos.some(
                                    (item) => item.name === 'COL',
                                ) ? (
                                    <span> ISA | Monitoramento em PDV </span>
                                ) : (
                                    <Link
                                        id="link-navbar"
                                        to="/isa/historico-preco"
                                    >
                                        ISA | Monitoramento em PDV
                                    </Link>
                                )}
                            </Nav.Item>

                            {/* PROJETO MULLER */}

                            <Nav.Item
                                componentClass={Col}
                                sm={24}
                                eventKey="5"
                                title="IPA | Software de precificação"
                                className={
                                    !usuario?.servicos.some(
                                        (item) => item.name === 'IPA',
                                    )
                                        ? 'no-access'
                                        : null
                                }
                                onSelect={this.showIpaBanner}
                            >
                                {!usuario?.servicos.some(
                                    (item) => item.name === 'IPA',
                                ) ? (
                                    <Icon icon="lock" className="mg-left-7" />
                                ) : null}
                                {!usuario?.servicos.some(
                                    (item) => item.name === 'IPA',
                                ) ? (
                                    <span>IPA | Software de precificação</span>
                                ) : (
                                    <Link
                                        id="link-navbar"
                                        to={
                                            usuario?.servicoIpa.pacoteIpa ===
                                            'LITE'
                                                ? '/ipa/lite/gerenciador-de-precos'
                                                : '/ipa/gerenciador-de-precos'
                                        }
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            columnGap: '8px',
                                            flexWrap: 'wrap',
                                        }}
                                    >
                                        IPA | Software de precificação
                                        {usuario?.servicoIpa.pacoteIpa ===
                                            'LITE' && (
                                            <Chip theme="ghost">LITE</Chip>
                                        )}
                                    </Link>
                                )}
                            </Nav.Item>

                            <Nav.Item
                                componentClass={Col}
                                sm={24}
                                eventKey="7"
                                title="Simulador de demanda"
                                className={
                                    !usuario?.servicos.some(
                                        (item) => item.name === 'SDD',
                                    )
                                        ? 'no-access'
                                        : null
                                }
                            >
                                {!usuario?.servicos.some(
                                    (item) => item.name === 'SDD',
                                ) ? (
                                    <Icon icon="lock" className="mg-left-7" />
                                ) : null}
                                {!usuario?.servicos.some(
                                    (item) => item.name === 'SDD',
                                ) ? (
                                    <span>IPA | Simulador de demanda</span>
                                ) : (
                                    <Link
                                        id="link-navbar"
                                        to="/ipa/calculadora-demanda"
                                    >
                                        IPA | Simulador de demanda
                                    </Link>
                                )}
                            </Nav.Item>

                            {usuario?.servicos.some(
                                (item) => item.name === 'BI',
                            ) ||
                            usuario?.servicosCliente?.some(
                                (item) => item.name === 'BI',
                            ) ? (
                                <Nav.Item
                                    componentClass={Col}
                                    sm={24}
                                    eventKey="3"
                                    title="Painéis Customizados"
                                    className={
                                        !usuario?.servicos.some(
                                            (item) => item.name === 'BI',
                                        ) ||
                                        (!usuario.servicoBi.analises.length &&
                                            !usuario.servicoBi.graficos.length)
                                            ? 'no-access'
                                            : null
                                    }
                                >
                                    {!usuario?.servicos.some(
                                        (item) => item.name === 'BI',
                                    ) ||
                                    (!usuario.servicoBi.analises.length &&
                                        !usuario.servicoBi.graficos.length) ? (
                                        <Icon
                                            icon="lock"
                                            className="mg-left-7"
                                        />
                                    ) : null}
                                    {!usuario?.servicos.some(
                                        (item) => item.name === 'BI',
                                    ) ||
                                    (!usuario.servicoBi.analises.length &&
                                        !usuario.servicoBi.graficos.length) ? (
                                        <span>Painéis Customizados</span>
                                        // <span>Painéis Customizados</span>
                                    ) : (
                                        <Link
                                            id="link-navbar"
                                            to={this.getPainelLink(usuario)}
                                        >
                                            Painéis Customizados
                                        </Link>
                                    )}
                                </Nav.Item>
                            ) : (
                                ''
                            )}
                            {usuario?.painelAbras ? (
                                <Nav.Item
                                    componentClass={Col}
                                    sm={24}
                                    eventKey="6"
                                    title="Painel ABRAS"
                                >
                                    <Link
                                        id="link-navbar"
                                        to={`/painel-abras/${usuario?.painelAbras.graficos[0].codigo}`}
                                    >
                                        Painel ABRAS
                                    </Link>
                                </Nav.Item>
                            ) : (
                                ''
                            )}
                            {codigoCliente === 286852 ? (
                                <Nav.Item
                                    componentClass={Col}
                                    sm={24}
                                    eventKey="7"
                                    title="IPA | Indústria"
                                >
                                    <Link
                                        id="link-navbar"
                                        to="/ipa/plataforma-editar-tabela-base"
                                    >
                                        IPA | Indústria
                                    </Link>
                                </Nav.Item>
                            ) : null}
                        </>
                    </Nav>
                    <Nav
                        style={{ float: 'right' }}
                        className="config-icon-margin-right"
                    >
                        <Dropdown
                            placement="bottomEnd"
                            icon={<Icon icon="cog" />}
                        >
                            {usuario?.usuarioAdministrador ? (
                                <Dropdown.Item href="/administrador/pessoas">
                                    Administrador
                                </Dropdown.Item>
                            ) : null}
                            <Dropdown.Item href="/upload">Upload</Dropdown.Item>
                            <Dropdown.Item onClick={this.handleLogout}>
                                Sair
                            </Dropdown.Item>
                        </Dropdown>
                    </Nav>
                    <Nav style={{ float: 'right' }}>
                        {usuario?.usuarioAdministrador ? (
                            <Whisper
                                placement="bottom"
                                trigger="hover"
                                speaker={<Tooltip>Convidar Pessoas</Tooltip>}
                            >
                                <Nav.Item>
                                    <Link
                                        id="link-navbar"
                                        to="/administrador/pessoas"
                                    >
                                        <Icon icon="user-plus" />
                                    </Link>
                                </Nav.Item>
                            </Whisper>
                        ) : null}
                        <Whisper
                            placement="bottom"
                            trigger="hover"
                            speaker={<Tooltip>Base de conhecimento</Tooltip>}
                        >
                            <Nav.Item
                                onClick={() => centralAjuda(usuario)}
                                icon={<Icon icon="question" />}
                            />
                        </Whisper>
                    </Nav>
                </Navbar.Body>

                <ModalNoAccessInfoPanel
                    show={showUnauthorizedPanel}
                    close={this.closeUnauthorizedPanel}
                    confirmSetup={this.sendUserToSetupFreemium}
                />

                <ModalIPALead
                    show={isIpaBannerOpen}
                    onHide={this.closeIpaBanner}
                    user={usuario}
                />

                <ModalMPDVLead
                    show={openMPDVBanner}
                    onHide={this.closeMPDVBanner}
                    user={usuario}
                />

                <Modal
                    size="md"
                    show={showNoMonitoramento}
                    onHide={this.closeNoMonitoramento}
                >
                    <Modal.Header>
                        <Modal.Title>Monitoramento de Concorrentes</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            Gostaria de monitorar o preço de produtos
                            específicos em lojas específicas de sua escolha?
                            Clique em &quot;Quero saber mais&quot; que um
                            consultor entrará em contato com você.
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            onClick={() =>
                                this.setLeadData(
                                    'Monitoramento de concorrentes',
                                    'showNoMonitoramento',
                                )
                            }
                            appearance="primary"
                        >
                            Quero saber mais
                        </Button>
                        <Button
                            onClick={this.closeNoMonitoramento}
                            appearance="subtle"
                        >
                            Fechar
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    size="md"
                    show={showNoAssociation}
                    onHide={this.closeNoAssociation}
                >
                    <Modal.Header>
                        <Modal.Title>Usuário sem acesso</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            Você não possui acesso a este produto da InfoPrice.
                            Entre em contato com o administrador da sua conta,{' '}
                            {administradores ? administradores[0] : null}, ou
                            com o gerente de conta responsável.
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            onClick={this.closeNoAssociation}
                            appearance="subtle"
                        >
                            Fechar
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    size="md"
                    show={showNoAnalises}
                    onHide={this.closeNoAnalise}
                >
                    <Modal.Header>
                        <Modal.Title>Painéis Customizados</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            Não existem análises a serem visualizadas. Entre em
                            contato com o seu gerente de contas da InfoPrice
                            para mais informações.
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            onClick={this.closeNoAnalise}
                            appearance="subtle"
                        >
                            Fechar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Navbar>
        );
    }
}
const mapStateToProps = (store) => ({
    activeKey: store.navbarDataReducer.activeKey,
    codigoCliente: store.clienteDataReducer.codigoCliente,
    administrador: store.adminDataReducer.administrador,
    servicosCliente: store.clienteDataReducer.servicosCliente,
    administradores: store.adminDataReducer.administradores,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavBar));
