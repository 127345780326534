import React from 'react';
import Layout from '../../Layout';

const { Section, Title, Subtitle } = Layout;

const SetupIpaProductsSegmentationHeader = () => {
    return (
        <Section.Root>
            <Section.Header>
                <Title>Segmentação de produtos</Title>
                <Subtitle>Escolha entre usar a Sensibilidade InfoPrice, Curva ABC ou segmentação própria para classificar seus produtos.</Subtitle>
            </Section.Header>
        </Section.Root>
    );
};

SetupIpaProductsSegmentationHeader.displayName = 'SetupIpaProductsSegmentationHeader';

export { SetupIpaProductsSegmentationHeader };
