import React from 'react';
import ModalConfirm from '../../../../../../../components/ModalConfirm';

import { TableDiscrimination } from '../TableDiscrimination';
import styles from './ModalDiscriminationTable.module.scss';

type ModalCompetitionProps = {
    show?: boolean
}

export const ModalDiscriminationTable = ({ show, ...props }: ModalCompetitionProps) => {
    return (
        <ModalConfirm
            show={show}
            modalWidth="742px"
            titleFontSize="16px"
            footerAlign="end"
            title="Discriminação da tabela base por UF"
            confirmButton="Salvar"
            cancelButton="Fechar"
            className={styles['modal-discrimination-table']}
        >
            <div className={styles['product-name-wrapper']}>
                <p className={styles.label}>Produto</p>
                <p className={styles.product}>253 - PROPOMAX Kids 140 ml</p>
            </div>
            <TableDiscrimination />
        </ModalConfirm>
    );
};
