import React from 'react';
import EditIcon from '@material-ui/icons/Edit';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
    Table,
} from 'rsuite';
// import { selectorCalculadoraPrevisaoDemanda } from '../../../../reducers/calculadoraPrevisaoDemanda';
// import {
//     setFiltersFromEditScenario,
// } from '../../../../reducers/calculadoraPrevisaoDemanda/filtros';
// import {
//     setInputFromEditScenario,
// } from '../../../../reducers/calculadoraPrevisaoDemanda/inputCalculos';
// import {
//     setResultFromEditScenario,
//     setResultScenarioData,
// } from '../../../../reducers/calculadoraPrevisaoDemanda/resultScenario';
import { LoadingSpinerArea } from '../../../../../../../components/LoadingSpinner';

import styles from './TableDiscrimination.module.scss';

const {
 Column, HeaderCell, Cell, Pagination,
} = Table;

const lengthMenu = [
    {
        value: 10,
        label: 10,
    },
    {
        value: 20,
        label: 20,
    },
];

const tableData = [
    {
        uf: 'SP',
        custoFabricacao: 4.29,
        cfInd: 1.2,
        outros: 12.4,
        outrosInd: 39.2,
        pmz: 7.64,
        pmzInd: 23.8,
        tabelaBase: 15.90,
        tbInd: 18.6,
        margemLucroBruto: 43.9,
        mlInd: 6.294835,
        margemLucroOperacional: 20.5,
        mloInd: 4.987987,
    },
    {
        uf: 'SP',
        custoFabricacao: 4.29,
        cfInd: 1.2,
        outros: 12.4,
        outrosInd: 39.2,
        pmz: 7.64,
        pmzInd: 23.8,
        tabelaBase: 15.90,
        tbInd: 18.6,
        margemLucroBruto: 43.9,
        mlInd: 6.294835,
        margemLucroOperacional: 20.5,
        mloInd: 4.987987,
    },
];

export const TableDiscrimination = () => {
    // const { savedScenario } = useSelector(selectorCalculadoraPrevisaoDemanda);
    // const selectedScenario = savedScenario.selectedScenarioItemList;
    // const tableData = savedScenario.savedScenarioData;
    // const indeterminate = selectedScenario.length > 0 && selectedScenario.length < tableData.totalElements;

    // const {
    //     tableHandlers,
    //     tableState,
    // } = useTableActions();

    // const { resultHandlers } = useGetScenarioResults();

    // const dispatch = useDispatch();

    // const history = useHistory();

    return (
        <div className={styles['table-discrimination']}>
            <LoadingSpinerArea area="table-scenario" />
            <Table
                data={tableData}
                id="table-negociacoes-fornecedor"
                className={styles['table-scenario']}
                rowClassName={styles['table-scenario-row']}
                rowHeight={64}
                headerHeight={46}
                // sortColumn={tableState.sortColumn}
                // sortType={tableState.sortType}
                // onSortColumn={tableHandlers.handleSortColumns}
            >
                <Column sortable flexGrow={1}>
                    <HeaderCell>
                        UF
                    </HeaderCell>
                    <Cell dataKey="uf" height={60}>
                        {/* {(rowData) => (
                            <Whisper
                                delayShow={0.2}
                                trigger="hover"
                                placement="bottom"
                                speaker={<Tooltip>{rowData.produto}</Tooltip>}
                            >
                                <div>
                                    <p className={styles['main-bold-text']}>{rowData.produtoCodigo}</p>
                                    <p className={styles['subtext-bold']}>
                                        {rowData.produto}
                                    </p>
                                </div>
                            </Whisper>
                        )} */}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell className={styles.line}>
                        <span className={styles['flex-column']}>
                            <p>Custo</p>
                            <p>fabricação</p>
                        </span>
                    </HeaderCell>
                    <Cell dataKey="custoFabricacao" height={60} className={styles.line}>
                        {(rowData) => (
                            <div>
                                <p className={styles['main-bold-text']}>
                                    R$
                                    {rowData.custoFabricacao}
                                </p>
                                <div className={[styles['neutro-indicator'], styles['pmz-indicator']].join(' ')}>
                                    {rowData.cfInd}
                                    %
                                </div>
                            </div>
                            )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell>
                        <span className={styles['flex-column']}>
                            <p>Outros</p>
                            <p>custos</p>
                        </span>
                    </HeaderCell>
                    <Cell dataKey="outros">
                        {(rowData) => (
                            <div>
                                <p className={styles['main-bold-text']}>
                                    R$
                                    {rowData.outros}
                                    {/* {utils.formatNumberToCurrency(rowData.outputs.new_price_scenario.price, 2)} */}
                                </p>
                                <div className={styles['outros-indicator-flex']}>
                                    <div className={styles['neutro-indicator']}>
                                        {rowData.outrosInd}
                                        %
                                    </div>
                                </div>
                            </div>
                            )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell>
                        PMZ
                    </HeaderCell>
                    <Cell dataKey="pmz">
                        {(rowData) => (
                            <div>
                                <p className={styles['main-bold-text']}>
                                    R$
                                    {rowData.pmz}
                                </p>
                                <div className={[styles['neutro-indicator'], styles['pmz-indicator']].join(' ')}>
                                    {rowData.pmzInd}
                                    %
                                </div>
                            </div>
                            )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell className={styles.line}>
                        <span className={styles['flex-column']}>
                            <p>Tabela</p>
                            <p>base</p>
                        </span>
                    </HeaderCell>
                    <Cell dataKey="tabelaBase" className={styles.line}>
                        {(rowData) => (
                            <div>
                                <p className={styles['main-bold-text']}>
                                    R$
                                    {' '}
                                    {rowData.tabelaBase}
                                    {/* {utils.formatLocaleNumber(rowData.outputs.new_price_scenario?.demand_forecast, 0)} */}
                                </p>
                            </div>
                )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell>
                        <span className={styles['flex-column']}>
                            <p>Margem</p>
                            <p>lucro bruto</p>
                        </span>
                    </HeaderCell>
                    <Cell dataKey="margemLucroBruto">
                        {(rowData) => (
                            <div>
                                <p className={styles['main-bold-text']}>
                                    {rowData.margemLucroBruto}
                                    %
                                </p>
                                <p className={styles['small-bold-text']}>
                                    R$
                                    {' '}
                                    {rowData.mlInd}
                                </p>
                            </div>
                            )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell>
                        <span className={styles['flex-column']}>
                            <p>Margem lucro</p>
                            <p>operacional</p>
                        </span>
                    </HeaderCell>
                    <Cell dataKey="margemLucroOperacional">
                        {(rowData) => (
                            <div>
                                <p className={styles['main-bold-text']}>
                                    {rowData.margemLucroOperacional}
                                    %
                                </p>
                                <p className={styles['small-bold-text']}>
                                    R$
                                    {' '}
                                    {rowData.mloInd}
                                </p>
                            </div>
                            )}
                    </Cell>
                </Column>
            </Table>
            <Pagination
                // activePage={tableState.page + 1 || null}
                // displayLength={tableData.size}
                // total={tableData.totalElements || null}
                // onChangePage={tableHandlers.handleChangePage}
                // onChangeLength={tableHandlers.handleChangeSize}
                lengthMenu={lengthMenu}
            />
        </div>

    );
};
