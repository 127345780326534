import { useQuery } from '@tanstack/react-query';
import React, { memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox } from 'rsuite';
import { TableProps } from 'rsuite/lib/Table';
import { InfoTable, Layout } from '../../../../../components';
import {
    DiscountCell,
    LastUpdateCell,
    ProductCell,
    StoreCell,
    TriggerCell,
} from '../../../../../components/TableCells';
import { Exception } from '../../../../../data';
import ExceptionSaveDatapoint from '../../../../../data/ExceptionSaveDatapoint';
import {
    SET_PRECO_ATACADO_DATAPOINTS,
    UPDATE_PRECO_ATACADO_DATAPOINT_BY_INDEX,
    selectorPrecoAtacadoDatapoints as selectorDatapoints,
} from '../../../../../reducers/PrecoAtacado/datapoints';
import { selectorPrecoAtacadoFiltersValue as selectorFilters } from '../../../../../reducers/PrecoAtacado/filters/values';
import {
    SET_PRECO_ATACADO_ACTIVE_PAGE,
    SET_PRECO_ATACADO_DISPLAY_LENGTH,
    selectorPrecoAtacadoPagination,
} from '../../../../../reducers/PrecoAtacado/pagination';
import {
    ADD_PRECO_ATACADO_SELECTED_DATAPOINT_BY_LIST,
    REMOVE_PRECO_ATACADO_SELECTED_DATAPOINT_BY_LIST,
    RESET_PRECO_ATACADO_SELECTED_DATAPOINTS,
    SET_PRECO_ATACADO_SELECTED_DATAPOINTS_BY_LIST,
    selectorPrecoAtacadoSelectedDatapoints as selectorSelectedDatapoints,
} from '../../../../../reducers/PrecoAtacado/selectedDatapoints';
import {
    SET_PRECO_ATACADO_SORT,
    selectorPrecoAtacadoSort as selectorSort,
} from '../../../../../reducers/PrecoAtacado/sort';
import {
    SET_PRECO_ATACADO_TOTAL_ELEMENTS,
    selectorPrecoAtacadoTotalElements as selectorTotalElements,
} from '../../../../../reducers/PrecoAtacado/totalElements';
import {
    checkIfIsLoading,
    handleMoveFocusToNextColumnInput,
} from '../../../../../utils';
import { CheckBoxCell } from '../../../RevisaoPrecos/Components';
import {
    GET_ITEMS_PRECO_ATACADO,
    GET_ITEMS_PRECO_ATACADO_RESPONSE,
    putSaveWholesaleOnBlur,
} from '../../pages/PrecoAtacado/services';
import { Wholesale } from '../../pages/PrecoAtacado/types';
import { GET_PRECO_ATACADO_DATAPOINTS_MODEL } from '../../pages/PrecoAtacado/utils';
import styles from './PrecoAtacadoTable.module.scss';

const MemoCheckbox = memo(Checkbox);
const MemoCheckboxCell = memo(CheckBoxCell);
const MemoDiscountCell = memo(DiscountCell);
const MemoLastUpdateCell = memo(LastUpdateCell);
const MemoProductCell = memo(ProductCell);
const MemoStoreCell = memo(StoreCell);
const MemoTriggerCell = memo(TriggerCell);

type RowData = Wholesale.RowData;

const { Column, HeaderCell, Cell } = InfoTable;

const inputColumns = ['triggerCount', 'discountPercentage'] as const;

type InputColumns = (typeof inputColumns)[number];

const DATA_KEY = 'productsToBePricedId';

const PrecoAtacadoTable = () => {
    const dispatch = useDispatch();

    const pagination = useSelector(selectorPrecoAtacadoPagination);

    const datapoints = useSelector(selectorDatapoints);

    const totalElements = useSelector(selectorTotalElements);

    const sort = useSelector(selectorSort);

    const filters = useSelector(selectorFilters);

    const { selectedAll, excludedIds, selectedIds } = useSelector(
        selectorSelectedDatapoints,
    );

    const activeSelectedDatapointsListName = useMemo(
        () => (selectedAll ? 'excludedIds' : 'selectedIds'),
        [selectedAll, excludedIds, selectedIds],
    );

    const activeSelectedDatapointsList = useMemo(
        () => (selectedAll ? excludedIds : selectedIds),
        [selectedAll, excludedIds, selectedIds],
    );

    const isCheckedAll = useMemo(() => {
        return (
            pagination.displayLength === activeSelectedDatapointsList.length ||
            selectedAll
        );
    }, [activeSelectedDatapointsList, pagination.displayLength, selectedAll]);

    const isIndeterminate = useMemo(
        () => !isCheckedAll && !!activeSelectedDatapointsList.length,
        [isCheckedAll, activeSelectedDatapointsList],
    );

    const findDatapoint = useCallback(
        (productsToBePricedId: string) => {
            return activeSelectedDatapointsList.includes(productsToBePricedId);
        },
        [activeSelectedDatapointsList],
    );

    const isChecked = useCallback(
        (productsToBePricedId: string) => {
            let isChecked = !!findDatapoint(productsToBePricedId);

            if (selectedAll) {
                isChecked = !findDatapoint(productsToBePricedId);
            }

            return isChecked;
        },
        [selectedAll, findDatapoint],
    );

    const handleSortColumn = useCallback(
        (sortColumn: string, sortType: TableProps['sortType']) => {
            dispatch(
                SET_PRECO_ATACADO_SORT({
                    type: sortColumn,
                    orderBy: sortType,
                }),
            );
        },
        [dispatch],
    );

    const handleCheckAll = useCallback(
        (checked: boolean) => {
            if (checked) {
                const ids = datapoints.map(
                    (datapoint) => datapoint.productsToBePricedId,
                );
                dispatch(
                    SET_PRECO_ATACADO_SELECTED_DATAPOINTS_BY_LIST({
                        data: ids,
                    }),
                );
            } else dispatch(RESET_PRECO_ATACADO_SELECTED_DATAPOINTS());
        },
        [dispatch, datapoints],
    );

    const handleRemoveDatapoint = useCallback(
        (productsToBePricedId: string) => {
            dispatch(
                REMOVE_PRECO_ATACADO_SELECTED_DATAPOINT_BY_LIST({
                    data: productsToBePricedId,
                    updateList: activeSelectedDatapointsListName,
                }),
            );
        },
        [activeSelectedDatapointsListName, dispatch],
    );

    const handleAddDatapoint = useCallback(
        (productsToBePricedId: string) => {
            dispatch(
                ADD_PRECO_ATACADO_SELECTED_DATAPOINT_BY_LIST({
                    data: productsToBePricedId,
                    updateList: activeSelectedDatapointsListName,
                }),
            );
        },
        [dispatch, activeSelectedDatapointsListName],
    );

    const handleCheck = useCallback(
        (productsToBePricedId: string, checked: boolean) => {
            if (selectedAll) {
                if (!checked) {
                    if (findDatapoint(productsToBePricedId)) {
                        handleRemoveDatapoint(productsToBePricedId);
                    } else handleAddDatapoint(productsToBePricedId);
                } else handleRemoveDatapoint(productsToBePricedId);
            } else if (checked) {
                handleAddDatapoint(productsToBePricedId);
            } else {
                handleRemoveDatapoint(productsToBePricedId);
            }
        },
        [selectedAll, findDatapoint, handleRemoveDatapoint, handleAddDatapoint],
    );

    const handleChangeValue = useCallback(
        (value: number, index: number, field: InputColumns) => {
            dispatch(
                UPDATE_PRECO_ATACADO_DATAPOINT_BY_INDEX({
                    index,
                    data: { [field]: value },
                }),
            );
        },
        [dispatch],
    );

    const handleBlur = useCallback(
        async (index: number) => {
            const {
                productId,
                storeId,
                storeName,
                productsToBePricedId,
                triggerCount,
                discountPercentage,
            } = datapoints[index];

            try {
                if (!triggerCount || !discountPercentage) {
                    throw new Exception(
                        'Os campos gatilho e desconto precisam ser maior do que zero.',
                    );
                }

                const model = {
                    productsToBePricedId,
                    triggerCount,
                    discountPercentage,
                    storeId,
                    productId,
                };

                const { data } = await putSaveWholesaleOnBlur(model);

                dispatch(
                    UPDATE_PRECO_ATACADO_DATAPOINT_BY_INDEX({
                        index,
                        data,
                    }),
                );
            } catch {
                const error = new ExceptionSaveDatapoint({
                    productId,
                    storeId,
                    storeName,
                });

                console.error(error)
            }
        },
        [dispatch, datapoints],
    );

    const GET_DATAPOINTS_MODEL = useMemo(() => {
        return GET_PRECO_ATACADO_DATAPOINTS_MODEL({
            filters,
            pagination,
            sort,
        });
    }, [filters, pagination, sort]);

    const handleGetDatapoints = useCallback(async () => {
        try {
            const res = await GET_ITEMS_PRECO_ATACADO(GET_DATAPOINTS_MODEL);
            return res;
        } catch {
            throw new Exception('Erro ao buscar produtos');
        }
    }, [GET_DATAPOINTS_MODEL]);

    const handleSuccess = useCallback(
        (data: GET_ITEMS_PRECO_ATACADO_RESPONSE) => {
            dispatch(SET_PRECO_ATACADO_DATAPOINTS(data.content));
            dispatch(SET_PRECO_ATACADO_TOTAL_ELEMENTS(data.totalElements));
        },
        [dispatch],
    );

    const handleChangePage = useCallback(
        (page: number) => {
            dispatch(SET_PRECO_ATACADO_ACTIVE_PAGE(page - 1));
        },
        [dispatch],
    );

    const handleChangeLength = useCallback(
        (size: number) => {
            const lastValidIndex = Math.ceil(totalElements / size);

            const isOnInvalidLastIndex =
                pagination.activePage + 1 > lastValidIndex;

            if (isOnInvalidLastIndex) handleChangePage(lastValidIndex);

            dispatch(SET_PRECO_ATACADO_DISPLAY_LENGTH(size));
        },
        [dispatch, totalElements, pagination.activePage, handleChangePage],
    );

    const memoPagination = useMemo(() => {
        return {
            total: totalElements,
            activePage: pagination.activePage + 1,
            displayLength: pagination.displayLength,
            lengthMenu: [
                { value: 25, label: '25' },
                { value: 50, label: '50' },
                { value: 100, label: '100' },
            ],
            onChangePage: handleChangePage,
            onChangeLength: handleChangeLength,
        };
    }, [totalElements, pagination, handleChangePage, handleChangeLength]);

    const { fetchStatus } = useQuery({
        queryKey: ['ipa/wholesale/datapoints', GET_DATAPOINTS_MODEL],
        retry: false,
        queryFn: handleGetDatapoints,
        onSuccess: handleSuccess,
    });

    const isLoading = useMemo(
        () => checkIfIsLoading(fetchStatus),
        [fetchStatus],
    );

    React.useEffect(() => {
        document.addEventListener('keydown', (e) => {
            handleMoveFocusToNextColumnInput(e, inputColumns as any);
        });
        return () => {
            document.removeEventListener('keydown', (e) => {
                handleMoveFocusToNextColumnInput(e, inputColumns as any);
            });
        };
    }, []);

    return (
        <Layout.Section>
            <InfoTable
                data={datapoints}
                dataKey={DATA_KEY}
                className={styles.table}
                loading={isLoading}
                sortColumn={sort.type}
                sortType={sort.orderBy}
                headerHeight={34}
                rowHeight={62}
                onSortColumn={handleSortColumn}
                pagination={memoPagination}
            >
                <Column width={60}>
                    <HeaderCell>
                        <MemoCheckbox
                            checked={isCheckedAll}
                            indeterminate={isIndeterminate}
                            onChange={(_, checked) => {
                                handleCheckAll(checked);
                            }}
                        />
                    </HeaderCell>
                    <Cell>
                        {(rowData: RowData) => {
                            const value = rowData.productsToBePricedId;
                            return (
                                <MemoCheckboxCell
                                    value={value}
                                    isChecked={isChecked(value as string)}
                                    handleCheck={(value, checked) => {
                                        handleCheck(value, checked);
                                    }}
                                />
                            );
                        }}
                    </Cell>
                </Column>
                {/* @ts-ignore */}
                <Column sortable flexGrow={1} minWidth={180}>
                    <HeaderCell>Produto</HeaderCell>
                    <Cell dataKey="productIds">
                        {(rowData: RowData) => (
                            <MemoProductCell rowData={rowData} />
                        )}
                    </Cell>
                </Column>
                {/* @ts-ignore */}
                <Column sortable width={220}>
                    <HeaderCell>Loja</HeaderCell>
                    <Cell dataKey="store">
                        {(rowData: RowData) => (
                            <MemoStoreCell rowData={rowData} />
                        )}
                    </Cell>
                </Column>
                {/* @ts-ignore */}
                <Column sortable width={136}>
                    <HeaderCell>Gatilho</HeaderCell>
                    <Cell
                        className={styles['padding-inline-xxs']}
                        dataKey="triggerCount"
                    >
                        {(rowData: RowData, index: number) => (
                            <MemoTriggerCell
                                rowData={rowData}
                                index={index}
                                handleChangeValue={(value) => {
                                    handleChangeValue(
                                        value,
                                        index,
                                        'triggerCount',
                                    );
                                }}
                                handleBlur={handleBlur}
                            />
                        )}
                    </Cell>
                </Column>

                {/* @ts-ignore */}
                <Column sortable width={112}>
                    <HeaderCell>Desconto</HeaderCell>
                    <Cell
                        className={styles['padding-inline-xxs']}
                        dataKey="discountPercentage"
                    >
                        {(rowData: RowData, index: number) => (
                            <MemoDiscountCell
                                rowData={rowData}
                                index={index}
                                handleChangeValue={(value) => {
                                    handleChangeValue(
                                        value,
                                        index,
                                        'discountPercentage',
                                    );
                                }}
                                handleBlur={handleBlur}
                            />
                        )}
                    </Cell>
                </Column>
                {/* @ts-ignore */}

                <Column sortable width={280}>
                    <HeaderCell>Última atualização</HeaderCell>
                    <Cell dataKey="lastEditedBy">
                        {(rowData: RowData) => (
                            <MemoLastUpdateCell rowData={rowData} />
                        )}
                    </Cell>
                </Column>
            </InfoTable>
        </Layout.Section>
    );
};

export default PrecoAtacadoTable;
