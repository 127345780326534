import { useQuery } from '@tanstack/react-query';
import * as service from '../services';
import { useEditBaseTableStore } from '../EditBaseTable.store';
import { useCustosGeraisTabela } from './useCustosGeraisTabela';
import { NotificationMessage } from '../../../../../../components';

export const usePriceApply = () => {
    const {
        priceApplyList,
        setEditBaseTableState,
    } = useEditBaseTableStore();

    const { handleGetCustosGeraisTabelaData } = useCustosGeraisTabela()

    const priceApplyQuery = async () => {
        const res = await service.priceApply(priceApplyList);
        return res;
    };

    const priceApplySuccess = () => {
        NotificationMessage(
            'success',
            5000,
            'Preço aplicado com sucesso',
        );
        handleGetCustosGeraisTabelaData()
    }

    const {
        isLoading,
        isError,
        refetch,
    } = useQuery({
        queryKey: ['price-apply'],
        queryFn: () => priceApplyQuery(),
        onSuccess: priceApplySuccess,
        retry: false,
        enabled: false
    });

    return {
        isLoading,
        isError,
        refetch,
        handleConfirmPriceApply: refetch
    };
};
